import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import EcpayComponent from './component';


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
  onLoadEcpay: (id) => {
  	dispatch(actions.doLoadEcpay(id));
  }   
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EcpayComponent);