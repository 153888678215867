import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
// images
import storyImg01 from '../images/cheftengstory/story_01.jpg';
import storyImg02 from '../images/cheftengstory/story_02.jpg'; 
import storyImg03 from '../images/cheftengstory/story_03.jpg';
import storyImg04 from '../images/cheftengstory/story_04.jpg';
import storyImg05 from '../images/cheftengstory/story_05.jpg';
import storyImg06 from '../images/cheftengstory/story_06.jpg';
import storyImg07 from '../images/cheftengstory/story_07.jpg';
import storyImg08 from '../images/cheftengstory/story_08.jpg';
import storyImg09 from '../images/cheftengstory/story_09.jpg';
import storyImg10 from '../images/cheftengstory/story_10.jpg';
import storyImg11 from '../images/cheftengstory/story_11.jpg';
import storyImg12 from '../images/cheftengstory/story_12.jpg';
import storyImg13 from '../images/cheftengstory/story_13.jpg';
import storyImg14 from '../images/cheftengstory/story_14.jpg';
import storyImg15 from '../images/cheftengstory/story_15.jpg';
import storyImg16 from '../images/cheftengstory/story_16.jpg';
import storyImg17 from '../images/cheftengstory/story_17.jpg';
import storyImg18 from '../images/cheftengstory/story_18.jpg';
import storyImg19 from '../images/cheftengstory/story_19.jpg';  


class StoryPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div id="page-story">
      <Helmet>
        <title>鄧師傅功夫菜-品牌故事</title>
        <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
      </Helmet>
        <headerBar.container/>

        {/* <div className="StoryBannerTitle">
          <p className="center">品牌故事</p>
        </div>  */}
        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">品牌故事</p>
          <p className="col-auto DirectionTitle">首頁 / 品牌故事</p>
        </div>

        <div className="StoryContent">
          <h1 className="d-none d-md-block">經營理念：『今日的努力、明日的信譽』及『品質是保證、過程是關鍵』</h1>
          <h1 className="d-block d-md-none">經營理念：<br/>『今日的努力、明日的信譽』<br/>『品質是保證、過程是關鍵』</h1>
          <h2>致力於研發道地的美食，提升顧客的用餐體驗，塑造國際連鎖餐廳的特色</h2>
          <p>
            『大內滷味、精緻名菜』正是鄧師傅滷味膾炙人口的最佳寫照。
            源自大內祕傳老滷與烹調手法，鄧師傅把不同材料的各個細部本質，全部「滷」出來，色澤紅亮，香醇爽口，令人食不停筷，讚不絕口。 
            精研廚藝至今四十多年的鄧師傅，曾遠赴日本、香港習藝，更由中法菜式與料理技術中，研發出鄧師傅口味的各式精緻「功夫菜」。
            百餘菜色分季推出，將家庭中經常食用之菜色與材料，烹調成風味雋永、齒頰留香的道道佳餚。
            每一道菜皆流露著「家庭風」的懷念滋味，讓各界人士蒞臨享受佳餚的同時，感受到鄧師傅－善待顧客的心、接待親友的情。面對未來，鄧師傅希望藉由顧客的監督，在美食研發上精益求精，塑造國際連鎖餐廳特色，並呈現最優質顧客用餐體驗。
            鄧師傅曾獲選為台灣十大美食名廚新秀、台灣美食名店- 優質餐廳、高雄市十大特色伴手禮、評鑑為亞洲66家特色餐廳、觀光局推薦於『台灣觀光護照』、『OTOP一鄉鎮一商品』推薦餐廳、高雄特色美食導覽華會評鑑最佳美食餐廳⋯⋯等榮耀。
          </p>
          <h3 className="d-none d-md-block">餐飲工作是良心與道德之事業。[鄧文裕 自勉之]</h3>
          <h3 className="d-block d-md-none">餐飲工作是良心與道德之事業。 <br/> [鄧文裕 自勉之]</h3>
          <div className="row">
            <div className="col d-flex justify-content-center">
              <div id="carouselExampleControls" className="carousel slide SliderBoxheight " data-ride="carousel">
                {/*<ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>*/}
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src={storyImg01} alt="First slide"/>
                    <div className="carousel-caption AwardText">
                      <p>
                      鄧文裕 師傅，台北縣三峽人。<br/>
                      由於家庭經濟的因素，13歲就前往台北市西門町的餐廳做學徒。
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg02} alt="Second slide"/>
                    <div className="carousel-caption AwardText">
                      <p>
                        學徒時期
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg03} alt="Third slide"/>
                    <div className="carousel-caption AwardText">
                      <p>
                      學徒到主廚
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg04}/>
                    <div className="carousel-caption AwardText">
                      <p>
                      學徒到主廚
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg05}/>
                    <div className="carousel-caption AwardText">
                      <p>
                        為了更精進自己的料理，曾前往日本學習法國菜。
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg06}/>
                    <div className="carousel-caption AwardText">
                      <p>
                      早期學習法國菜
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg07}/>
                    <div className="carousel-caption AwardText">
                      <p>
                      開幕後生意順利
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg08}/>
                    <div className="carousel-caption AwardText">
                      <p>
                      高雄創業門市開幕外觀
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg09}/>
                    <div className="carousel-caption AwardText">
                      <p>
                        鄧師傅曾榮獲2003年北京康壽杯國際名廚烹飪大賽<br/>
                        －個人及團體－雙料金牌獎、中華民國第56屆優良商人金商獎，<br/>
                        及榮獲消費者協會21世紀風雲人物獎。
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg10} alt="Third slide"/>
                    <div className="carousel-caption AwardText">
                      <p>
                      獲得廚藝比賽個人團體雙料金牌獎
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg11} alt="Third slide"/>
                    <div className="carousel-caption AwardText">
                      <p>
                      參加菲律賓廚藝比賽獲獎
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg12} alt="Third slide"/>
                    <div className="carousel-caption AwardText">
                      <p>
                      捐贈高餐大百萬獎學金
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg13} alt="Third slide"/>
                    <div className="carousel-caption AwardText">
                      <p>
                      擔任高餐大學講師授業解惑
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg14}/>
                    <div className="carousel-caption AwardText">
                      <p>
                      巡迴擔任青年職涯規劃講師
                      稟持「永續經營」的心，代代相傳下去，相信未來後備接棒之後，一定能做的更的出色。
                      （鄧師傅受聘為行政院青年輔導委員會專業職場演講者，以及高雄餐旅學院客座講師）
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg15}/>
                    <div className="carousel-caption AwardText">
                      <p>
                      巡迴擔任青年職涯規劃講師
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg16}/>
                    <div className="carousel-caption AwardText">
                      <p>
                      獲頒金商獎殊榮
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg17}/>
                    <div className="carousel-caption AwardText">
                      <p>
                      創立30周年與市長陳菊合影
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg18} alt="Third slide"/>
                    <div className="carousel-caption AwardText">
                      <p>
                      日本雜誌專訪
                      </p>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={storyImg19} alt="Third slide"/>
                    <div className="carousel-caption AwardText">
                    </div>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        
        <footer.container/>
	    </div>
	  )
  }
}

export default StoryPageComponent;
