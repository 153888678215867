import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import HeaderBarComponent from './component';


const mapStateToProps = (state) => ({
  showUserDropDown: selectors.getShowUserDropDown(state),
  shoppingCount: selectors.getShoppingCount(state),
});

const mapDispatchToProps = dispatch => ({
  onClickUser: (history) => {
    dispatch(actions.doClickUser(history));
  },
  onClickLogout: () => {
    dispatch(actions.doLogout());
  },  
  onLeaveUser: () => {
    dispatch(actions.doShowUserDorpDown({ showUserDropDown: false }));
  },    
  // onEditLogin: (value, type) => {
  //   dispatch(actions.doEditLogin({ value, type }));
  // }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderBarComponent);