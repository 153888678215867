import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';


class LoginFbComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      onClickLoginFb
    } = this.props;

    return (
      <p className="LoginBtnFB" onClick={() => onClickLoginFb(this.props.history)}>
        Facebook帳號登入
      </p>
    )
  }
}

export default withRouter(LoginFbComponent);
