import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ResetPasswordWithTokenComponent from './component';


const mapStateToProps = (state) => ({
  password: selectors.getPassword(state),
  confirmPassword: selectors.getConfirmPassword(state),
  isReset: selectors.getIsReset(state),
  errMessage: selectors.getErrMessage(state)    
});

const mapDispatchToProps = dispatch => ({
  onSubmitResetPassword: (history, token) => {
    dispatch(actions.doSubmitResetPassword(history, token));
  },
  onEditResetPassword: (value, type) => {
    dispatch(actions.doEditResetPassword({ value, type }));
  },
  onInitModule: () => {
    dispatch(actions.doInitModule());
  }  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordWithTokenComponent);