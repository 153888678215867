import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ShoppingCartComponent from './component';


const mapStateToProps = (state) => ({
  buyProducts: selectors.getBuyProducts(state),
  products: selectors.getProducts(state),
  totalAmount: selectors.getTotalAmount(state),
});

const mapDispatchToProps = dispatch => ({
  onIsLoggedIn: (history) => {
      dispatch(actions.doIsLoggedIn(history));
  },
  onLoadProducts: () => {
    dispatch(actions.doLoadProducts());
  },     
  onUpdateBuyProductQuantity: (productId, buyQuantity) => {
    dispatch(actions.doUpdateBuyProductQuantity(productId, buyQuantity));
  },   
  onClickRemoveBuyProduct: (productId) => {
    dispatch(actions.doClickRemoveBuyProduct(productId, 0));
  },
  onUpdateShoppingData: (value, type) => {
    dispatch(actions.doUpdateShoppingData({ value, type }));
  },
  onClickProgress: (history,value) => {
    dispatch(actions.doClickProgress({ history,value }));
  }, 
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingCartComponent);