import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import OrderComponent from './component';


const mapStateToProps = (state) => ({
  id: selectors.getId(state),
  paymentDate: selectors.getPaymentDate(state),
  paymentMethod: selectors.getPaymentMethod(state),
  paymentStatus: selectors.getPaymentStatus(state),
  status: selectors.getStatus(state),
  totalAmount: selectors.getTotalAmount(state),
  shippingCost: selectors.getShippingCost(state),
  balanceDue: selectors.getBalanceDue(state),
  pakcageList: selectors.getPackageList(state),
});

const mapDispatchToProps = dispatch => ({
  onIsLoggedIn: (history) => {
    dispatch(actions.doIsLoggedIn(history));
  },    
  onLoadOrderDetail: (orderId) => {
    dispatch(actions.doLoadOrderDetail(orderId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderComponent);

 
