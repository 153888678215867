import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'shoppingProgress/UPDATE_IS_LOGGED_IN': (state, { payload }) => {
    return state.setIn(['isLoggedIn'], payload.isLoggedIn);      
  },    
  'shoppingProgress/UPDATE_IS_COMPLETED': (state, { payload }) => {
    return state.setIn(['isCompleted'], payload.isCompleted);      
  },      
  'shoppingProgress/UPDATE_BUY_PRODUCTS': (state, { payload }) => {
    const buyProducts = state.getIn(['buyProducts']);
    if (payload.buyQuantity === 0) {        
        if (buyProducts.has(payload.productId)) {
          const newBuyProducts = buyProducts.delete(payload.productId);
          return state.setIn(['buyProducts'], newBuyProducts);
        } else {
          return state;
        }
    } else {
      let qty = 0
      if (buyProducts.has(payload.productId)) {
        qty = state.getIn(['buyProducts',payload.productId]) + payload.buyQuantity
      } else {
        qty = payload.buyQuantity
      }
      if (qty > 0) {
        return state.setIn(['buyProducts', payload.productId], qty); 
      }
    }
  },
  'shoppingProgress/UPDATE_PRODUCTS': (state, { payload }) => {
    return state.setIn(['products'], fromJS(payload.products));      
  },  
  'shoppingProgress/UPDATE_STORE_ID_NAMES': (state, { payload }) => {
    return state.setIn(['storeIdNames'], fromJS(payload.storeIdNames));      
  },   
  'shoppingProgress/UPDATE_SHOPPING_DATA': (state, { payload }) => {
    return state.setIn([payload.type], payload.value);      
  },     
  'shoppingProgress/UPDATE_ORDER_ID': (state, { payload }) => {
    return state.setIn(['orderId'], payload.orderId);      
  },      
}, State);

export default Reducer;