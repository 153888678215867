import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import portfolio from '../modules/portfolio';
// images
// import newsBanner from '../images/newsBanner.png'


class PortfolioPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div className="container-fluid padding0" id="page-news">
      <Helmet>
        <title>隆太國際有限公司-實績照片</title>
        <meta name="description" content="「隆太國際有限公司」。提供各項專業的交通安全服務，專營各項交通安全設施產品，如碰撞緩衝設施、鋼索型碰撞防護體、滾筒式護欄、TMA車體碰撞防護器、LED標誌指示燈及交通安全等。" />
      </Helmet>
        <headerBar.container/>

        {/* <div className="NewsBannerTitle">
          <p className="center">媒體報導</p>
        </div>         */}
        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">實績照片</p>
          <div className="col-auto DirectionTitle">首頁 / 實績照片</div>
        </div>

        <portfolio.container />

        <footer.container/>
	    </div>
	  )
  }
}

export default PortfolioPageComponent;
