import React from 'react';
import H_02 from '../../../images/products/H_02.jpg';
import H_03 from '../../../images/products/H_03.jpg';
import H_04 from '../../../images/products/H_04.jpg';
import H_05 from '../../../images/products/H_05.jpg';
import HH_01 from '../../../images/products/HH_01.jpg';
import HH_02 from '../../../images/products/HH_02.jpg';
import HH_03 from '../../../images/products/HH_03.jpg';

export const ProductsH = (props) => {
  return (
    <div>
      <h2 className="productsTitle">橋樑檢修車</h2>
      <p>
        BT-400 大型橋樑檢修車規格說明<br />
        車輛總重：約25噸<br />
        車輛總長：約 11.5米<br />
        最大地下深度：17.4米<br />
        最大作業半徑：11.4米 <br />
        完全伸出深度：15米<br />
      </p>
      <p>
        操作裝置：手臂伸縮升降及旋轉控制、自動展開收納、作業界限控制、聲音警報、強制停止。
        安全裝置：故障診斷、油壓開關閥、車體傾斜警報、緊急停止裝置、剎車警報。
      </p>

      <div>
        <div className="row productsH">
          <div className="col-md-12 col-sm-12">
            <div className="NewsVideoBox">
              <iframe width="100%" height="520" src="https://www.youtube.com/embed/ShxtCs2tt68" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>                
            <p className="text-center">大型橋樑檢修車</p>
            <p className="text-center date"></p>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="imgStraight" src={H_02}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="imgStraight" src={H_03}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={H_04}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={H_05}/>
          </div>
        </div>
      </div>

      <h2 className="productsTitle mt-5">BT-100 小型橋檢車</h2>
      <p>
        輕巧便捷 快速施工<br />
        高處作業或橋底工程<br />
        更能安全有效率的進行<br />
      </p>
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={HH_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={HH_02}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={HH_03}/>
          </div>
        </div>
      </div>

    </div>
  )
}