import { combineReducers } from 'redux-immutable';
import login from './modules/login';
import headerBar from './modules/headerBar';
import registration from './modules/registration';
import resetPasswordWithToken from './modules/resetPasswordWithToken';
import stores from './modules/stores';
import aboutUs from './modules/aboutUs';
import media from './modules/media';
import featured from './modules/featured';
import news from './modules/news';
import productHistory from './modules/productHistory';
import products from './modules/products';
import product from './modules/product';
import portfolio from './modules/portfolio';
import orders from './modules/orders';
import ordersSearch from './modules/ordersSearch';
import profile from './modules/profile';
import resetPasswordWithOldPassword from './modules/resetPasswordWithOldPassword';
import forgotPassword from './modules/forgotPassword';
import coupons from './modules/coupons';
import order from './modules/order';
import shoppingProgress from './modules/shoppingProgress';
import shoppingCompleted from './modules/shoppingCompleted';


const rootReducer = combineReducers({
  [login.constants.NAME]: login.reducer,
  [headerBar.constants.NAME]: headerBar.reducer,
  // [registration.constants.NAME]: registration.reducer,
  // [resetPasswordWithToken.constants.NAME]: resetPasswordWithToken.reducer,
  [stores.constants.NAME]: stores.reducer,
  // [aboutUs.constants.NAME]: aboutUs.reducer,
  // [media.constants.NAME]: media.reducer,
  // [featured.constants.NAME]: featured.reducer,
  [news.constants.NAME]: news.reducer,
  // [productHistory.constants.NAME]: productHistory.reducer,  
  [products.constants.NAME]: products.reducer,
  // [product.constants.NAME]: product.reducer,  
  [portfolio.constants.NAME]: products.reducer,
  // [orders.constants.NAME]: orders.reducer,
  // [ordersSearch.constants.NAME]: ordersSearch.reducer,  
  // [profile.constants.NAME]: profile.reducer,  
  // [resetPasswordWithOldPassword.constants.NAME]: resetPasswordWithOldPassword.reducer,  
  // [forgotPassword.constants.NAME]: forgotPassword.reducer,  
  // [coupons.constants.NAME]: coupons.reducer,  
  // [order.constants.NAME]: order.reducer,  
  // [shoppingProgress.constants.NAME]: shoppingProgress.reducer,  
  // [shoppingCompleted.constants.NAME]: shoppingCompleted.reducer,
});

export default rootReducer

