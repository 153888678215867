import React from 'react';
import E_01 from '../../../images/products/E_01.jpg';
import E_02 from '../../../images/products/E_02.jpg';
import E_03 from '../../../images/products/E_03.jpg';
import E_04 from '../../../images/products/E_04.jpg';
import LED_01 from '../../../images/products/LED_01.jpg';
import LED_02 from '../../../images/products/LED_02.jpg';
import LED_03 from '../../../images/products/LED_03.jpg';
import LED_04 from '../../../images/products/LED_04.jpg';
import LED_05 from '../../../images/products/LED_05.jpg';
import LED_06 from '../../../images/products/LED_06.jpg';
import LED_07 from '../../../images/products/LED_07.jpg';

export const ProductsE = (props) => {
  return (
    <div>
      <h2 className="productsTitle">LED及交通安全產品</h2>
      <h4 className="mt-5">可變式LED標誌看板</h4>
      <ul>
        <li>硬體規格：</li>
        <li>◎ 外框尺寸：高75cm × 寬150 cm × 厚12 cm ± 1cm</li>
        <li>◎ 顯示顏色：黃色</li>
        <li>◎ 電源特性：DC 12V或24V</li>
        <li>◎ 顯示字數：2排4字中文字及導引箭頭等圖示</li>
        <li>◎ 燈體元件：LED(發光二極體) ，亮度3燭光以上，發光中心波長為580~600nm、發光可見視角(半角)為±15度</li>
      </ul>
      <div className="row">
        <div className="col-md-6 col-12 mb-3">
          <img className="ProductsImg" src={LED_03}/>
        </div>
        <div className="col-md-6 col-12 mb-3">
          <img className="ProductsImg" src={LED_04}/>
        </div>
        <div className="col-md-6 col-12 mb-3">
          <img className="ProductsImg" src={LED_05}/>
        </div>
      </div>
      <h4 className="mt-5">交通安全產品</h4>
      {/* <ul>
        <li>◎ 電動旗手</li>
        <li>◎ LED 排式警示燈</li>
        <li>◎ LED 爆閃燈</li>
        <li>◎ 施工標誌(商用級、高強級)</li>
        <li>◎ 審驗合格反光貼紙</li>
      </ul> */}
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <span>◎ 施工標誌(商用級、高強級)</span>
            <img className="ProductsImg" src={E_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <span>◎ 電動旗手</span>
            <img className="ProductsImg" src={E_02}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <span>◎ 審驗合格反光貼紙</span>
            <img className="ProductsImg" src={E_03}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <span>◎ LED 爆閃燈</span>
            <img className="ProductsImg" src={E_04}/>
          </div>
        </div>
      </div>
      <h4 className="mt-5">LED標誌看板</h4>
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={LED_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={LED_02}/>
          </div>
          {/* <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={LED_06}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={LED_07}/>
          </div> */}
        </div>
      </div>
    </div>
  )
}