import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { ORDER_STATUS } from '../../utils/constants/order';


class OrdersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.onIsLoggedIn(this.props.history);
    this.props.onLoadOrdersPage();
  }    
  render() {
    const { 
      orderList, searchText, statusFilter, dateFilter,
      onChangeSearchText, onChangeStatusFilter, onChangeDateFilter, onClickOrder
    } = this.props;
  	return (
	    <div className="container-fluid padding0">
        <div className="OrdersIdListContent">
          <div className="row justify-content-end align-items-center">
            <div className="col-md-4 text-right">
              <input className="SearchInput text-left" type="text" value={searchText} onChange={(event) => onChangeSearchText(event.target.value)}/>
            </div>
            <div className="col-md-4 text-right">
              <select className="Select text-left" value={statusFilter} onChange={(event) => onChangeStatusFilter(event.target.value)}>
                <option value="全部狀態">全部狀態</option>
                {
                  Object.values(ORDER_STATUS).map(orderStatus => 
                    <option value={orderStatus}>{ orderStatus }</option>
                  )
                }
              </select>
            </div>
            <div className="col-md-4 text-right">
              <select className="Select text-left" value={dateFilter} onChange={(event) => onChangeDateFilter(event.target.value)}>
                <option value="0">所有訂單</option>
                <option value="1">最近一個月</option>
                <option value="3">最近三個月</option>
                <option value="6">最近六個月</option>
                <option value="12">最近一年</option>
              </select>
            </div>
          </div>
          <table className="table table-hover table-responsive-md align-middle TableList">
            <thead className="OrdersListHead">
              <tr>            
                <th scope="col">訂單編號</th>
                <th scope="col">訂購金額</th>
                <th scope="col">訂單日期</th>
                <th scope="col">訂單狀態</th>
                <th scope="col">物流狀態</th>
              </tr>
            </thead>
            <tbody>
              {
                orderList.size > 0
                &&
                orderList.map(order => (
                  <tr key={order.getIn(['id'])} onClick={(event) => onClickOrder(this.props.history, order.getIn(['id']))}>
                    <td className="OrdersTH">{order.getIn(['id']).substring(2)}</td>
                    <td className="OrdersTH">{order.getIn(['balanceDue'])}</td>
                    <td className="OrdersTH">{order.getIn(['orderDate'])}</td>
                    <td className="OrdersTH">{ORDER_STATUS[order.getIn(['status'])]}</td>
                    {
                      ( order.getIn(['devlieryMethod']) === 1 && order.getIn(['deliveryNo']) )
                      ?
                      <a href={`http://www.t-cat.com.tw/Inquire/TraceDetail.aspx?BillID=${order.getIn(['deliveryNo'])}`} onClick={(event) => {event.stopPropagation()}} target="_blank">
                        <td className="OrdersTH">查詢</td>
                      </a>  
                      :
                      <td className="OrdersTH"></td>
                    }
                  </tr>
                ))
              }
            </tbody>                  
          </table>
        </div>
	    </div>
	  )
  }
}

export default withRouter(OrdersComponent);