import React from 'react';

import A_01 from '../../../images/products/TMA-100km_01.jpg';
import A_02 from '../../../images/products/TMA-100km_02.jpg';
import A_03 from '../../../images/products/TMA-100km_03.jpg';
import A_04 from '../../../images/products/TMA-100km_04.jpg';
import A_05 from '../../../images/products/TMA-100km_05.jpg';
import A_06 from '../../../images/products/TMA_06.jpg';
import AA_01 from '../../../images/products/KEL-0859_01.jpg';
import AA_02 from '../../../images/products/KEL-0859_02.jpg';
import AA_03 from '../../../images/products/KEL-0859_03.jpg';
import AA_04 from '../../../images/products/KEL-0859_04.jpg';
import AA_05 from '../../../images/products/TMA-70km_01.jpg';
import AA_06 from '../../../images/products/TMA-80km_02.jpg';

export const ProductsA = (props) => {
  return (
    <div>
      <h2 className="productsTitle">TMA 車體碰撞防護器<span className="h6">  (緩撞工程車)</span></h2>
      <p>
        TMA車體碰撞防護器為通過美國聯邦公路總署(FHWA)之MASH (NCHRP) TL-2 &amp; TL-3測試報告之碰撞緩衝產品。
      </p>
      <p>
        「Scorpion TMA」車體碰撞防護器 (Truck Mounted Attenuator) 可安裝於任何施工車輛後方，不僅可以吸收高速撞擊所產生的能量，減低撞擊車輛的傷害，有效的保護施工中的人員與車輛，大幅降低傷亡。
      </p>
      <h4 className="mt-5">產品特色</h4>
      <ul>
        <li className="point-font">◎ 經美國聯邦政府交通運輸部認可，核發測試報告核准文件。</li>
        <li className="point-font">◎ 蜂巢式的全面性能量吸收，無論是正面或有角度的撞擊，均通過碰撞測試且獲得核准。</li>
        <li className="point-font">◎ 無死角或尖銳角度設計，可保護後方有角度的撞擊。</li>
        <li>◎ 簡易穩固的連接支架，直接與車大樑連接，安全性更高。</li>
        <li>◎ 崁疊的反旋轉設計可將側面碰撞所造成可能的翻覆降到最低。</li>
        <li className="point-font">◎ Scorpion 先進的零件設計，可全面拆解，在受撞後可快速且節省的僅更換損壞部分，降低維修成本。</li>
        <li>◎ 獨特的彎曲設計提供 100% 的側面防護，以及卡車後方死角防護。</li>
        <li>◎ 可依照道路限速等級70Km / 80Km / 100Km 選擇適用的緩撞設施。</li>
        <li className="point-font">◎ 設備高度及寬度合乎交通法規，通過車測中心檢測，經交通部及各監理單位驗車核可核發牌照。</li>
      </ul>
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={A_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={A_02}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={A_03}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={A_04}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={A_05}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={A_06}/>
          </div>
        </div>
      </div>

      <h2 className="productsTitle mt-5">TL-2輕型緩撞工程車</h2>
      <p>
        Scorpion II Metro 全長僅 2.5 公尺<br />
        適用於狹窄轉彎半徑或低限速道路使用<br />
        建議連結在 6 噸以上之中型貨車<br />
        可承受 MASH 中時速 80 公里之撞擊
      </p>
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={AA_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={AA_02}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={AA_03}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={AA_04}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={AA_05}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={AA_06}/>
          </div>
        </div>
      </div>


    </div>
  )
}