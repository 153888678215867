import { handleActions } from 'redux-actions';
// import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'profile/UPDATE_NAME': (state, { payload }) => {
    return state.setIn(['name'], payload.name);
  },
  'profile/UPDATE_EMAIL': (state, { payload }) => {
    return state.setIn(['email'], payload.email);
  },
  'profile/UPDATE_GENDER': (state, { payload }) => {
    return state.setIn(['gender'], payload.gender);
  },
  'profile/UPDATE_PHONE': (state, { payload }) => {
    return state.setIn(['phone'], payload.phone);
  },
  'profile/UPDATE_CITY': (state, { payload }) => {
    return state.setIn(['city'], payload.city);
  },
  'profile/UPDATE_ADDRESS': (state, { payload }) => {
    return state.setIn(['address'], payload.address);
  },
  'profile/UPDATE_TAIWAN_CITY': (state, { payload }) => {
    return state.setIn(['TaiwanCity'], payload.TaiwanCity);
  }
}, State);

export default Reducer;