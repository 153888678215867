import React, { Component } from 'react';

import { ProductsA } from './product/productA';
import { ProductsB } from './product/productB';
import { ProductsC } from './product/productC';
import { ProductsD } from './product/productD';
import { ProductsE } from './product/productE';
import { ProductsF } from './product/productF';

class PortfolioComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productsTab: 'productB',
    };
  }
  onClickChangeTab = (event) => {
    event.preventDefault()
    this.setState({
      productsTab: event.target.name,
    })
  }

  render() {
    const {
      productsTab
    } = this.state;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 SideBox d-none d-lg-block">
            <p>
              ▶ 產品介紹
            </p>
            <p className={`MediaSubP ${productsTab === 'productB' ? "active" : ""} `}>
              <a name="productB" onClick={this.onClickChangeTab}>▶ 滾筒式護欄</a>
            </p>
            <p className={`MediaSubP ${productsTab === 'productA' ? "active" : ""} `}>
              <a name="productA" onClick={this.onClickChangeTab}>▶ TMA 車體碰撞防護器</a>
            </p>
            <p className={`MediaSubP ${productsTab === 'productC' ? "active" : ""} `}>
              <a name="productC" onClick={this.onClickChangeTab}>▶ 碰撞緩衝設施</a>
            </p>
            <p className={`MediaSubP ${productsTab === 'productD' ? "active" : ""} `}>
              <a name="productD" onClick={this.onClickChangeTab}>▶ 鋼索型碰撞防護體</a>
            </p>
            <p className={`MediaSubP ${productsTab === 'productE' ? "active" : ""} `}>
              <a name="productE" onClick={this.onClickChangeTab}>▶ LED 及交通安全產品</a>
            </p>
            <p className={`MediaSubP ${productsTab === 'productF' ? "active" : ""} `}>
              <a name="productF" onClick={this.onClickChangeTab}>▶ 特殊車輛</a>
            </p>
          </div>
          <div className="row d-lg-none categorySelect">
            {
              <div className="col-sm-12">
                <select id="categorySelect" onChange={event => this.setState({ productsTab: event.target.value })}>
                  <option className="MediaSubP" value="productB">▶ 滾筒式護欄</option>
                  <option className="MediaSubP" value="productA">▶ TMA 車體碰撞防護器</option>
                  <option className="MediaSubP" value="productC">▶ 碰撞緩衝設施</option>
                  <option className="MediaSubP" value="productD">▶ 鋼索型碰撞防護體</option>
                  <option className="MediaSubP" value="productE">▶ LED 及交通安全產品</option>
                  <option className="MediaSubP" value="productF">▶ 特殊車輛</option>
                </select>
              </div>
            }
          </div>
          <div className="col-md-8 col-sm-12 ListContent">
            <div className="">
              {productsTab === 'productA' && <ProductsA />}
              {productsTab === 'productB' && <ProductsB />}
              {productsTab === 'productC' && <ProductsC />}
              {productsTab === 'productD' && <ProductsD />}
              {productsTab === 'productE' && <ProductsE />}
              {productsTab === 'productF' && <ProductsF />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PortfolioComponent;