import { createAction } from 'redux-actions';
import { message } from 'antd';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as config from '../../config'

const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

export const doUpdateMessage = createAction(types.UPDATE_MESSAGE)
export const doEditForgotPassword = createAction(types.EDIT_FORGOT_PASSWORD)

export function doSubmitForgotPassword(history) {
  return (dispatch, getState) => {
  	const state = getState();
  	const email = state.getIn(['forgotPassword', 'email']);
    return api.cPasswordForgot(email).then(result => {  
      message.info('信件已寄出，請從信中連結進行密碼重設');
      // return dispatch(doUpdateMessage({ message: 'Sent!' }));
      window.location.href = `${config.loginPage}`;
    }).catch((errMsg) => {
      console.log(errMsg);
    })
  }
}
