import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ProductComponent from './component';


const mapStateToProps = (state) => ({
  product: selectors.getProduct(state),
  buyQuantity: selectors.getBuyQuantity(state)
});

const mapDispatchToProps = dispatch => ({
  onLoadProduct: (productId) => {
    dispatch(actions.doLoadProduct({ productId }));
  },  
  onChangeBuyQuantity: (value) => {
    dispatch(actions.doChangeBuyQuantity({ buyQuantity: value }));
  },  
  onClikcAddToShoppingCart: (productId) => {
    dispatch(actions.doClikcAddToShoppingCart(productId));
  }  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductComponent);