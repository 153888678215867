import { createAction } from 'redux-actions';
import { message } from 'antd';
import * as types from './actionTypes';
import * as api from '../../utils/api';


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

export function doLoadOrdersSearchPage() {  
  return dispatch => {
    dispatch(doChangeOrderPhone({ 'orderPhone': '' }));
    dispatch(doChangeOrderId({ 'orderId': '' }));
    dispatch(doUpdateOrder({ 'order': {} }));
    return;
  }
}

export const doChangeOrderPhone = createAction(types.UPDATE_ORDER_PHONE)
export const doChangeOrderId = createAction(types.UPDATE_ORDER_ID)
export const doUpdateOrder = createAction(types.UPDATE_ORDER)
export function doClickSearch() {  
  return (dispatch, getState) => {
    const state = getState();
    let orderPhone = state.getIn(['ordersSearch', 'orderPhone']);
    let orderId = state.getIn(['ordersSearch', 'orderId']);
    dispatch(doUpdateOrder({ 'order': {} }));
    api.rSearchOrder(orderId, orderPhone).then(result => {
      dispatch(doUpdateOrder({ 'order': result }));
    }).catch((errMsg) => {
      if (errMsg === 'E007') {
        message.info('查無此訂單');
      }
    })    
  }
}