import React, { Component } from 'react';
import btn_prev from '../../images/btn_prev.svg'
import btn_close from '../../images/slider-close-icon.svg'


class ProductHistoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.onLoadProductHistoryPage();
  }
  onToggleClass(elementClass,elementId, toggleClassName){
    var x = document.getElementsByClassName(elementClass);
    var i;
    for (i = 0; i < x.length; i++) {
        x[i].classList.remove(toggleClassName);
    }
    var element = document.getElementById(elementId.productHistoryCategoryId);
    if(element.classList)
      element.classList.toggle(toggleClassName);
    else
      element.classList.add(toggleClassName);
  }    

  render() {
    const { 
      productHistoryCategoryIdList, productHistoryCategories, productHistoryIdList, productHistory, selectedProductHistoryId,
      onClickProductHistory, onClickProductHistoryCategory
    } = this.props;
  	return (
	    <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 SideBox d-none d-lg-block">
            {
              // (productHistoryCategoryIdList && productHistoryCategories)
              // &&
              // productHistoryCategoryIdList.map(productHistoryCategoryId => (
              //   <p className="SideBoxList" id={productHistoryCategoryId} key={productHistoryCategoryId} onClick={(event) => {onClickProductHistoryCategory(productHistoryCategoryId),this.onToggleClass("SideBoxList",{productHistoryCategoryId},"active")}}>▶  {productHistoryCategories.getIn([productHistoryCategoryId, "name"])}</p>
              // ))
            }
          </div>
        
          <div className="row d-lg-none categorySelect">
            <div className="col-sm-12">
              <select id="categorySelect" style={{width:'100%'}} onChange={() => {onClickProductHistoryCategory(document.getElementById("categorySelect").value)}}>
              {
                (productHistoryCategoryIdList && productHistoryCategories)
                &&
                productHistoryCategoryIdList.map(productHistoryCategoryId => (
                  <option className="SideBoxListRWD" id={productHistoryCategoryId} key={productHistoryCategoryId} value ={productHistoryCategoryId}>{productHistoryCategories.getIn([productHistoryCategoryId, "name"])}</option>
                ))
              }
            </select>
            </div>
          </div>

          <div className="col-lg-8 col-sm-12 ListContent">
            <div className="row justify-content-start">
              {
                (productHistoryIdList && productHistory && productHistoryCategories)
                &&
                productHistoryIdList.map(productHistoryId => (
                  <div className="col-md-4 List ProductHistoryList" data-toggle="modal" data-target="#exampleModal" key={productHistoryId} onClick={(event) => onClickProductHistory(productHistoryId)}>
                    <div className="ImgBox">
                      <img src={`/inspection/stamp/${productHistory.getIn([productHistoryId, "image"])}`}  className="img-fluid" alt=""/>
                    </div>
                      <p className="text-center">{productHistory.getIn([productHistoryId, "inspectionName"])}</p>
                  </div>
                ))
              }
            </div>                   
          </div>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <img className="close SliderBoxCloseBtn" src={btn_close} data-dismiss="modal" aria-label="Close"/>
              <div className="modal-body">
                <div id="carouselExampleControls" className="carousel slide ProductSliderBox" data-ride="">
                  {/* <ol className="carousel-indicators">
                    {
                      (productHistoryIdList && productHistory && productHistoryCategories)
                      &&
                      productHistoryIdList.entrySeq().map(([id, productHistoryId]) => (
                        <li data-target="#carouselExampleIndicators" data-slide-to={id} ></li>
                      ))
                    }
                  </ol> */}
                  <div className="carousel-inner">
                    {
                      productHistoryIdList.entrySeq().map(([id, productHistoryId]) => {
                        if(productHistoryId == selectedProductHistoryId){
                          return <div id={productHistoryId} className="carousel-item active"> 
                            <img className="d-block w-100" src={`inspection/full/${productHistory.getIn([productHistoryId, "image"])}`} alt={`${productHistory.getIn([productHistoryId, 'selectedProductHistoryId'])}`}/>
                          </div>
                        }/*
                        else if(productHistoryId != selectedProductHistoryId){
                          return <div id={productHistoryId} className="carousel-item">
                            <span>productHistoryId ${productHistoryId} selectedProductHistoryId ${selectedProductHistoryId}</span>
                            <img className="d-block w-100" src={`https://chefteng.stormed.com/inspection/full/${productHistory.getIn([productHistoryId, "image"])}`} alt=""/>
                          </div>
                        }*/
                        
                      })
                    }
                  </div>
                  {/* <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <img src={btn_prev} aria-hidden="true" alt="" />
                  </a>
                  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <img className="TransformY" src={btn_prev} aria-hidden="true" alt="" />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
	    </div>
     
	  )
  }
}

export default ProductHistoryComponent;