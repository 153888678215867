import { handleActions } from 'redux-actions';
// import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'resetPasswordWithOldPassword/UPDATE_OLD_PASSWORD': (state, { payload }) => {
    return state.setIn(['oldPassword'], payload.oldPassword);
  },
  'resetPasswordWithOldPassword/UPDATE_NEW_PASSWORD': (state, { payload }) => {
    return state.setIn(['newPassword'], payload.newPassword);
  },
  'resetPasswordWithOldPassword/UPDATE_CONFIRM_PASSWORD': (state, { payload }) => {
    return state.setIn(['confirmPassword'], payload.confirmPassword);
  },
  'resetPasswordWithOldPassword/UPDATE_ERROR_MESSAGE': (state, { payload }) => {
    return state.setIn(['errMessage'], payload.message);
  },      
  'resetPasswordWithOldPassword/UPDATE_IS_RESET': (state, { payload }) => {
    return state.setIn(['isReset'], payload.isReset);
  }      
}, State);

export default Reducer;