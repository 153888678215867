import { createAction } from 'redux-actions';
import { message, Modal } from 'antd';
import moment from 'moment';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';
import * as config from '../../config'


export const doUpdateIsLoggedIn = createAction(types.UPDATE_IS_LOGGED_IN);
export function doIsLoggedIn(history) {
  return (dispatch, getState) => {
    const state = getState();
    if (cookie.getCookie('stormedToken') === '') {
      dispatch(doUpdateIsLoggedIn({ isLoggedIn: false }));
      Modal.confirm({
        title: '您尚未登入會員，是否要先登入？',
        content: '(成為會員即可享有購物優惠等福利)',
        okText: '登入',
        cancelText: '繼續',
        onOk() {
          history.push(`/login`);
        },
      });
    }
  }
}

export const doUpdateProducts = createAction(types.UPDATE_PRODUCTS);
export const doUpdateShippingLimit = createAction(types.UDPATE_SHIPPING_LIMIT);
export function doInitOrderData() {
  return (dispatch, getState) => {
    const state = getState();
    const buyProducts = state.getIn(['shoppingProgress', 'buyProducts']);
    const buyProductIdList = [];
    buyProducts.keySeq().forEach(productId => buyProductIdList.push(productId));
    api.rPartialPackages(buyProductIdList).then(result => {   
      const products = {};
      let canShippingSelf = true;
      let canShippingDelivery = true;
      let shippingSelfDateList = [];
      let shippingDeliveryDateList = [];
      let allEnough = true;
      result.forEach(product => {
        products[product.id] = product;
        if (product.shippingSelf === false) {
          canShippingSelf = false;
        }
        if (product.shippingDelivery === false) {
          canShippingDelivery = false;
        }
        if (product.shippingSelfDateList.length > 0) {
          if (shippingSelfDateList.length === 0) {
            shippingSelfDateList = JSON.parse(JSON.stringify(product.shippingSelfDateList));
          } else {
            shippingSelfDateList = shippingSelfDateList.filter(shippingDate => product.shippingSelfDateList.indexOf(shippingDate) !== -1);
          }
        }
        if (product.shippingDeliveryDateList.length > 0) {
          if (shippingDeliveryDateList.length === 0) {
            shippingDeliveryDateList = JSON.parse(JSON.stringify(product.shippingDeliveryDateList));
          } else {
            shippingDeliveryDateList = shippingDeliveryDateList.filter(shippingDate => product.shippingDeliveryDateList.indexOf(shippingDate) !== -1);
          }
        }
        if (buyProducts.getIn([product.id]) > product.quantity) {
          allEnough = false;
          dispatch(doUpdateBuyProductQuantity(product.id, product.quantity));
          dispatch(doUpdateShoppingCount({ count: product.quantity - buyProducts.getIn([product.id]) }));
        }
      })

      if (allEnough === false) {
        dispatch(doUpdateShoppingData(0, 'currentStep'));
        message.error('商品數量不足');
      } else {
        dispatch(doUpdateShippingLimit({ canShippingSelf, canShippingDelivery, shippingSelfDateList, shippingDeliveryDateList }));
        dispatch(doUpdateProducts({ products }));

        dispatch(doUpdateShoppingData({value:"", type:"deliveryDate"}))
        dispatch(doUpdateShoppingData({value:"0", type:"deliveryTime"}))
        dispatch(doUpdateShoppingData({value: canShippingDelivery ? "1" : "2", type:"deliveryMethod"}))
        dispatch(doUpdateShoppingData({value:"1", type:"storeId"}))
        dispatch(doUpdateShoppingData({value:"", type:"deliveryName"}))
        dispatch(doUpdateShoppingData({value:"male", type:"deliveryGender"}))
        dispatch(doUpdateShoppingData({value:"", type:"deliveryPhone"}))
        dispatch(doUpdateShoppingData({value:"台北市", type:"deliveryCity"}))
        dispatch(doUpdateShoppingData({value:"", type:"deliveryAddress"}))
        dispatch(doUpdateShoppingData({value:"1", type:"paymentMethod"}))
        dispatch(doUpdateShoppingData({value:false, type:"accessory1"}))
        dispatch(doUpdateShoppingData({value:false, type:"accessory2"}))
        dispatch(doUpdateShoppingData({value:false, type:"accessory3"}))
        dispatch(doUpdateShoppingData({value:false, type:"accessory4"}))
        dispatch(doUpdateShoppingData({value:false, type:"accessory5"}))
        dispatch(doUpdateShoppingData({value:false, type:"accessory6"}))
        dispatch(doUpdateShoppingData({value:false, type:"isCut"}))
        dispatch(doUpdateShoppingData({value:"1", type:"invoiceType"}))
        dispatch(doUpdateShoppingData({value:"", type:"invoiceUbn"}))
        dispatch(doUpdateShoppingData({value:"", type:"invoiceTitle"}))
        dispatch(doUpdateShoppingData({value:"", type:"greetings"}))
        dispatch(doUpdateShoppingData({value:"", type:"note"}))
        dispatch(doUpdateShoppingData({value:false, type:"isAgreeTerms"}))
        dispatch(doUpdateShoppingData({value:"", type:"errorMessage"}))
        dispatch(doUpdateShoppingData({value:false, type:"asPurchaserData"}))

        dispatch(doUpdateShoppingData({value:"", type:"couponCode"}))
        dispatch(doUpdateShoppingData({value:0, type:"giftId"}))
        dispatch(doUpdateShoppingData({value:0, type:"couponId"}))
        dispatch(doUpdateShoppingData({value:"", type:"couponContent"}))
        dispatch(doUpdateShoppingData({value:0, type:"minAmt"}))
        dispatch(doUpdateShoppingData({value:0, type:"discount"}))
        dispatch(doUpdateShoppingData({value:100, type:"deduction"}))
        dispatch(doUpdateShoppingData({value:false, type:"freeShipping"}))
        dispatch(doUpdateShoppingData({value:{}, type:"gift"}))   
      }
    }).catch((errMsg) => {
      console.log(errMsg);
    })

    if (cookie.getCookie('stormedToken') !== '') {
      api.rProfile().then(result => {
        dispatch(doUpdateShoppingData({ value: result.memberName, type: "purchaserName" }))
        dispatch(doUpdateShoppingData({ value: result.gender, type: "purchaserGender" }))
        dispatch(doUpdateShoppingData({ value: result.memberEmail, type: "purchaserEmail" }))
        dispatch(doUpdateShoppingData({ value: result.phone, type: "purchaserPhone" }))
        dispatch(doUpdateShoppingData({ value: result.address, type: "purchaserAddress" }))
        if(result.city === null){
          dispatch(doUpdateShoppingData({ value: '台北市', type: "purchaserCity" }))
        } else {
          dispatch(doUpdateShoppingData({ value: result.city, type: "purchaserCity" }))
        }
      }).catch((errMsg) => {
        dispatch(doUpdateShoppingData({ value: '', type: "purchaserName" }))
        dispatch(doUpdateShoppingData({ value: '', type: "purchaserGender" }))
        dispatch(doUpdateShoppingData({ value: '', type: "purchaserEmail" }))
        dispatch(doUpdateShoppingData({ value: '', type: "purchaserPhone" }))
        dispatch(doUpdateShoppingData({ value: '台北市', type: "purchaserCity" }))
        dispatch(doUpdateShoppingData({ value: '', type: "purchaserAddress" }))  
      })      
    }
  }
}


export const doUpdateBuyProducts = createAction(types.UPDATE_BUY_PRODUCTS);
export const doUpdateShoppingCount = createAction(types.UPDATE_SHOPPING_COUNT);
export function doUpdateBuyProductQuantity(productId, buyQuantity) {
  return (dispatch, getState) => {
    dispatch(doUpdateBuyProducts({ productId, buyQuantity }));
    dispatch(doUpdateShoppingCount({ count: buyQuantity }));
  }
}

export function doClickRemoveBuyProduct(productId, buyQuantity) {
  return (dispatch, getState) => {
    const state = getState();
    const products = state.getIn(['shoppingProgress','products']);
    const buyProducts = state.getIn(['shoppingProgress', 'buyProducts']);
    let canShippingSelf = true;
    let canShippingDelivery = true;
    let shippingSelfDateList = [];
    let shippingDeliveryDateList = [];
    buyProducts.keySeq().forEach(pId => {
      if (pId != productId) {
        if (products.getIn([pId, 'shippingSelf']) === false) {
          canShippingSelf = false;
        }
        if (products.getIn([pId, 'shippingDelivery']) === false) {
          canShippingDelivery = false;
        }
        if (products.getIn([pId, 'shippingSelfDateList']).size > 0) {
          if (shippingSelfDateList.length === 0) {
            shippingSelfDateList = products.getIn([pId, 'shippingSelfDateList']).toJS();
          } else {
            shippingSelfDateList = shippingSelfDateList.filter(shippingDate => products.getIn([pId, 'shippingSelfDateList']).indexOf(shippingDate) !== -1);
          }
        }
        if (products.getIn([pId, 'shippingDeliveryDateList']).size > 0) {
          if (shippingDeliveryDateList.length === 0) {
            shippingDeliveryDateList = products.getIn([pId, 'shippingDeliveryDateList']).toJS();
          } else {
            shippingDeliveryDateList = shippingDeliveryDateList.filter(shippingDate => products.getIn([pId, 'shippingDeliveryDateList']).indexOf(shippingDate) !== -1);
          }
        }
      }
    });
    dispatch(doUpdateShippingLimit({ canShippingSelf, canShippingDelivery, shippingSelfDateList, shippingDeliveryDateList }));
    dispatch(doUpdateBuyProducts({ productId, buyQuantity }));
    dispatch(doUpdateShoppingCount({ count: -buyProducts.getIn([productId]) }));
  }
}

export const doReallyUpdateShoppingData = createAction(types.UPDATE_SHOPPING_DATA);
export const doUpdateStoreIdNames = createAction(types.UPDATE_STORE_ID_NAMES);
export function doLoadStoreIdNames() {
  return (dispatch, getState) => {
    const state = getState();
    if (state.getIn(['shoppingProgress', 'deliveryMethod']) === '1' 
      && (state.getIn(['shoppingProgress', 'deliveryDate']) === '2019/01/30'
      || state.getIn(['shoppingProgress', 'deliveryDate']) === '2019/01/31'
      || state.getIn(['shoppingProgress', 'deliveryDate']) === '2019/02/01'
      || state.getIn(['shoppingProgress', 'deliveryDate']) === '2019/02/02'
      || state.getIn(['shoppingProgress', 'deliveryDate']) === '2019/02/03'
      || state.getIn(['shoppingProgress', 'deliveryDate']) === '2019/02/04'
      || state.getIn(['shoppingProgress', 'deliveryDate']) === '2019/02/05'
      || state.getIn(['shoppingProgress', 'deliveryDate']) === '2019/02/06')) {
      dispatch(doUpdateShoppingData({value: '2019/02/07', type: 'deliveryDate'}))
    }    
    if (state.getIn(['shoppingProgress', 'deliveryDate']) === '2019/02/05') {
      const storeIdNames = [{'storeId': 3, 'storeName': '高雄巨蛋店'},{'storeId': 14, 'storeName': '高雄夢時代概念店'},{'storeId': 6, 'storeName': '高雄機場店'}];
      dispatch(doUpdateStoreIdNames({ storeIdNames }));
    } else {
      const storeIdNames = [
        {'storeId': 1, 'storeName': '高雄中正店', 'storeCity': '高雄市'},
        {'storeId': 2, 'storeName': '高雄青年店', 'storeCity': '高雄市'},
        {'storeId': 7, 'storeName': '高雄美術館店', 'storeCity': '高雄市'},
        {'storeId': 3, 'storeName': '高雄巨蛋店', 'storeCity': '高雄市'},
        {'storeId': 14, 'storeName': '高雄夢時代概念店', 'storeCity': '高雄市'},
        {'storeId': 6, 'storeName': '高雄機場店', 'storeCity': '高雄市'}
      ];
      dispatch(doUpdateStoreIdNames({ storeIdNames }));
    }
  }
}

export function doLoadTaiwanCity() {
  return dispatch => {
    api.rCities().then(result => {
      dispatch(doUpdateShoppingData({ value: result, type: 'TaiwanCity' }));
    }).catch((errMsg) => {
      console.log(errMsg);
    })    
  }
}

export function doUpdateShoppingData(payload) {
  return (dispatch, getState) => {
    // if (payload.type === 'deliveryDate' && payload.value === '2019/02/05') {
    //   const storeIdNames = [{'storeId': 3, 'storeName': '巨蛋店'},{'storeId': 14, 'storeName': '夢時代概念店'},{'storeId': 6, 'storeName': '機場店'}];
    //   dispatch(doUpdateStoreIdNames({ storeIdNames }));
    // } else {
    //   const storeIdNames = [{'storeId': 1, 'storeName': '中正店'},{'storeId': 2, 'storeName': '青年店'},{'storeId': 7, 'storeName': '美術館店'},{'storeId': 3, 'storeName': '巨蛋店'},{'storeId': 14, 'storeName': '夢時代概念店'},{'storeId': 6, 'storeName': '機場店'}];
    //   dispatch(doUpdateStoreIdNames({ storeIdNames }));   
    // }
    const state = getState();    
    dispatch(doReallyUpdateShoppingData(payload));
    if (['deliveryName', 'deliveryGender', 'deliveryPhone', 'deliveryAddress', 'deliveryName', 'deliveryGender', 'deliveryPhone', 'deliveryAddress'].indexOf(payload.type) > -1) {
      const purchaserName = state.getIn(['shoppingProgress', 'purchaserName']);
      const purchaserGender = state.getIn(['shoppingProgress', 'purchaserGender']);
      const purchaserPhone = state.getIn(['shoppingProgress', 'purchaserPhone']);
      const purchaserAddress = state.getIn(['shoppingProgress', 'purchaserAddress']);      

      const deliveryName = state.getIn(['shoppingProgress', 'deliveryName']);
      const deliveryGender = state.getIn(['shoppingProgress', 'deliveryGender']);
      const deliveryPhone = state.getIn(['shoppingProgress', 'deliveryPhone']);
      const deliveryAddress = state.getIn(['shoppingProgress', 'deliveryAddress']);    

      if (purchaserName !== deliveryName || purchaserGender !== deliveryGender || purchaserPhone !== deliveryPhone || purchaserAddress !== deliveryAddress) {
        dispatch(doReallyUpdateShoppingData({ value: false, type: 'asPurchaserData'}));
      } else {
        dispatch(doReallyUpdateShoppingData({ value: true, type: 'asPurchaserData'}));
      }
    }

    if (payload.type === 'deliveryMethod') {
      dispatch(doReallyUpdateShoppingData({ value: '', type: 'deliveryDate' }));
    }

    dispatch(doLoadStoreIdNames());
    return;
  }
}
export function doClickAsPurchaserData(isAsPurchaserData) {
  return (dispatch, getState) => {
    if (isAsPurchaserData) {
      const state = getState();
      const purchaserName = state.getIn(['shoppingProgress', 'purchaserName']);
      const purchaserGender = state.getIn(['shoppingProgress', 'purchaserGender']);
      const purchaserPhone = state.getIn(['shoppingProgress', 'purchaserPhone']);
      const purchaserAddress = state.getIn(['shoppingProgress', 'purchaserAddress']);
      const purchaserCity = state.getIn(['shoppingProgress', 'purchaserCity']);

      dispatch(doUpdateShoppingData({ value: purchaserName, type: 'deliveryName' }));
      dispatch(doUpdateShoppingData({ value: purchaserGender, type: 'deliveryGender' }));
      dispatch(doUpdateShoppingData({ value: purchaserPhone, type: 'deliveryPhone' })); 
      dispatch(doUpdateShoppingData({ value: purchaserAddress, type: 'deliveryAddress' }));
      dispatch(doUpdateShoppingData({ value: purchaserCity, type: 'deliveryCity' }));
    } else {
      dispatch(doUpdateShoppingData({ value: '', type: 'deliveryName' }));  
      dispatch(doUpdateShoppingData({ value: '', type: 'deliveryGender' }));
      dispatch(doUpdateShoppingData({ value: '', type: 'deliveryPhone' })); 
      dispatch(doUpdateShoppingData({ value: '', type: 'deliveryAddress' }));
      dispatch(doUpdateShoppingData({ value: '台北市', type: 'deliveryCity' }));
    }
    return dispatch(doUpdateShoppingData({ value: isAsPurchaserData, type: 'asPurchaserData' }));
  }
}
export function doCheckCoupon() {
  return (dispatch, getState) => {

    const state = getState();
    const couponCode = state.getIn(['shoppingProgress', 'couponCode']);
    const totalAmount = state.getIn(['shoppingProgress', 'totalAmount']);

    api.rCoupon(totalAmount, couponCode).then(result => {
      if (!result) {
        dispatch(doUpdateShoppingData({ value: '查無可用折價券!', type: 'couponContent' }));
      } else {
        const minAmtContent = result.minAmt? `滿${result.minAmt} - `: ``
        const discountContent = result.discountContent? `${result.discountContent}`: ``
        let freeShippingContent = ''
        if (result.roleType === 4) {
          freeShippingContent = '免運費'
        } else if (result.roleType === 5) {
          freeShippingContent = `折抵運費$${result.freeShipping}`
        }
        const giftContent = result.gift? `送贈品`: ``
        const couponContent = `【${result.couponDescription}】  ${minAmtContent}${discountContent}${freeShippingContent}${giftContent}`
        dispatch(doUpdateShoppingData({ value: couponContent, type: 'couponContent' }));

        dispatch(doUpdateShoppingData({ value: result.couponId, type: 'couponId' }));
        dispatch(doUpdateShoppingData({ value: result.minAmt, type: 'minAmt' }));
        // dispatch(doUpdateShoppingData({ value: result.discount, type: 'discount' }));
        dispatch(doUpdateShoppingData({ value: result.freeShipping, type: 'freeShipping' }));
        dispatch(doUpdateShoppingData({ value: result.gift, type: 'gift' }));

        
        if (totalAmount < result.minAmt) {
          dispatch(doUpdateShoppingData({ value: 0, type: 'couponId' }));
          dispatch(doUpdateShoppingData({ value: 0, type: 'freeShipping' }));
          dispatch(doUpdateShoppingData({ value: 0, type: 'discount' }));
          dispatch(doUpdateShoppingData({ value: {}, type: 'gift' }));
          dispatch(doUpdateShoppingData({ value: `您的購物金額為$${totalAmount}, 尚需$${result.minAmt - totalAmount}才能使用此折價券`, type: 'errorMessage' }));
        }
      }      
    }).catch((errMsg) => {
      console.log(errMsg);
    })        
  }
}

export function doOrderPreprocessing() {
  return (dispatch, getState) => {
    dispatch(doUpdateShoppingData({value:'', type:'errorMessage'}))    
    const state = getState();
    const buyPackages = state.getIn(['shoppingProgress', 'buyProducts']).toJS();
    const couponCode = state.getIn(['shoppingProgress', 'couponCode']);
    const deliveryMethod = state.getIn(['shoppingProgress', 'deliveryMethod']);
    
    const deliveryCity = state.getIn(['shoppingProgress', 'deliveryCity']);
    const taiwanCities = state.getIn(['shoppingProgress', 'TaiwanCity']);
    const islands = taiwanCities.some(t => (t.getIn(['city']) === deliveryCity) && t.getIn(['islands']))

    return api.cOrderCalculate(buyPackages, couponCode, deliveryMethod, islands).then(result => {
      dispatch(doUpdateShoppingData({ value: result.totalAmount, type: 'totalAmount' }));
      dispatch(doUpdateShoppingData({ value: result.shippingCost, type: 'shippingCost' }));
      dispatch(doUpdateShoppingData({ value: result.discount, type: 'discount' }));
      dispatch(doUpdateShoppingData({ value: result.balanceDue, type: 'balanceDue' }));
      dispatch(doUpdateShoppingData({value: result.giftId, type: 'giftId'}))      
    }).catch((errMsg) => {
      dispatch(doUpdateShoppingData({value:'訂單送出失敗，請聯絡客服人員確認訂單狀態，造成不便敬請見諒。', type:'errorMessage'}))
    })       
  }
}

export const doUpdateOrderId = createAction(types.UPDATE_ORDER_ID);  // 寫到 shoppingCompleted 裡
export function doClickSubmitOrder(history) {
  
  // cookie.deleteCookie('purchaserEmail');
  // cookie.deleteCookie('orderId');
  
  return (dispatch, getState) => {
    dispatch(doUpdateShoppingData({value: '', type: 'errorMessage'}));
    const state = getState();

    const data = {};
    data['buyPackages'] = state.getIn(['shoppingProgress', 'buyProducts']);

    data['couponId'] = state.getIn(['shoppingProgress', 'couponId']);
    if (state.getIn(['shoppingProgress', 'gift'])) {
      data['giftId'] = state.getIn(['shoppingProgress', 'gift', 'id']);
    }
    data['discount'] = state.getIn(['shoppingProgress', 'discount']);
    data['shippingCost'] = state.getIn(['shoppingProgress', 'shippingCost']);
    data['balanceDue'] = state.getIn(['shoppingProgress', 'balanceDue']);

    data['purchaserName'] = state.getIn(['shoppingProgress', 'purchaserName']);
    data['purchaserGender'] = state.getIn(['shoppingProgress', 'purchaserGender']);
    data['purchaserEmail'] = state.getIn(['shoppingProgress', 'purchaserEmail']);
    data['purchaserPhone'] = state.getIn(['shoppingProgress', 'purchaserPhone']);
    data['purchaserCity'] = state.getIn(['shoppingProgress', 'purchaserCity']);
    data['purchaserAddress'] = state.getIn(['shoppingProgress', 'purchaserAddress']);

    const deliveryMethod = state.getIn(['shoppingProgress', 'deliveryMethod']);
    data['deliveryMethod'] = deliveryMethod;
    if (deliveryMethod === '1') { // 貨運宅配
      data['deliveryName'] = state.getIn(['shoppingProgress', 'deliveryName']);
      data['deliveryGender'] = state.getIn(['shoppingProgress', 'deliveryGender']);
      data['deliveryPhone'] = state.getIn(['shoppingProgress', 'deliveryPhone']);
      data['deliveryCity'] = state.getIn(['shoppingProgress', 'deliveryCity']);
      data['deliveryAddress'] = state.getIn(['shoppingProgress', 'deliveryAddress']);
    } else if (deliveryMethod === '2') { // 到店取貨
      const storeId = state.getIn(['shoppingProgress', 'storeId']);
      data['pickupStoreId'] = storeId;
      const storeIdNames = state.getIn(['shoppingProgress', 'storeIdNames']);
      storeIdNames.forEach(store => {
        if (store.getIn(['storeId']) === parseInt(storeId)) {
          data['purchaserAddress'] = store.getIn(['storeName']) + '取貨(刷卡付清)';
          data['deliveryAddress'] = store.getIn(['storeName']) + '取貨(刷卡付清)';
          data['deliveryCity'] = store.getIn(['storeCity'])
        }
      })
    }

    data['deliveryDate'] = state.getIn(['shoppingProgress', 'deliveryDate']);
    data['deliveryTime'] = state.getIn(['shoppingProgress', 'deliveryTime']);

    data['totalAmount'] = state.getIn(['shoppingProgress', 'totalAmount']);
    data['paymentMethod'] = state.getIn(['shoppingProgress', 'paymentMethod']);

    data['invoiceType'] = state.getIn(['shoppingProgress', 'invoiceType']);
    const invoiceUbn = state.getIn(['shoppingProgress', 'invoiceUbn']);
    if (invoiceUbn) {
      data['invoiceUbn'] = invoiceUbn;
    }
    const invoiceTitle = state.getIn(['shoppingProgress', 'invoiceTitle']);
    if (invoiceTitle) {
      data['invoiceTitle'] = invoiceTitle;
    }

    data['greetings'] = state.getIn(['shoppingProgress', 'greetings']);
    data['note'] = state.getIn(['shoppingProgress', 'note']);

    data['from'] = 'forestage'
    data['deposit'] = 0
    api.cOrder(data).then(result => {
      const orderId = result.orderId;

      // cookie.setCookie('purchaserEmail', data['purchaserEmail']);
      // cookie.setCookie('orderId', orderId);

      // if (data['paymentMethod'] === '2') {
      //   api.cOrderSendBsApi(orderId).then(result => {  
      //     if (result && result.hasOwnProperty('errorCode')) {

      //     }
      //     // console.log(result)
      //   })
      // }

      dispatch(doUpdateOrderId({ orderId }));
      if (data['paymentMethod'] === '1') {
        // api.rEcpayParams(orderId, state.getIn(['shoppingProgress', 'purchaserEmail'])).then(result => {  
        api.rEcpayParams(orderId).then(result => {            
          if (result && result.hasOwnProperty('errorCode')) {
            return dispatch(doUpdateShoppingData({value:"訂單送出失敗，請聯絡客服人員確認訂單狀態，造成不便敬請見諒。", type:"errorMessage"}))
          }
          dispatch(doUpdateShoppingData({value:0, type:"currentStep"}))
          dispatch(doUpdateShoppingData({ value: {}, type: 'buyProducts' }));
          dispatch(doUpdateShoppingCount({ count: 0 }));
                  
          var form = document.createElement("form");
          form.method = "POST";
          form.action = config.ECPAY_URL;
          form.style.display = 'none';
          Object.keys(result).forEach(key => {
            let input = document.createElement("input"); 
            input.name = key;
            input.value = result[key];
            form.appendChild(input);
          });
          document.body.appendChild(form);
          form.submit();
        })   
      } else {
        dispatch(doUpdateShoppingData({value:0, type:"currentStep"}))
        dispatch(doUpdateShoppingData({ value: {}, type: 'buyProducts' }));
        dispatch(doUpdateShoppingCount({ count: 0 }));        
        history.push(`/shopping/completed`);   
      }
    }).catch((errMsg) => {
      dispatch(doUpdateShoppingData({value:"訂單送出失敗，請聯絡客服人員確認訂單狀態，造成不便敬請見諒。", type:"errorMessage"}))
    })       
  }
}
