import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';


export function doClickLoginLine(history) {
	return dispatch => {
    api.rLineAuthUrl().then(result => {
      cookie.setCookie('loginMethod', 'LINE');
      cookie.setCookie('stormedToken', result.stormedToken);
      window.location.href = result.url;
    }).catch((errMsg) => {
      console.log(errMsg);
    })    
	}
}