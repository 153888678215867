import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import news from '../modules/news';
// images
// import newsBanner from '../images/newsBanner.png'


class NewsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div className="container-fluid padding0" id="page-news">
      <Helmet>
        <title></title>
        <meta name="description" content="" />
      </Helmet>
        <headerBar.container/>

        <div className="NewsBannerTitle">
          <p className="center">媒體報導</p>
        </div>        
        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">媒體報導</p>
          <div className="col-auto DirectionTitle">首頁 / 媒體報導</div>
        </div>

        <news.container/>

        <footer.container/>
	    </div>
	  )
  }
}

export default NewsPageComponent;
