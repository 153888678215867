import React from 'react';
import I_01 from '../../../images/products/I_01.jpg';
import I_02 from '../../../images/products/I_02.jpg';
import I_03 from '../../../images/products/I_03.jpg';

export const ProductsI = (props) => {
  return (
    <div>
      <h2 className="productsTitle">掃街車</h2>
      
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={I_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={I_02}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={I_03}/>
          </div>
        </div>
      </div>
    </div>
  )
}