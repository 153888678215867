import { createAction } from 'redux-actions';
import { message } from 'antd';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

export function doIsLoggedIn(history) {
  return dispatch => {
    const stormedToken = cookie.getCookie('stormedToken');
    if (stormedToken === '') {
      history.push(`/login`);
    } else {
      api.cCheckToekn().then(result => {
      }).catch((errMsg) => {
        if (errMsg === 'E401') {
          message.info('Invalid Token');
          cookie.setCookie('stormedToken', '');
          history.push('/');
        }   
      })    
    }  
  }
}

export function doClickOrder(history, orderId) {
  return dispatch => {
    history.push(`/order/${orderId}`)
  }
}
export const doChangeStatusFilter = createAction(types.UPDATE_STATUS_FILTER)
export const doChangeDateFilter = createAction(types.UPDATE_DATE_FILTER)
export const doChangeSearchText = createAction(types.UPDATE_SEARCH_TEXT)
export const doUpdateOrderList = createAction(types.UPDATE_ORDER_LIST)
export function doLoadOrdersPage() {  
  return dispatch => {
    api.rMemberOrders().then(result => {
      dispatch(doUpdateOrderList({ orderList: result }));
    }).catch((errMsg) => {
      console.log(errMsg);
    })
  }
}


