import { handleActions } from 'redux-actions';
// import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  // 'login/EDIT_LOGIN': (state, { payload }) => {
  //   if (payload.type === 'account') {
  //     return state.setIn(['account'], payload.value);
  //   } else {
  //     return state.setIn(['password'], payload.value);
  //   }
  // },
  // 'login/UPDATE_ERROR_MESSAGE': (state, { payload }) => {
  //   console.log(payload.message)
  //   console.log(state.getIn(['errMessage']))
  //   state.setIn(['errMessage'], payload.message)
  //   console.log(state.getIn(['errMessage']))
  //   return state.setIn(['errMessage'], payload.message);
  // }  
}, State);

export default Reducer;