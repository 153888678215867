import { createAction } from 'redux-actions';
import { message } from 'antd';
import * as types from './actionTypes';
import * as api from '../../utils/api';


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

export const doUpdateProduct = createAction(types.UPDATE_PRODUCT)
export function doLoadProduct(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const products = state.getIn(['products', 'products']);
    dispatch(doUpdateBuyQuantity({ buyQuantity: 1 }))
    api.rPackage(payload.productId).then(result => {   
      dispatch(doUpdateProduct(result));
    }).catch((errMsg) => {
      console.log(errMsg)
    })      
  }
}

export const doUpdateBuyQuantity = createAction(types.UPDATE_BUY_QUANTITY)
export function doChangeBuyQuantity(payload) {
  return (dispatch, getState) => {
    const state = getState();
    const quantity = state.getIn(['product', 'product', 'quantity']);
    if (payload.buyQuantity > quantity) {
      dispatch(doUpdateBuyQuantity({ buyQuantity: quantity }));
    } else if (payload.buyQuantity < 1) {
      dispatch(doUpdateBuyQuantity({ buyQuantity: 1 }));
    } else {
      dispatch(doUpdateBuyQuantity(payload));
    }
  }
}

export const doUpdateBuyProducts = createAction(types.UPDATE_BUY_PRODUCTS)
export const doUpdateShoppingCount = createAction(types.UPDATE_SHOPPING_COUNT)
export function doClikcAddToShoppingCart(productId) {
  return (dispatch, getState) => {
    const state = getState();
    const products = state.getIn(['products', 'products']);
    const buyProducts = state.getIn(['shoppingProgress', 'buyProducts']);
    let canShippingSelf = true;
    let canShippingDelivery = true;
    let shippingSelfDateList = [];
    let shippingDeliveryDateList = [];
    buyProducts.keySeq().forEach(packageId => {
      if (products.getIn([packageId, 'shippingSelf']) === false) {
        canShippingSelf = false;
      }
      if (products.getIn([packageId, 'shippingDelivery']) === false) {
        canShippingDelivery = false;
      }
      if (products.getIn([packageId, 'shippingSelfDateList']).size > 0) {
        if (shippingSelfDateList.length === 0) {
          shippingSelfDateList = products.getIn([packageId, 'shippingSelfDateList']).toJS();
        } else {
          shippingSelfDateList = shippingSelfDateList.filter(shippingDate => products.getIn([packageId, 'shippingSelfDateList']).indexOf(shippingDate) !== -1);
        }
      }
      if (products.getIn([packageId, 'shippingDeliveryDateList']).size > 0) {
        if (shippingDeliveryDateList.length === 0) {
          shippingDeliveryDateList = products.getIn([packageId, 'shippingDeliveryDateList']).toJS();
        } else {
          shippingDeliveryDateList = shippingDeliveryDateList.filter(shippingDate => products.getIn([packageId, 'shippingDeliveryDateList']).indexOf(shippingDate) !== -1);
        }
      }
    })
    const addProduct = products.getIn([productId]);
    if (addProduct.getIn(['shippingSelf']) === false) {
      canShippingSelf = false;
    }
    if (addProduct.getIn(['shippingDelivery']) === false) {
      canShippingDelivery = false;
    }
    // 一部份商品只允許自取、另有一部份商品只允許宅配
    if (canShippingSelf === false && canShippingDelivery === false) {
      message.error('購物車已包含其它限期商品，並與此商品的取貨方式衝突，請分開訂購');
      return;
    }
    // 已存在限定自取日期的商品，新加入的限期商品至少要有一天重疊
    if (canShippingSelf === true && shippingSelfDateList.length > 0 && addProduct.getIn(['shippingSelfDateList']).size > 0) {
      if (!shippingSelfDateList.some(shippingDate => addProduct.getIn(['shippingSelfDateList']).indexOf(shippingDate) !== -1)) {
        message.error('購物車已包含其它限期商品，並與此商品的取貨日期衝突，請分開訂購');
        return;
      }
    }
    // 已存在限定宅配日期的商品，新加入的限期商品至少要有一天重疊
    if (canShippingDelivery === true && shippingDeliveryDateList.length > 0 && addProduct.getIn(['shippingDeliveryDateList']).size > 0) {
      if (!shippingDeliveryDateList.some(shippingDate => addProduct.getIn(['shippingDeliveryDateList']).indexOf(shippingDate) !== -1)) {
        message.error('購物車已包含其它限期商品，並與此商品的取貨日期衝突，請分開訂購');
        return;
      }
    }

    const qty = addProduct.getIn(['quantity']);
    const buyQuantity = state.getIn(['product', 'buyQuantity']);
    let nowBuyQty = buyProducts.getIn([productId])
    if (!nowBuyQty) {
      nowBuyQty = 0;
    }
    if (nowBuyQty + buyQuantity <= qty) {
      dispatch(doUpdateBuyProducts({ productId, buyQuantity }));
      dispatch(doUpdateShoppingCount({ count: buyQuantity }));
      message.info('已加入購物車');
    } else {
      message.info('該商品可購買數量已滿');
    }
  }
}
