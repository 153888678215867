import Immutable from 'immutable';

export const State = Immutable.fromJS({
  name: '',
  gender: 'male',
  email: '',
  phone: '',
  city: '台北市',
  address: '',
  TaiwanCity: {},
});
