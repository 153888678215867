import React, { Component } from 'react';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import product from '../modules/product';
// images
import productBanner from '../images/productBanner.png'


class ProductPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div id="page-product">
        <headerBar.container/>

        <div className="ProductBannerTitle">
          <p className="center">產品明細</p>
        </div> 
        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">產品明細</p>
          <p className="col-auto DirectionTitle">首頁 / 線上購物 / 產品明細</p>
        </div>

        <product.container
          productId={this.props.match.params.productId}
        />

        <footer.container/>
	    </div>
	  )
  }
}

export default ProductPageComponent;