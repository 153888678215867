import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { ORDER_STATUS } from '../../utils/constants/order';


class OrdersSearchComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.onLoadOrdersSearchPage();
  }    
  render() {
    const { 
      orderPhone, orderId, order,
      onChangeOrderPhone, onChangeOrderId, onClickSearch
    } = this.props;
  	return (
	    <div className="container-fluid padding0">
        <form onSubmit={(event) => {event.preventDefault();onClickSearch()}}>
          <div className="row justify-content-end align-items-center">
            <div className="col-md-2 text-right normalFont">
              訂購人手機號碼：
            </div> 
            <div className="col-md-3">
              <input className="text-left" type="number" value={orderPhone} onChange={(event) => onChangeOrderPhone(event.target.value)} required/>
            </div>
            <div className="col-md-2 text-right normalFont">
              訂單編號：
            </div>
            <div className="col-md-3">
              <input className="text-left" type="text" value={orderId} onChange={(event) => onChangeOrderId(event.target.value)} required/>
            </div>
            <div className="col-md-2 text-right">
              <button className="aboutBtn" type="submit">查詢</button>
            </div>
          </div>
        </form>
        {
          order.getIn(["orderId"])
          &&
          <table className="table table-responsive-md align-middle TableList">
            <thead className="OrdersListHead">
              <tr>            
                <th scope="col">訂單編號</th>
                <th scope="col">訂購金額</th>
                <th scope="col">訂單日期</th>
                <th scope="col">訂單狀態</th>
                <th scope="col">物流狀態</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="OrdersTH">{order.getIn(["orderId"])}</td>
                <td className="OrdersTH">{order.getIn(["balanceDue"])}</td>
                <td className="OrdersTH">{order.getIn(["orderDate"])}</td>
                <td className="OrdersTH">{ORDER_STATUS[order.getIn(["status"])]}</td>
                {
                  ( order.getIn(["devlieryMethod"]) === 1 && order.getIn(["deliveryNo"]) )
                  ?
                  <a href={`http://www.t-cat.com.tw/Inquire/TraceDetail.aspx?BillID=${order.getIn(["deliveryNo"])}`} onClick={(event) => {event.stopPropagation()}} target="_blank">
                    <td className="OrdersTH">查詢</td>
                  </a>  
                  :
                  <td className="OrdersTH"></td>
                }
              </tr> 
            </tbody>
          </table>
        }
	    </div>
	  )
  }
}

export default withRouter(OrdersSearchComponent);