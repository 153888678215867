import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import stores from '../modules/stores';
import store from '../modules/store';
import googleMap from '../modules/googleMap';
// images
import storesBanner from '../images/storesBanner.png'


class StoresPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div>
        <Helmet>
          <title>隆太國際有限公司-公司簡介</title>
          <meta name="description" content="「隆太國際有限公司」。提供各項專業的交通安全服務，專營各項交通安全設施產品，如碰撞緩衝設施、鋼索型碰撞防護體、滾筒式護欄、TMA車體碰撞防護器、LED標誌指示燈及交通安全等。" />
        </Helmet>
        <headerBar.container/>

        {/* <div className="BannerTitle StoresBannerTitle">
          <p className="center">門市資訊</p>
        </div>  */}
        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">公司簡介</p>
          <p className="col-auto DirectionTitle">首頁 / 公司簡介</p>
        </div>

        <div className="container align-items-center StoresMap">
          <div className="row">
            <div className="col-12">
              <p className="text-left mb-5">
                「隆太國際有限公司」，本公司秉持著<span className="point-font">「誠信、專業、安全」</span>的原則，提供各項專業的交通安全服務，專營各項交通安全設施產品，如 <span className="point-font">TMA車體碰撞防護器、滾筒式護欄、碰撞緩衝設施、鋼索型碰撞防護體</span>、LED標誌指示燈及交通安全等，各種相關交通安全設施均通過美國 MASH / NCHRP 測試報告，可安全、合法的使用於公路上，大幅降低事故所帶來的傷害。<br />
                <br />
                公司自2007年創立至今，全省北、中、南均有安裝維修服務據點，每一服務據點均通過美國原廠授權，並定期教育訓練，確保安裝服務技術與國外原廠同步，提供客戶最佳、最安全的技術保證。<br />
                全面性客製化服務，提供客戶支援車輛、保險規劃以及特殊車輛打造，搭配掃街車、水車、灑水車等客製化打造，為客戶提供完善的服務。<br />
                多年來，我們堅持用最<span className="point-font">誠信</span>的服務態度，提供客戶最<span className="point-font">專業</span>的服務及規劃，一起守護道路交通<span className="point-font">安全</span>。
              </p>
            </div>
          </div>
          <div div className="row justify-content-center align-items-center">
            <div className="col-lg-7 MapBox">
              {
                // this.props.coordinate
                // &&
                // <googleMap.component
                //   longitude={parseFloat(this.props.coordinate.split(",")[0])}
                //   latitude={parseFloat(this.props.coordinate.split(",")[1])}
                // />          
              }
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.6598849340353!2d120.33468651496048!3d22.62917258515362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e04b610545ea5%3A0xda5e0fe73745b6e5!2zODAy6auY6ZuE5biC6IuT6ZuF5Y2A5Lit5q2j5LiA6LevNTblt7c0M-iZnw!5e0!3m2!1szh-TW!2stw!4v1589521409251!5m2!1szh-TW!2stw" style={{width:"100%",height:"400px"}} frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>         
            <div className="col-lg-5 pt-5">
              {/* <store.container
                storeData={this.props.storeData}
              /> */}
              <p className="Text_24 text-left pl-2">隆太國際有限公司</p>
              <p className="Text_18"></p>
              <div className="StoreContent mb-5">
                <p className="Name"></p>
                <div className="Content">
                  <a href={`tel:077491118`}>電話：07-749-1118 (代表號)</a>
                  <p>地址：802 高雄市苓雅區中正一路 56 巷 43-1 號</p>
                  <p>工廠：	美國舊金山</p>
                  <p>海外辦事處：美國 / 韓國</p>
                  <p><a href={`mailto:longtime888@hotmail.com.tw`}>E-mail：longtime888@hotmail.com.tw</a></p>
                  <p>
                    {/* <img src={locationIcon} alt=""/> */}
                  </p>
            </div>
          </div>
            </div>
          </div>
        </div>  
        
        {/* <stores.container/> */}
        <footer.container/>
	    </div>
	  )
  }
}

const mapStateToProps = (state) => ({
  coordinate: stores.selectors.getSelectedStoreCoordinate(state),
  storeData: stores.selectors.getSelectedStore(state)
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoresPageComponent);