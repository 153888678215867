import { createAction } from 'redux-actions';
import { message } from 'antd';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

export function doIsLoggedIn(history) {
  return dispatch => {
    const stormedToken = cookie.getCookie('stormedToken');
    if (stormedToken === '') {
      history.push(`/login`);
    } else {
      api.cCheckToekn().then(result => {
      }).catch((errMsg) => {
        if (errMsg === 'E401') {
          message.info('Invalid Token');
          cookie.setCookie('stormedToken', '');
          history.push('/');
        }
      })
    }  
  }
}

export const doUpdateId = createAction(types.UPDATE_ID)
export const doUpdatePaymentDate = createAction(types.UPDATE_PAYMENT_DATE)
export const doUpdatePaymentMethod = createAction(types.UPDATE_PAYMENT_METHOD)
export const doUpdatePaymentStatus = createAction(types.UPDATE_PAYMENT_STATUS)
export const doUpdateStatus = createAction(types.UPDATE_STATUS)
export const doUpdateTotalAmount = createAction(types.UPDATE_TOTAL_AMOUNT)
export const doUpdateShippingCost = createAction(types.UPDATE_SHIPPING_COST)
export const doUpdateBalanceDue = createAction(types.UPDATE_BALANCE_DUE)
export const doUpdatePakcageList = createAction(types.UPDATE_PACKAGE_LIST)
export function doLoadOrderDetail(orderId) {  
  return dispatch => {
    api.rOrder(orderId).then(result => {  
      dispatch(doUpdateId({ id: result.id.substring(2) }));
      dispatch(doUpdatePaymentDate({ paymentDate: result.paymentDate }));
      dispatch(doUpdatePaymentMethod({ paymentMethod: result.paymentMethod }));
      dispatch(doUpdatePaymentStatus({ paymentStatus: result.paymentStatus }));
      dispatch(doUpdateStatus({ status: result.deliveryStatus }));
      dispatch(doUpdateTotalAmount({ totalAmount: result.totalAmount }));
      dispatch(doUpdateShippingCost({ shippingCost: result.shippingCost }));
      dispatch(doUpdateBalanceDue({ balanceDue: result.balanceDue }));
      dispatch(doUpdatePakcageList({ packageList: result.packageList }));
    }).catch((errMsg) => {
      console.log(errMsg);
    })    
  }
}
