import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import * as api from '../../utils/api';


export const doUpdateAboutUs = createAction(types.UPDATE_ABOUT_US)

export function doLoadAboutUs() {
	return dispatch => {
    api.rAboutUs().then(result => {
    	dispatch(doUpdateAboutUs({ about: result.webContent }));
    }).catch((errMsg) => {
      console.log(errMsg);
    })
  }
}
