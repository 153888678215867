import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
// images
import minusIcon from '../../images/minus.svg'; // -
import plusIcon from '../../images/plus.svg'; // +


class ShoppingCartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentDidMount() {
    this.props.onLoadProducts();
  }
  render() {
    const {
      buyProducts, products, totalAmount,
      onClickRemoveBuyProduct, onUpdateBuyProductQuantity, onClickProgress, onUpdateShoppingData
    } = this.props;
    return (
      <div className="ShoppingCartBox" key={`shoppingCart`}>

        <div className="arrow arrow-border"></div>
        <div className="arrow arrow-bg"></div>

        <p className="ShoppingCartText">最近加入的商品</p>


        <div className="ShoppingCartOverflow">
        {
          (buyProducts, products)
          &&
          buyProducts.keySeq().map((productId, index) =>
            <div className="ShoppingCartContent_headerbar" key={`shopping_cart_${productId}`}>

              <div className="row ShoppingCartCommodityBlock">
                <div className="col ShoppingCartImgBox">
                  <img className="ShoppingCartImg" src={`/packages/sxs/${products.getIn([productId, "image"])}`} alt={products.getIn([productId, "name"])} />
                </div>
                <div className="col">
                  <p className="ShoppingCartTitle">{products.getIn([productId, "name"])}</p>
                  <div className="d-flex align-items-center QuantityBar">
                    <button className="Minus" type="button" onClick={(event) => onUpdateBuyProductQuantity(productId, -1)} disabled={buyProducts.getIn([productId]) == 1}><img src={minusIcon} alt="" /></button>
                    <input className="QuantityNumber" type="number" name="quantity" value={buyProducts.getIn([productId])} onChange={(event) => onUpdateBuyProductQuantity(productId, event.target.value)} disabled />
                    <button className="Plus" type="button" onClick={(event) => onUpdateBuyProductQuantity(productId, +1)} disabled={products.getIn([productId, "quantity"]) <= buyProducts.getIn([productId])}><img src={plusIcon} alt="" /></button>                   
                  </div>
                </div>
                <div className="PriceBox">
                  <p className="ShoppingCartPrice">${+products.getIn([productId, "price"]) * +buyProducts.getIn([productId])}</p>
                  <button className="CheckCartBtnDelete" type="button" onClick={() => onClickRemoveBuyProduct(productId)}>刪除</button>
                </div>
              </div>
              { /* 最後一個不顯示分隔線 */
                index !== buyProducts.keySeq().size - 1
                &&
                <div className="ShoppingCartDividers"></div>
              }
            </div>
          )
        }
        </div>
        {
          buyProducts.keySeq().size > 0
          &&
          <div className="CheckCartBtnBox">
            <button className="CheckCartBtn" type="button" onClick={() => onClickProgress(this.props.history,0)}>查看購物車</button>
            &nbsp;&nbsp;&nbsp;
            <button className="CheckCartBtn" type="button" onClick={() => { onUpdateShoppingData(`${totalAmount}`, "totalAmount"); onClickProgress(this.props.history,1); }}>立即結帳</button>
          </div>
        }
      </div>
    )
  }
}

export default withRouter(ShoppingCartComponent);