import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import * as cookie from '../../utils/cookiesTool';
import * as api from '../../utils/api';

export const doUpdateErrorMessage = createAction(types.UPDATE_ERROR_MESSAGE)
export const doUpdateIsReset = createAction(types.UPDATE_IS_RESET)
export const doEditResetPassword = createAction(types.EDIT_RESET_PASSWORD)


export function doInitModule() {
  return dispatch => {
    dispatch(doEditResetPassword({ type: 'password', value: '' }));
    dispatch(doEditResetPassword({ type: 'confirmPassword', value: '' }));
    return dispatch(doUpdateIsReset({ isReset: false }));
  }
}

export function doSubmitResetPassword(history, token) {
  return (dispatch, getState) => {
  	const state = getState();
  	const password = state.getIn(['resetPasswordWithToken', 'password']);
  	const confirmPassword = state.getIn(['resetPasswordWithToken', 'confirmPassword']);

  	dispatch(doUpdateErrorMessage({ message: '' }));

    if (password !== confirmPassword) {
      dispatch(doEditResetPassword({ type: 'password', value: '' }));
      dispatch(doEditResetPassword({ type: 'confirmPassword', value: '' }));
      dispatch(doUpdateErrorMessage({ message: '密碼不一致，請重新輸入' }));
      return;
    }

    cookie.setCookie('stormedToken', token);
    api.uPasswordReset(password).then(result => {  
  		dispatch(doUpdateIsReset({ isReset: true }));
    }).catch((errMsg) => {
      if (errMsg === 'E401') {
        history.push(`/login`);
      } else if (errMsg === 'E400') {
        dispatch(doUpdateErrorMessage({ message: 'Bad Request'}));
      } else if (errMsg === 'E002') {
        // dispatch(doUpdateErrorMessage({ message: '密碼需8位數以上且英數字大小寫混和'}));
        dispatch(doUpdateErrorMessage({ message: '密碼需4位數以上'}));
      }
    })
  }
}
