import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import LoginLineComponent from './component';


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch => ({
  onClickLoginLine: (history) => {
    dispatch(actions.doClickLoginLine(history));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginLineComponent);