import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ShoppingProgressComponent from './component';


const mapStateToProps = (state) => ({
  isLoggedIn: selectors.getIsLoggedIn(state),
  isCompleted: selectors.getIsCompleted(state),
  currentStep: selectors.getCurrentStep(state),

  buyProducts: selectors.getBuyProducts(state),
  products: selectors.getProducts(state),
  canShippingSelf: selectors.getCanShippingSelf(state),
  canShippingDelivery: selectors.getCanShippingDelivery(state),
  shippingSelfDateList: selectors.getShippingSelfDateList(state),
  shippingDeliveryDateList: selectors.getShippingDeliveryDateList(state),
  totalAmount: selectors.getTotalAmount(state),

  storeIdNames: selectors.getStoreIdNames(state),
  deliveryMethod: selectors.getDeliveryMethod(state),
  deliveryDate: selectors.getDeliveryDate(state),
  deliveryTime: selectors.getDeliveryTime(state),
  storeId: selectors.getStoreId(state),
  storeName: selectors.getStoreName(state),
  
  purchaserName: selectors.getPurchaserName(state),
  purchaserGender: selectors.getPurchaserGender(state),
  purchaserEmail: selectors.getPurchaserEmail(state),
  purchaserPhone: selectors.getPurchaserPhone(state),
  purchaserAddress: selectors.getPurchaserAddress(state),
  purchaserCity: selectors.getPurchaserCity(state),
  
  asPurchaserData: selectors.getAsPurchaserData(state),
  deliveryName: selectors.getDeliveryName(state),
  deliveryGender: selectors.getDeliveryGender(state),
  deliveryPhone: selectors.getDeliveryPhone(state),
  deliveryAddress: selectors.getDeliveryAddress(state),  
  deliveryCity: selectors.getDeliveryCity(state), 

  paymentMethod: selectors.getPaymentMethod(state),  

  couponCode: selectors.getCouponCode(state),
  couponId: selectors.getCouponId(state),
  couponContent: selectors.getCouponContent(state),
  gift: selectors.getGift(state),
  giftId: selectors.getGiftId(state),  

  invoiceType: selectors.getInvoiceType(state),
  invoiceUbn: selectors.getInvoiceUbn(state),
  invoiceTitle: selectors.getInvoiceTitle(state),

  greetings: selectors.getGreetings(state),
  note: selectors.getNote(state),     

  errorMessage: selectors.getErrorMessage(state),
  isAgreeTerms: selectors.getIsAgreeTerms(state), 
  shippingCost: selectors.getShippingCost(state),
  discount: selectors.getDiscount(state),
  deduction: selectors.getDeduction(state),
  balanceDue: selectors.getBalanceDue(state),

  orderId: selectors.getOrderId(state),

  TaiwanCity: selectors.getTaiwanCity(state),
  islands: selectors.getIslands(state),
});

const mapDispatchToProps = dispatch => ({
  onIsLoggedIn: (history) => {
    dispatch(actions.doIsLoggedIn(history));
  },
  onUpdateBuyProductQuantity: (productId, buyQuantity) => {
    dispatch(actions.doUpdateBuyProductQuantity(productId, buyQuantity));
  },   
  onClickRemoveBuyProduct: (productId) => {
    dispatch(actions.doClickRemoveBuyProduct(productId, 0));
  },    
  onLoadStoreIdNames: () => {
    dispatch(actions.doLoadStoreIdNames());
  },
  onLoadTaiwanCity: () => {
    dispatch(actions.doLoadTaiwanCity());
  },    
  onUpdateShoppingData: (value, type) => {
    dispatch(actions.doUpdateShoppingData({ value, type }));
  },      
  onClickAsPurchaserData: (isAsPurchaserData) => {
    dispatch(actions.doClickAsPurchaserData(isAsPurchaserData));
  },       
  onClickCheckCoupon: () => {
    dispatch(actions.doCheckCoupon());
  },        
  onOrderPreprocessing: () => {
    dispatch(actions.doOrderPreprocessing());
  },
  onClickSubmitOrder: (history) => {
    dispatch(actions.doClickSubmitOrder(history));
  },
  onInitOrderData: () => {
    dispatch(actions.doInitOrderData());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingProgressComponent);