import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';

import A_00 from '../images/announcement-0.jpg';
import A_01 from '../images/announcement-1.png';
import A_02 from '../images/announcement-2.png';
import A_03 from '../images/announcement-3.png';
import A_04 from '../images/announcement-4.jpg';
import A_05 from '../images/announcement-5.jpg';
import A_06 from '../images/announcement-6.jpg';

// images
// import newsBanner from '../images/newsBanner.png'


class AnnouncemntPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div className="container-fluid padding0" id="page-news">
      <Helmet>
        <title></title>
        <meta name="description" content="" />
      </Helmet>
        <headerBar.container/>
        
        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">最新公告</p>
          <div className="col-auto DirectionTitle">首頁 / 最新公告</div>
        </div>

        <div className="container align-items-center">
          <div className="row">
            <div className="col-md-12 col-12 mb-3">
              <img className="AnnouncemntImg" src={A_06}/>
            </div>
            <div className="col-md-12 col-12 mb-3">
              <img className="AnnouncemntImg" src={A_05}/>
            </div>
            <div className="col-md-12 col-12 mb-3">
              <img className="AnnouncemntImg" src={A_04}/>
            </div>
            <div className="col-md-12 col-12 mb-3">
              <img className="AnnouncemntImg" src={A_00}/>
            </div>
            <div className="col-md-12 col-12 mb-3">
              <img className="AnnouncemntImg" src={A_01}/>
            </div>
            <div className="col-md-12 col-12 mb-3">
              <img className="AnnouncemntImg" src={A_02}/>
            </div>
            <div className="col-md-12 col-12 mb-3">
              <img className="AnnouncemntImg" src={A_03}/>
            </div>
          </div>
        </div>
        

        <footer.container/>
	    </div>
	  )
  }
}

export default AnnouncemntPageComponent;
