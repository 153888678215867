import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import * as api from '../../utils/api';


export const doUpdateFirstIndex = createAction(types.UPDATE_FIRST_INDEX)
export function doClickToLeft() {
	return (dispatch, getState) => {
		const state = getState();
		const firstIndex = state.getIn(['featured', 'firstIndex'])
		if (firstIndex === 0) {
      return dispatch(doUpdateFirstIndex({ firstIndex: firstIndex }));
		} else {
			return dispatch(doUpdateFirstIndex({ firstIndex: firstIndex - 1 }));
		}
  }  
}	
export function doClickToRight() {
	return (dispatch, getState) => {
		const state = getState();
		const SIZE = state.getIn(['featured', 'SIZE'])
		const featuredList = state.getIn(['featured', 'featuredList'])
		const firstIndex = state.getIn(['featured', 'firstIndex'])
		if (firstIndex + SIZE >= featuredList.size) {
      return dispatch(doUpdateFirstIndex({ firstIndex: featuredList.size - SIZE }));
		} else {
			return dispatch(doUpdateFirstIndex({ firstIndex: firstIndex + 1 }));
		}
  }  
}	

export const doUpdateFeaturedList = createAction(types.UPDATE_FEATURED_LIST)
export function onLoadFeatuerdMenu(type) {
	return dispatch => {
    api.rFeatured(type).then(result => {
    	dispatch(doUpdateFeaturedList({ featuredList: result }));
    }).catch((errMsg) => {
      console.log(errMsg);
    })    
  }
}