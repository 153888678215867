import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import aboutUs from '../modules/aboutUs';
import media from '../modules/media';
import featured from '../modules/featured';

// images
// import mainBanner from '../images/mainBanner.jpg'
// import mainBrand from '../images/mainBrand.png'
// import mainNews from '../images/mainNews.png'
// import mainStore from '../images/mainStore.png'
// import mainShopping from '../images/mainShopping.png'
// import mainKungfuNoodle from '../images/mainKungfuNoodle.png'
// import mainProductHistory from '../images/mainProductHistory.png'
// import mainFansDivider from '../images/mainFansDivider.png'
import logoBanner from '../images/logo_banner.png'
import btnDown from '../images/btn_Down.png'
import DecorativeLine from '../images/DecorativeLine.svg'
import TitleMedia from '../images/title_media.svg'
import Adv_photo_01 from '../images/Adv_photo_01_20200316.jpg'
import Adv_photo_02 from '../images/Adv_photo_01_20200406.jpg'

class MainPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onClickScrollToBottom() {
    const node = ReactDOM.findDOMNode(this.bottom);
    node.scrollIntoView({ behavior: "smooth" });
  }
  render() {
    return (
      <div id="page-main">
      <Helmet>
        <title>隆太國際有限公司</title>
        <meta name="description" content="「隆太國際有限公司」。提供各項專業的交通安全服務，專營各項交通安全設施產品，如碰撞緩衝設施、鋼索型碰撞防護體、滾筒式護欄、TMA車體碰撞防護器、LED標誌指示燈及交通安全等。" />
        <meta name="keywords" content="隆太國際、緩撞車、TMA、滾筒式護欄、車體碰撞防護器、緩撞工程車" />
      </Helmet>
        <headerBar.container />

        <div className="mainBanner">
          <img className="btnDown" src={btnDown} alt="" onClick={() => this.onClickScrollToBottom()} />    
        </div> 

        <aboutUs.container />


        {/* <div className="">
          <div class="row Adv_box">
            <div class="col-sm AdvContentBox">
              <div className="AdvImgBox">
                <Link to={`/products/8`}><img className="img-fluid rounded mx-auto d-block" src={Adv_photo_01} alt="" /></Link>
                <div className="AdvTextBox">
                  <p class="AdvTitle">【單身雞湯】任選6包自由配！享免運。</p>
                  <p class="AdvText">湯鮮、料好、覆熱快！讓您輕鬆飽胃全家，增加健康防禦力！</p>
                  <Link to={`/products/8`}><button className="AdvBtn" type="button">立即訂購</button></Link>
                </div>
              </div>
            </div>

            <div class="col-sm AdvContentBox">
              <div className="AdvImgBox">
                <Link to={`/products`}><img className="img-fluid rounded mx-auto d-block" src={Adv_photo_02} alt="" /></Link> 
                <div className="AdvTextBox">
                  <p class="AdvTitle">【在家開飯最暖心】ABC套餐 限量50組！</p>
                  <p class="AdvText">多樣經典名菜與功夫湯品，陪伴您在家幸福吃飯的時刻。</p>
                  <Link to={`/products`}><button className="AdvBtn" type="button">立即訂購</button></Link> 
                </div>
              </div>
            </div>
          </div>
        </div> */}









        <div className="blockGole">
        </div>
        





        {/* <div className="mediaCoverage">
          <div className="container-fluid">
            <div className="row">
              <div className="col"><img className="mainPage_Title rounded mx-auto d-block" src={TitleMedia} alt="" /></div>
            </div>
            <media.container />
          </div>
        </div> */}

        {/* <div className="FBBOX">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-8 col-xl-6">
                <div className="use-flexbox row">
                  <div className="col-md-12"><p className="FBText_1">快來粉絲團按讚獲得最新消息吧！</p></div>
                  <div className="col-md-12"><p className="FBText_2"></p></div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-xl-6">
                <div className="col-md-12">
                  <div className="fb-page" data-href="https://www.facebook.com/tianlong3126952/" data-tabs="timeline,messages" data-width="500" data-height="500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/tianlong3126952/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/tianlong3126952/">高灃帆布/天龍帆布</a></blockquote></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}


        <footer.container ref={(el) => { this.bottom = el }} />
      </div>
    )
  }
}

export default MainPageComponent;

(function (d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = 'https://connect.facebook.net/zh_TW/sdk.js#xfbml=1&version=v2.11';
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'));