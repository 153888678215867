import fetch from 'isomorphic-fetch';
import * as config from '../config';
import * as cookie from './cookiesTool';


export function request(method, url, data={}) {
  method = method.toUpperCase();
  let body;
  if (method === 'GET') {
      body = undefined;
  } else {
      body = data && JSON.stringify(data);
  }
  let headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'    
  }
  const token = cookie.getCookie('stormedToken');
  if (token) {
    headers['stormedToken'] = `${cookie.getCookie('stormedToken')}`;
  }
  return (
    fetch(url, {
      method,
      headers,
      body
    })
    .then((response) => {
      return response.json()
        .then((json) => {
          return ({ response, json });
        });
    })
    .then(({ response, json }) => {
      if (response.ok) {
        return json;
      } else {
        if (json.hasOwnProperty('errorCode')) {
          throw json.errorCode;
        } else {
          throw config.DEFAULT_ERROR_MSG;
        }
      }
    })
    .catch((err) => {
      throw err;
    })   
  ) 
}

// common
export const cCheckToekn = () => request('GET', `${config.baseUrl}/auth/token/check`);
export const rCities = () => request('GET', `${config.baseUrl}/info/cities`);
export const rProfile = () => request('GET', `${config.baseUrl}/members`);
export const rPartialPackages = (packageIdList) => request('POST', `${config.baseUrl}/packages`, { packageIdList });
export const rEcpayParams = (orderId) => request('GET', `${config.baseUrl}/3rd-party/ecpay/params?id=${orderId}`);
// aboutUs
export const rAboutUs = () => request('GET', `${config.baseUrl}/info/web?kind=2`);
// coupons
export const rCoupons = () => request('GET', `${config.baseUrl}/coupons`);
// featured
export const rFeatured = (type_) => request('GET', `${config.baseUrl}/info/featured?type=${type_}`);
// forgotPassword
export const cPasswordForgot = (email) => request('POST', `${config.baseUrl}/auth/password/forgot`, { email });
// login
export const cLogin = (account, password) => request('POST', `${config.baseUrl}/auth/login/account`, { account, password });
// loginFb
export const rFbAuthUrl = () => request('GET', `${config.baseUrl}/auth/login/fb`);
// loginLine
export const rLineAuthUrl = () => request('GET', `${config.baseUrl}/auth/login/line`);
// media
export const rMedia = () => request('GET', `${config.baseUrl}/info/media`);
// news
export const rNews = () => request('GET', `${config.baseUrl}/info/news?kind=News`);
export const rActivities = () => request('GET', `${config.baseUrl}/info/news?kind=Activities`);
// order
export const rOrder = (orderId) => request('GET', `${config.baseUrl}/orders/${orderId}`);
// orders
export const rMemberOrders = () => request('GET', `${config.baseUrl}/members/orders`);
// ordersSearch
export const rSearchOrder = (orderId, orderPhone) => request('POST', `${config.baseUrl}/orders/search`, { orderId, orderPhone });
// product
export const rPackage = (packageId) => request('GET', `${config.baseUrl}/packages/${packageId}`);
// productHistory
export const rProductHistory = () => request('GET', `${config.baseUrl}/info/inspection`);
// products
export const rProductCategories = () => request('GET', `${config.baseUrl}/packages/categories`);
export const rPackages = () => request('GET', `${config.baseUrl}/packages`);
// profile
export const uProfile = (name, gender, phone, city, address) => request('PUT', `${config.baseUrl}/members`, { name, gender, phone, city, address });
// registration
export const cRegistration = (name, gender, email, phone, city, address, password) => request('POST', `${config.baseUrl}/members/registration`, { name, gender, email, phone, city, address, password });
// resetPasswordWithOldPassword
// resetPasswordWithToken
export const uPasswordReset = (password, newPassword) => request('PUT', `${config.baseUrl}/members/password`, { password, newPassword });
// shoppingCart
// shoppingCompleted
export const cOrderCompleted = (orderEmail, orderId) => request('POST', `${config.baseUrl}/orders/completed`, { orderEmail, orderId });
// shoppingProgress
export const rCoupon = (totalAmount, couponCode) => request('POST', `${config.baseUrl}/coupons`, { totalAmount, couponCode });
export const cOrderCalculate = (buyPackages, couponCode, deliveryMethod, islands) => request('POST', `${config.baseUrl}/orders/calculate`, { buyPackages, couponCode, deliveryMethod, islands });
export const cOrder = (data) => request('POST', `${config.baseUrl}/orders`, data);
// stores
export const rStores = () => request('GET', `${config.baseUrl}/info/stores`);