import React from 'react';

export const ProductsG = (props) => {
  return (
    <div>
      <h2 className="productsTitle">產品影音說明</h2>
      <div>
        <div className="row">
          <div className="col-md-12 col-sm-12 List">
            <iframe width="100%" height={window.screen.width < 578 ? "315" : "630"} src="https://www.youtube.com/embed/ShxtCs2tt68" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p className="text-center">大型橋樑檢修車</p>
          </div>
          <div className="col-md-12 col-sm-12 List">
            <iframe width="100%" height={window.screen.width < 578 ? "315" : "630"} src="https://www.youtube.com/embed/UZWI2iIUpdM" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <p className="text-center">隆太TMA防撞工程車及交通產品</p>
          </div>
        </div>
      </div>
    </div>
  )
}