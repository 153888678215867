import React from 'react';
import B_01 from '../../../images/portfolio/portfolio_B001.jpg';
import B_02 from '../../../images/portfolio/portfolio_B002.jpg';
import B_03 from '../../../images/portfolio/portfolio_B003.jpg';
import B_04 from '../../../images/portfolio/portfolio_B004.jpg';
import B_05 from '../../../images/portfolio/portfolio_B005.jpg';
import B_06 from '../../../images/portfolio/portfolio_B006.jpg';

export const ProductsB = (props) => {
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_02}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_03}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_04}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_05}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_06}/>
          </div>
        </div>
      </div>
    </div>
  )
}