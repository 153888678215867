import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class ResetPasswordWithTokenComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.onInitModule();
  }
  render() {
    const { 
      isReset, password, confirmPassword, errMessage, token,
      onEditResetPassword, onSubmitResetPassword
    } = this.props;

  	return (
	    <div>
        {
          isReset
          ?
            <div className="container-fluid">

              <div className="row">

                <div className="LoginBox col-lg-7">
                  <div className="LoginBoxGoldBanner">
                    <img className="LoginBoxGoldBannerImg imgBox " alt="" />
                  </div>
                </div>

                <div className="LoginBox col-lg-5">
                  <div className="LoginBoxGold col-12">
                    <p className="LoginTitle">重新設定密碼</p>
                    <p className="PasswordText">密碼已重設，請使用新密碼登入！</p>
                    <Link to={`/login`}>
                      <button className="LoginBtn" type="button">返回登入頁</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          :
            <form onSubmit={(event) => {event.preventDefault();onSubmitResetPassword(this.props.history, token)}}>

              <div className="container-fluid">

                <div className="row">

                  <div className="LoginBox col-lg-7">
                    <div className="LoginBoxGoldBanner">
                      <img className="LoginBoxGoldBannerImg imgBox " alt="" />
                    </div>
                  </div>

                  <div className="LoginBox col-lg-5">
                    <div className="LoginBoxGold col-12">
                      <p className="LoginTitle">重新設定密碼</p>
                      <p className="PasswordText">請輸入新密碼</p>
                      <p>密碼需4位數以上</p>

                      <input type="password" placeholder="新密碼"
                        value={password}
                        onChange={(event) => onEditResetPassword(event.target.value, "password")}
                        required
                      />

                      <input type="password" placeholder="再次輸入新密碼"
                        value={confirmPassword}
                        onChange={(event) => onEditResetPassword(event.target.value, "confirmPassword")}
                        required
                      />

                      <p>{errMessage}</p>

                      <button className="LoginBtn" type="submit">送出</button>
                    </div>
                  </div>

                </div>

              </div>


            </form>
        }
	    </div>
	  )
  }
}

export default ResetPasswordWithTokenComponent;