import Immutable from 'immutable';

export const State = Immutable.fromJS({
	productHistoryCategoryIdList: [],
	productHistoryCategories: {},
	productHistoryIdList: [], 
	productHistory: {},
	selectedProductHistoryId: '0',
	selectedProductHistoryCategoryId: '0'
});
