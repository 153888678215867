import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'order/UPDATE_ID': (state, { payload }) => {
    return state.setIn(['id'], fromJS(payload.id));
  },
  'order/UPDATE_PAYMENT_DATE': (state, { payload }) => {
    return state.setIn(['paymentDate'], fromJS(payload.paymentDate));
  },
  'order/UPDATE_PAYMENT_METHOD': (state, { payload }) => {
    return state.setIn(['paymentMethod'], fromJS(payload.paymentMethod));
  },
  'order/UPDATE_PAYMENT_STATUS': (state, { payload }) => {
    return state.setIn(['paymentStatus'], fromJS(payload.paymentStatus));
  },  
  'order/UPDATE_STATUS': (state, { payload }) => {
    return state.setIn(['status'], fromJS(payload.status));
  },
  'order/UPDATE_TOTAL_AMOUNT': (state, { payload }) => {
    return state.setIn(['totalAmount'], fromJS(payload.totalAmount));
  },
  'order/UPDATE_SHIPPING_COST': (state, { payload }) => {
    return state.setIn(['shippingCost'], fromJS(payload.shippingCost));
  },
  'order/UPDATE_BALANCE_DUE': (state, { payload }) => {
    return state.setIn(['balanceDue'], fromJS(payload.balanceDue));
  },
  'order/UPDATE_PACKAGE_LIST': (state, { payload }) => {
    return state.setIn(['packageList'], fromJS(payload.packageList));
  },
}, State);

export default Reducer;