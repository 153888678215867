import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import * as api from '../../utils/api';


export const doClickStore = createAction(types.UPDATE_SELECTED_STORE_ID)
export const doUpdateStores = createAction(types.UPDATE_STORES)
export const doUpdateStoreIdList = createAction(types.UPDATE_STORE_ID_LIST)

export function doFetchStores() {
  return dispatch => {
    api.rStores().then(result => {     
      const storeIdList = [];
      const stores = {};
      result.forEach(store => {
        storeIdList.push(`${store.storeId}`);
        stores[store.storeId] = store;
      })
      dispatch(doUpdateStoreIdList({ storeIdList }));
      dispatch(doUpdateStores({ stores }));
      dispatch(doClickStore({ storeId: storeIdList[0] }));
    }).catch((errMsg) => {
      console.log(errMsg)
    })       
  }
}
