import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import CouponsIcon from '../../images/coupons_icon.svg'
import { COUPON_STATUS } from '../../utils/constants/coupon';


class CouponsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.onIsLoggedIn(this.props.history);
    this.props.onLoadCoupons();
  }    
  render() {
    const { 
      couponIdList, coupons, searchText, statusFilter, availableCouponCount,
      onChangeSearchText, onChangeStatusFilter
    } = this.props;

  	return (
	    <div className="CouponContent">       
        <div className="d-md-flex align-items-center CouponCount">
          <img src={CouponsIcon}/>
          <p>優惠券</p>
          <p className="ml-auto d-inline-flex CouponCountNumber">{availableCouponCount}張</p>
        </div>
        <div className="OrdersIdListContent">
          <div className="row justify-content-end align-items-center">
            <div className="col-md-4 text-right">
              <input className="SearchInput text-left" type="text" value={searchText} onChange={(event) => onChangeSearchText(event.target.value)}/>
            </div>
            <div className="col-md-4 text-right">
              <select className="Select text-left" value={statusFilter} onChange={(event) => onChangeStatusFilter(event.target.value)}>
                <option value="">全部</option>
                <option value="0">不可使用</option>
                <option value="1">可使用</option>
              </select>
            </div>
          </div>
            <table className="table table-responsive-md align-middle TableList">
            <thead className="OrdersListHead">
                <tr>
                  <th scope="col">折價券代碼</th>
                  <th scope="col">說明</th>
                  <th scope="col">抵用門檻</th>
                  <th scope="col">狀態</th>
                  <th scope="col">有效期限</th>
                </tr>
              </thead>
              <tbody>
                {
                  (couponIdList && coupons)
                  &&
                  couponIdList.map(couponId => (
                    <tr key={couponId}>
                      <td className="CounponTH">{coupons.getIn([couponId, "couponCode"])}</td>
                      <td className="CounponTH">{coupons.getIn([couponId, "couponDescription"])}</td>
                      <td className="CounponTH">{coupons.getIn([couponId, "minAmt"])}</td>
                      <td className="CounponTH">{COUPON_STATUS[`${coupons.getIn([couponId, "couponStatus"])}`]}</td>
                      <td className="CounponTH">{coupons.getIn([couponId, "startDay"])} ~ {coupons.getIn([couponId, "endDay"])}</td>
                    </tr>
                  ))
                }
              </tbody> 
            </table>                           
        </div>
        <div className="row justify-content-center">
          <div className="col-md-3">
            <Link to={`/orders`}>
              <button className="GLBtn" type="button">返回</button>
            </Link>
          </div>
        </div>
	    </div>
	  )
  }
}

export default withRouter(CouponsComponent);