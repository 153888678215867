import React from 'react';
import F_01 from '../../../images/portfolio/portfolio_F001.jpg';
import F_02 from '../../../images/portfolio/portfolio_F002.jpg';
import F_03 from '../../../images/portfolio/portfolio_F003.jpg';
import F_04 from '../../../images/portfolio/portfolio_F004.jpg';
import F_05 from '../../../images/portfolio/portfolio_F005.jpg';
import F_06 from '../../../images/portfolio/portfolio_F006.jpg';

export const ProductsF = (props) => {
  return (
    <div>
      <h2 className="productsTitle">特殊車輛</h2>
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={F_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={F_02}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={F_03}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={F_04}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={F_05}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={F_06}/>
          </div>
        </div>
      </div>
    </div>
  )
}