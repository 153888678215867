import React from 'react';
import C_01 from '../../../images/portfolio/portfolio_C001.jpg';
import C_02 from '../../../images/portfolio/portfolio_C002.jpg';
import C_03 from '../../../images/portfolio/portfolio_C003.jpg';
import C_04 from '../../../images/portfolio/portfolio_C004.jpg';
import C_05 from '../../../images/portfolio/portfolio_C005.jpg';
import C_06 from '../../../images/portfolio/portfolio_C006.jpg';

export const ProductsC = (props) => {
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_02}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_03}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_04}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_05}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={C_06}/>
          </div>
        </div>
      </div>
    </div>
  )
}