import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'orders/UPDATE_STATUS_FILTER': (state, { payload }) => {
    return state.setIn(['statusFilter'], payload.statusFilter);
  },	
  'orders/UPDATE_SEARCH_TEXT': (state, { payload }) => {
    return state.setIn(['searchText'], payload.searchText);
  },
  'orders/UPDATE_DATE_FILTER': (state, { payload }) => {
    return state.setIn(['dateFilter'], payload.dateFilter);
  },  
  'orders/UPDATE_ORDER_LIST': (state, { payload }) => {
    return state.setIn(['orderList'], fromJS(payload.orderList));
  },
  'orders/UPDATE_SELECTED_ORDER_ID': (state, { payload }) => {
    return state.setIn(['selectedOrderId'], fromJS(payload.selectedOrderId));
  },
}, State);

export default Reducer;