export const DELIVERY_METHOD = {
  1: '貨運宅配',
  2: '到店取貨',
};


export const ORDER_STATUS = {
  1: '訂單成立',
  2: '備貨中',
  3: '已出貨',
  4: '付款未成功',
  5: '訂單取消'
};


export const PAYMENT_METHOD = {
  1: '線上刷卡',
  2: '貨到付款'
};


export const INVOICE_TYPE = {
  1: '二聯式捐贈發票',
  2: '二聯式收銀機發票',
  3: '三聯式紙本發票'
};


export const DELIVERY_TIME = {
  0: '不指定',
  1: '早上',
  2: '下午'
};
