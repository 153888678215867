import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import RegistrationComponent from './component';


const mapStateToProps = (state) => ({
  name: selectors.getName(state),  
  email: selectors.getEmail(state),
  gender: selectors.getGender(state),
  city: selectors.getCity(state),
  address: selectors.getAddress(state),
  phone: selectors.getPhone(state),
  password: selectors.getPassword(state),
  confirmPassword: selectors.getConfirmPassword(state),
  errMessage: selectors.getErrMessage(state),  
  TaiwanCity: selectors.getTaiwanCity(state), 
});

const mapDispatchToProps = dispatch => ({
  onSubmitRegistration: (history) => {
    dispatch(actions.doSubmitRegistration(history));
  },
  onEditRegistration: (value, type) => {
    dispatch(actions.doEditRegistration({ value, type }));
  },
  onLoadPurchaserEmail: () => {
    dispatch(actions.doLoadPurchaserEmail());
  }   
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationComponent);