import React, { Component } from 'react';


class ForgotPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.onEditForgotPassword('');
  }  
  render() {
    const { 
      email,
      onEditForgotPassword, onSubmitForgotPassword
    } = this.props;

  	return (
	    <div>
        <form onSubmit={(event) => {event.preventDefault();onSubmitForgotPassword(this.props.history)}}>
          <p className="LoginTitle">忘記密碼</p>
          <p className="PasswordText">請輸入您的E-mail，系統將會寄送確認信給您</p>

          <input type="email" placeholder="user@domain.com" 
            value={email} 
            onChange={(event) => onEditForgotPassword(event.target.value)}
            required 
          />

          <button className="LoginBtn" type="submit">繼續</button>
        </form>
	    </div>
	  )
  }
}

export default ForgotPasswordComponent;