import React, { Component } from 'react';

import Print from './media/print';
import {Video} from './media/video';
import {Proclaim} from './media/proclaim';

class MediaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaTab: 'print',
    };
  }
  onClickChangeTab = (event) => {
    event.preventDefault()
    this.setState({
      mediaTab: event.target.name,
    })
  }
  render() {
    const {
      mediaTab
    } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 SideBox d-none d-lg-block">
            <p>
              ▶ 媒體報導
            </p>
            <p className={`MediaSubP ${mediaTab === 'print' ? "active" : ""} `}>
              <a name="print" onClick={this.onClickChangeTab}>▶ 平面報導</a>
            </p>
            <p className={`MediaSubP ${mediaTab === 'video' ? "active" : ""} `}>
              <a name="video" onClick={this.onClickChangeTab}>▶ 影音報導</a>
            </p>
            <p className={`MediaSubP ${mediaTab === 'proclaim' ? "active" : ""} `}>
              <a name="proclaim" onClick={this.onClickChangeTab}>▶ 安全宣導</a>
            </p>
          </div>
          <div className="row d-lg-none categorySelect">
          {
            <div className="col-sm-12">
              <select id="categorySelect" onChange={ event => this.setState({mediaTab: event.target.value})}>
                <option className="MediaSubP" value="print">▶ 平面報導</option>
                <option className="MediaSubP" value="video">▶ 影音報導</option>
                <option className="MediaSubP" value="proclaim">▶ 安全宣導</option>
              </select>
            </div>
          }
        </div>
          <div className="col-md-8 col-sm-12 ListContent">
            <div>
                {mediaTab === 'print' && <Print />}
                {mediaTab === 'video' && <Video />}
                {mediaTab === 'proclaim' && <Proclaim />}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MediaComponent;