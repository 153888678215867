import { createSelector } from 'reselect';

export const getSize = state => state.getIn(['featured', 'SIZE']);
export const getFeaturedList = state => state.getIn(['featured', 'featuredList']);
export const getFirstIndex = state => state.getIn(['featured', 'firstIndex']);
export const getSomeFeaturedList = createSelector(
  [getSize, getFirstIndex, getFeaturedList],
  (SIZE, firstIndex, featuredList) => {
  	if (featuredList) {
  	  return featuredList.filter((featured, index) => firstIndex <= index && index < firstIndex + SIZE)	
  	} else {
  		return []
  	}  	
  }
)

