import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import { DatePicker, Button } from 'antd';
import Button from 'antd/lib/button';

// import images
import logo_footer from '../../images/logo_footer.png';
import icon_fb_normal from '../../images/icon_fb_normal.png';
import icon_line_normal from '../../images/icon_line_normal.png';
import btn_top from '../../images/btn_TOP.png';
import line_QR_code from '../../images/cheftenglineQRcode.png';


class ScrollButton extends React.Component {
  constructor() {
    super();
    this.state = {
        intervalId: 0
    };
  }
  
  scrollStep() {
    if (window.pageYOffset === 0) {
        clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }
  
  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
    this.setState({ intervalId: intervalId });
  }
  
  render() {
    return (
      <div className="btn_top" onClick={() => this.scrollToTop()}>
        <img src={btn_top} alt="" />
      </div>
    )
  }
} 

class FooterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="footerAll">
        {/*
        <button className="btn_top" type="button">
          <img src={btn_top} alt="" />
        </button>
        */}

        <ScrollButton scrollStepInPx="50" delayInMs="5"/>        


        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <p className="Text_24">隆太國際有限公司</p>
            </div>

            <div className="footerText col-md-6">
              {/* <div className="dividers"></div> */}
              <p className="Text_18">地址：802 高雄市苓雅區中正一路 56 巷 43-1 號</p>
              <p className="Text_18"><a href={`tel:077491118`}>電話：07-749-1118 (代表號)</a></p>
              <p className="Text_18">工廠：	美國舊金山</p>
              <p className="Text_18">海外辦事處：美國 / 韓國</p>
              <p className="Text_18"><a href={`mailto:longtime888@hotmail.com.tw`}>E-mail：longtime888@hotmail.com.tw</a></p>
                {/* <div className="FBLINEBOX2">
                  <a href="https://www.facebook.com/ChefTengRG/" target="blank"><img src={icon_fb_normal} alt="" /></a>
                  <a href="https://line.me/R/ti/p/%40wtz8539j" target="blank"><img src={icon_line_normal} alt="" /></a>
                </div> */}
            </div>
          </div>
        </div>

        {/* <div className="FBLINEBOX">
          <a href="https://www.facebook.com/tianlong3126952/" target="blank"><div className="iconFB"></div></a>
          <a href="https://line.me/R/ti/p/" target="blank"><div className="iconLINE"></div></a>
          <a data-toggle="collapse" href="#LineQR" aria-expanded="false" aria-controls="LineQR"><div className="iconLINE"></div></a>
        </div> */}
        {/*
        <div className="QRArrowBox collapse" id="LineQR">
          <div className="LineQRArrowBox" >
            <img className="img-fluid" src={line_QR_code} alt="" />
          </div>
        </div>
        */}
      </div>
    )
  }
}

export default FooterComponent;
