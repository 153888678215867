// import React from 'react';
import React, { Component } from 'react';

import pressRelease20200513 from '../../../images/media/pressRelease20200513.jpg';
import pressRelease20200531 from '../../../images/media/pressRelease20200531.jpg';
import pressRelease20200602 from '../../../images/media/pressRelease20200602.jpg';
import pressRelease20211112 from '../../../images/media/pressRelease20211112.jpg';
import btn_close from '../../../images/slider-close-icon.svg'

class Print extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pressRelease: '',
    }
  };
  render() {
    const { dataPressRelease } = this.state;
    return (
      <div>
        <div>
          <div className="row">
            <div className="col-md-6 List">
              <div className="NewsImgBox" onClick={e => {e.stopPropagation(); this.setState({ dataPressRelease: pressRelease20211112 }) }} data-toggle="modal" data-target="#NewsModal"  target="_blank">
                <img src={pressRelease20211112} alt={pressRelease20211112} className="img-fluid Img" />
              </div>
            </div>
            <div className="col-md-6 List">              
              <div className="NewsImgBox" onClick={e => {e.stopPropagation(); this.setState({ dataPressRelease: pressRelease20200513 }) }}  data-toggle="modal" data-target="#NewsModal"  target="_blank">
                <img src={pressRelease20200513} alt={pressRelease20200513} className="img-fluid Img" />                     
              </div>
            </div>
            <div className="col-md-6 List">
              <div className="NewsImgBox" onClick={e => {e.stopPropagation(); this.setState({ dataPressRelease: pressRelease20200531 }) }} data-toggle="modal" data-target="#NewsModal"  target="_blank">
                <img src={pressRelease20200531} alt={pressRelease20200531} className="img-fluid Img" />
              </div>
            </div>
            <div className="col-md-6 List">
              <div className="NewsImgBox" onClick={e => {e.stopPropagation(); this.setState({ dataPressRelease: pressRelease20200602 }) }} data-toggle="modal" data-target="#NewsModal"  target="_blank">
                <img src={pressRelease20200602} alt={pressRelease20200602} className="img-fluid Img" />
              </div>
            </div>
          <div className="modal fade" id="NewsModal" tabIndex="-1" role="dialog" aria-labelledby="NewsModalLabel" aria-hidden="true">
            <div className="modal-dialog NewsPaper-modal-dialog" role="document">
              <div className="modal-content">
              <div className="modal-header">
                {/* <h5 className="modal-title" id="NewsModalLongTitle">{currentNews["title"]}</h5> */}
              </div>
                <img className="close SliderBoxCloseBtn" src={btn_close} data-dismiss="modal" aria-label="Close"/>
                <div className="modal-body">
                  <img src={dataPressRelease} className="img-fluid"/>
                  {/* { currentNews["image"] !== '/newsData/image/None'
                    &&
                    <img src={pressRelease} className="img-fluid"/>
                    ||
                    currentNews["video"] !== '/newsData/video/None'
                    &&
                    <video width="100%" controls>
                      <source src={currentNews["video"]} type="video/mp4"/>
                      Your browser does not support HTML5 video.
                    </video>
                  } */}
                </div>
              </div>
            </div>
          </div>


          </div>
        </div>
      </div>
    )
  }
}
export default Print;