import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import MediaComponent from './component';


const mapStateToProps = (state) => ({
  mediaIdList: selectors.getMediaIdList(state),
  medias: selectors.getMedias(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadMedia: () => {
    dispatch(actions.doLoadMedia());
  },
  onChangeMedia: (currentMedia) => {
    dispatch(actions.doUpdateCurrentMedia({ currentMedia }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaComponent);