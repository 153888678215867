import { createSelector } from 'reselect';

export const getSelectedProductHistoryId = state => state.getIn(['productHistory', 'selectedProductHistoryId']);
export const getSelectedProductHistoryCategoryId = state => state.getIn(['productHistory', 'selectedProductHistoryCategoryId']);

export const getAllProductHistoryIdList = state => state.getIn(['productHistory', 'productHistoryIdList']);
export const getProductHistory = state => state.getIn(['productHistory', 'productHistory']);
export const getProductHistoryIdList = createSelector(
  [getSelectedProductHistoryCategoryId, getAllProductHistoryIdList, getProductHistory],
  (selectedProductHistoryCategoryId, allProductHistoryIdList, productHistory) => 
  	 allProductHistoryIdList.filter(productHistoryId => selectedProductHistoryCategoryId === '0'? true: `${productHistory.getIn([productHistoryId, 'category'])}` === selectedProductHistoryCategoryId)
)
export const getSelectProductHistory = createSelector(
  [getSelectedProductHistoryId, getProductHistory],
  (selectedProductHistoryId,  productHistory) => productHistory.getIn([selectedProductHistoryId])
)



export const getProductHistoryCategoryIdList = state => state.getIn(['productHistory', 'productHistoryCategoryIdList']);
export const getProductHistoryCategories = state => state.getIn(['productHistory', 'productHistoryCategories']);
