import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ProductHistoryComponent from './component';


const mapStateToProps = (state) => ({
  productHistoryIdList: selectors.getProductHistoryIdList(state),
  productHistory: selectors.getProductHistory(state),
  productHistoryCategoryIdList: selectors.getProductHistoryCategoryIdList(state),
  productHistoryCategories: selectors.getProductHistoryCategories(state),
  selectedProductHistoryId: selectors.getSelectedProductHistoryId(state)
});

const mapDispatchToProps = dispatch => ({
  onClickProductHistory: (productHistoryId) => {
    dispatch(actions.doClickProductHistory({ productHistoryId }));
  },
  onClickProductHistoryCategory: (productHistoryCategoryId) => {
    dispatch(actions.doClickProductHistoryCategory({ productHistoryCategoryId }));
  },  
  onLoadProductHistoryPage: () => {
  	dispatch(actions.doLoadProductHistoryPage());
  }  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductHistoryComponent);