import { createSelector } from 'reselect';


export const getIsLoggedIn = state => state.getIn(['shoppingProgress', 'isLoggedIn']);
export const getIsCompleted = state => state.getIn(['shoppingProgress', 'isCompleted']);
export const getCurrentStep = state => state.getIn(['shoppingProgress', 'currentStep']);

export const getBuyProducts = state => state.getIn(['shoppingProgress', 'buyProducts']);
export const getProducts = state => state.getIn(['shoppingProgress', 'products']);


export const getStoreIdNames = state => state.getIn(['shoppingProgress', 'storeIdNames']);
export const getDeliveryMethod = state => state.getIn(['shoppingProgress', 'deliveryMethod']);
export const getDeliveryDate = state => state.getIn(['shoppingProgress', 'deliveryDate']);
export const getDeliveryTime = state => state.getIn(['shoppingProgress', 'deliveryTime']);
export const getStoreId = state => state.getIn(['shoppingProgress', 'storeId']);

export const getPurchaserName = state => state.getIn(['shoppingProgress', 'purchaserName']);
export const getPurchaserGender = state => state.getIn(['shoppingProgress', 'purchaserGender']);
export const getPurchaserEmail = state => state.getIn(['shoppingProgress', 'purchaserEmail']);
export const getPurchaserPhone = state => state.getIn(['shoppingProgress', 'purchaserPhone']);
export const getPurchaserAddress = state => state.getIn(['shoppingProgress', 'purchaserAddress']);

export const getAsPurchaserData = state => state.getIn(['shoppingProgress', 'asPurchaserData']);
export const getDeliveryName = state => state.getIn(['shoppingProgress', 'deliveryName']);
export const getDeliveryGender = state => state.getIn(['shoppingProgress', 'deliveryGender']);
export const getDeliveryPhone = state => state.getIn(['shoppingProgress', 'deliveryPhone']);
export const getDeliveryAddress = state => state.getIn(['shoppingProgress', 'deliveryAddress']);

export const getPaymentMethod = state => state.getIn(['shoppingProgress', 'paymentMethod']);

export const getCouponCode = state => state.getIn(['shoppingProgress', 'couponCode']);
export const getCouponId = state => state.getIn(['shoppingProgress', 'couponId']);
export const getCouponContent = state => state.getIn(['shoppingProgress', 'couponContent']);
export const getGifts = state => state.getIn(['shoppingProgress', 'gifts']);
export const getGiftId = state => state.getIn(['shoppingProgress', 'giftId']);

export const getInvoiceType = state => state.getIn(['shoppingProgress', 'invoiceType']);
export const getInvoiceUbn = state => state.getIn(['shoppingProgress', 'invoiceUbn']);
export const getInvoiceTitle = state => state.getIn(['shoppingProgress', 'invoiceTitle']);

export const getIsAgreeTerms = state => state.getIn(['shoppingProgress', 'isAgreeTerms']);

export const getTotalAmount = createSelector(
  [getBuyProducts, getProducts],
  (buyProducts, products) => {
  	let totalAmount = 0;
  	buyProducts.keySeq().forEach(productId => {
  		totalAmount += +buyProducts.getIn([productId]) * +products.getIn([productId, 'price'])
  	})
  	return totalAmount;
  }
)
export const getShippingCost = state => state.getIn(['shoppingProgress', 'shippingCost']);
export const getErrorMessage = state => state.getIn(['shoppingProgress', 'errorMessage']);
export const getDiscount = state => state.getIn(['shoppingProgress', 'discount']);
export const getDeduction = state => state.getIn(['shoppingProgress', 'deduction']);
export const getBalanceDue = state => state.getIn(['shoppingProgress', 'balanceDue']);


export const getOrderId = state => state.getIn(['shoppingProgress', 'orderId']);

