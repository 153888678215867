import React, { Component } from 'react';
// images
import btn_prev from '../../images/btn_prev.svg'
import DecorativeLine from '../../images/DecorativeLine.svg'
import { Link } from 'react-router-dom';
import TitleFeatured from '../../images/title_featured.svg'

class FeaturedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount() {
    this.props.onLoadFeatuerdMenu(this.props.type);
  }
  render() {
    const {
      someFeaturedList,
      onClickToLeft, onClickToRight
    } = this.props;
    return (
      <div className="Featured">

        <div className="FeaturedBtnBox">
          <div onClick={(event) => onClickToLeft()}>
            <img className="FeaturedBtnLeft" src={btn_prev} alt="" />
          </div>
          <div onClick={(event) => onClickToRight()}>
            <img className="FeaturedBtnRight TransformY" src={btn_prev} alt="" />
          </div>
        </div>

        <div className="container">

          <div className="row">
            <div className="col"><img className="mainPage_Title rounded mx-auto d-block" src={TitleFeatured} alt="" /></div>
          </div>
          
          <div className="row">
            {
              someFeaturedList.map((featured, index) => (
                
                <div className="col-12 col-sm-4 col-md-4" key={index}>
                  <div className="FeaturedImgBox col-sm-12">
                    {
                      this.props.type === 'product'
                      &&
                      <Link to={`/product/${featured.getIn(["packageId"])}`}>
                        <img className="FeaturedImg rounded mx-auto d-block" src={featured.getIn(["image"])} alt="" />
                      </Link>
                    }
                    {
                      this.props.type === 'kungfuNoodle'
                      &&
                      <img className="FeaturedImg rounded mx-auto d-block" src={featured.getIn(["image"])} alt="" />
                    }
                  </div>
                  <div className="col-sm-12">
                    <p className="FeaturedText">{featured.getIn(["featuerdName"])}</p>
                  </div>
                </div>                
              ))
            }
          </div>
          
        </div>
      </div>
    )
  }
}

export default FeaturedComponent;