import React from 'react';


export const Video = (props) => {
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-6 col-sm-12 List">
            <div className="NewsVideoBox">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/YGemdab5cPE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>                
            <p className="text-center">台灣首座滾筒式護欄！北宜公路19.4K試辦</p>
            <p className="text-center date"></p>
          </div>
          <div className="col-md-6 col-sm-12 List">
            <div className="NewsVideoBox">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/ShxtCs2tt68" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>                
            <p className="text-center">大型橋樑檢修車</p>
            <p className="text-center date"></p>
          </div>
          <div className="col-md-6 col-sm-12 List">
            <div className="NewsVideoBox">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/-BsnTSpIBjM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>                
            <p className="text-center">保命利器！國道警租用9輛緩撞車－八大民生新聞</p>
            <p className="text-center date"></p>
          </div>
          <div className="col-md-6 col-sm-12 List">
            <div className="NewsVideoBox">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/fVy2ECOfaoM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>                
            <p className="text-center">定速110「坦克」撞工程車，緩撞器救命人輕傷</p>
            <p className="text-center date"></p>
          </div>
          <div className="col-md-6 col-sm-12 List">
            <div className="NewsVideoBox">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/9PBihRhTPQs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>                
            <p className="text-center">國道警察執勤利器，緩撞車成功挽救11人－民視新聞</p>
            <p className="text-center date"></p>
          </div>
          <div className="col-md-6 col-sm-12 List">
            <div className="NewsVideoBox">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/o6xYemOuamg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>                
            <p className="text-center">國道追撞肇事頻頻，調派「緩撞車」減少事故發生</p>
            <p className="text-center date"></p>
          </div>
          <div className="col-md-6 col-sm-12 List">
            <div className="NewsVideoBox">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/d37yH260uFE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>                
            <p className="text-center">國道公路警察局 交通宣導 緩撞車再次阻擋死神</p>
            <p className="text-center date"></p>
          </div>
        </div>
      </div>
    </div>
  )
}