import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import bootstrap from 'bootstrap';
import { DatePicker, Button } from 'antd';
import shoppingCart from '../shoppingCart'
// import images
import logo_headerbar from '../../images/longtime_long.png';
import icon_order from '../../images/icon_order.svg';
import icon_user from '../../images/icon_user.svg';
import icon_shoppingcart from '../../images/icon_shopping-cart.svg';
import icon_menu from '../../images/icon_menu.svg';


const selectedItemClass = {
  '/stores': 'selectStores',
  '/media': 'selectMedia',
  '/products': 'selectProducts',
  '/portfolio': 'selectPortfolio',
  '/story': 'selectStory',
  '/kungfu-noodle': 'selectKungfu-noodle',
  '/product-history': 'selectProduct-history'
}

class HeaderBarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerItems: [
        {
          'name': '公司簡介',
          'url': '/stores'
        },
        {
          'name': '產品介紹',
          'url': '/products'
        },
        {
          'name': '實績照片',
          'url': '/portfolio'
        },
        {
          'name': '媒體報導',
          'url': '/media'
        },
        {
          'name': '最新公告',
          'url': '/announcement'
        },
      ],
      currentItemUrl: null,
      showShoppingCart: false,
      isInShoppingCart: false,
      isInUserDropdown: false
    };
    this.onMouseLeaveShoppingCart = this.onMouseLeaveShoppingCart.bind(this);
    // this.onMouseEnterShoppingCart = this.onMouseEnterShoppingCart.bind(this);
    this.onMouseLeaveUser = this.onMouseLeaveUser.bind(this);
    this.onMouseEnterUser = this.onMouseEnterUser.bind(this);
    this.onHideAllDropdown = this.onHideAllDropdown.bind(this);
    this.handleShoppingCart = this.handleShoppingCart.bind(this);
  }
  onMouseLeaveShoppingCart() {
    this.setState({ showShoppingCart: false });
    this.setState({ isInShoppingCart: false });
  }
  // onMouseEnterShoppingCart() {
  //   if (this.props.location.pathname !== '/shopping/progress') {
  //     this.setState({ showShoppingCart: true })
  //   } 
  //   this.setState({ isInShoppingCart: true })
  // }
  onMouseLeaveUser() {
    this.setState({ isInUserDropdown: false });
  }
  onMouseEnterUser() {
    this.setState({ isInShoppingCart: false });
      this.setState({ showShoppingCart: false });
    this.setState({ isInUserDropdown: true });
  }  
  onHideAllDropdown() {
    if (!this.state.isInShoppingCart && !this.state.isInUserDropdown) {
      this.props.onLeaveUser();
      this.setState({ isInShoppingCart: false });
      this.setState({ showShoppingCart: false });
    }
  }
  componentDidMount () {
    document.addEventListener('click', this.onHideAllDropdown, true);
  }
  componentWillUnmount () {
    document.removeEventListener('click', this.onHideAllDropdown, true);
  }
  handleShoppingCart() {
    if (this.props.location.pathname != "/shopping/progress") {
      if (!this.state.showShoppingCart) {
        this.setState({ isInShoppingCart: true });
        this.setState({ showShoppingCart: true });
        document.addEventListener('click', this.onHideAllDropdown, true);
      } else {
        this.setState({ isInShoppingCart: false });
        document.removeEventListener('click', this.onHideAllDropdown, true);
      }
      
      if (this.state.showShoppingCart) {
        this.setState({ isInShoppingCart: false });
        this.setState({ showShoppingCart: false });
      }
    }    
  }
  render() {
    const {
      showUserDropDown, onLeaveUser, shoppingCount,
      onClickUser, onClickLogout
    } = this.props;

    return (
      <div>
        
        <nav className="MenuBarNav navbar navbar-light navbar-expand-lg justify-content-end fixed-top">
          <Link className="navbar-brand mr-auto" to={`/index`}>
            <div className="logoBlock">
              <img src={logo_headerbar} alt="" />
              <div>
                <div>隆太國際有限公司</div>
              </div>
            </div>
            
          </Link>
          <button className="navbar-toggler MenuBtn" type="button" data-toggle="collapse" data-target="#collapsingNavbar2">
            <img src={icon_menu} alt="" />
          </button>
          {/* <ul className="d-flex align-items-center justify-content-end order-lg-4">
            <li className="nav-item">
              <Link to={`/orders/search`}>
                <i>
                  <img className="nav-link NavIcon" src={icon_order} alt=""  />
                </i>
              </Link>            
            </li>

            <li className="nav-item" onMouseEnter={this.handleShoppingCart}>
              <Link className="nav-link NavIcon" to={`/shopping/progress`}>
                <i className="ShoppingCartIcon"><img src={icon_shoppingcart} alt="" />
                  {
                    ( shoppingCount !== 0 )
                    &&
                    <span className="RedBadge">{shoppingCount}</span>
                  }
                </i>
              </Link>
            </li>

            <li className="nav-item">
              <i onClick={(event) => onClickUser(this.props.history)}>
                <img className="nav-link NavIcon" src={icon_user} alt=""  />
                {
                  showUserDropDown
                  &&
                  <div className="AccountBox" onMouseLeave={() => {onLeaveUser();this.onMouseLeaveUser()}} onMouseEnter={() => this.onMouseEnterUser()}>
                    <div className="arrowuser arrowuser-border"></div>
                    <div className="arrowuser arrowuser-bg"></div>
                    <Link to={`/orders`}>
                      <p className="AccountText">我的帳戶</p>
                    </Link>
                    <p className="AccountText" onClick={(event) => onClickLogout()}>登出</p>
                  </div>
                }
              </i>
            </li>
          </ul>
           */}
          <div className="navbar-collapse collapse order-lg-2" id="collapsingNavbar2">

            <ul className="NavBarText navbar-nav">
              {
                this.state.headerItems.map(headerItem => (
                  <li className="nav-item" key={headerItem.url}>
                    <Link className="nav-link" to={headerItem.url}>
                      <p className="NavText">{headerItem.name}</p>
                      {
                        this.props.location.pathname === headerItem.url
                        &&
                        <div className={`${selectedItemClass[headerItem.url]}`}></div>
                      }                      
                    </Link>
                  </li>   
                ))
              }
            </ul>            
          </div>
          
          {
            this.state.showShoppingCart
            &&
            <div onMouseLeave={this.onMouseLeaveShoppingCart}>
              <shoppingCart.container />
            </div>
          }
        </nav>

      </div>
    )
  }
}

export default withRouter(HeaderBarComponent);