import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

// images
import iconChecked from '../../images/icon_checked.png'
import iconCancelar from '../../images/icon_cancelar.png'


class ShoppingCompletedComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.onLoadShoppingCompleted(this.props.history);    
  }
  componentWillUnmount() {
    this.props.onClearOrder();
  }         
  render() {
    const { 
      isLoggedIn, showOrderId, purchaserEmail, isCompleted
    } = this.props;
    return (
	    <div>
        <div className="container ShoppingStep4AllBox">
          <div className="row ShoppingStep4TitleBox">
            {
              isLoggedIn === false
              &&
              <div className="col-12 ShoppingStep4Title">您尚未加入會員，是否要 <Link to={`/registration`}>註冊</Link>？(成為會員即可查詢訂單進度)</div>
            }
          </div>

          {
            isCompleted
            ?
            <div className="row ShoppingStep4TextBox">
              <div className="col-lg-4"><img className="rounded mx-auto d-block" src={iconChecked} alt="" /></div>
              <div className="col-lg-8">
                <div className="col-lg-12 ShoppingStep4TextTitle">謝謝您！您的訂單已經成立！</div>
                <div className="col-lg-12 ShoppingStep4Text">訂單編號：{showOrderId}</div>
                <div className="col-lg-12 ShoppingStep4Text">訂單確認郵件已經發送到您的電子信箱</div>
                <div className="col-lg-12 ShoppingStep4Text">{purchaserEmail}</div>
              </div>
            </div>
            :
            <div className="row ShoppingStep4TextBox">
              <div className="col-lg-4"><img className="rounded mx-auto d-block" src={iconCancelar} alt="" /></div>
              <div className="col-lg-8">
                <div className="col-lg-12 ShoppingStep4TextTitle">抱歉您的訂單付款失敗！</div>
                <div className="col-lg-12 ShoppingStep4Text">訂單編號：{showOrderId}</div>
                <div className="col-lg-12 ShoppingStep4Text">訂單確認郵件已經發送到您的電子信箱，請使用信中連結重新付款</div>
                <div className="col-lg-12 ShoppingStep4Text">{purchaserEmail}</div>
              </div>
            </div>            
          }
        </div>

        <div className="container ShoppingProgressBtnBox">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-3">
              <Link to={`/products`}>
                <button className="ShoppingProgressReturnBtn_small" type="button" >繼續購物</button>
              </Link>
            </div>
            {
              isLoggedIn
              &&
            <div className="col-md-3">
              <Link to={`/orders`}>
                <button className="ShoppingProgressBtnSendBtn_small" type="button">查詢訂單</button>
              </Link>
            </div>
            }
            <div className="col-md-3"></div>
          </div>
        </div>
	    </div>
	  )
  }
}

export default withRouter(ShoppingCompletedComponent);