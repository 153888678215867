import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'featured/UPDATE_FEATURED_LIST': (state, { payload }) => {
    return state.setIn(['featuredList'], fromJS(payload.featuredList));
  },
  'featured/UPDATE_FIRST_INDEX': (state, { payload }) => {
    return state.setIn(['firstIndex'], payload.firstIndex);
  }  
}, State);

export default Reducer;