import React from 'react';
import A_01 from '../../../images/portfolio/portfolio_A001.jpg';
import A_02 from '../../../images/portfolio/portfolio_A002.jpg';



export const ProductsA = (props) => {
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={A_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={A_02}/>
          </div>
        </div>
      </div>
    </div>
  )
}