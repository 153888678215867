import { createSelector } from 'reselect';

export const getStoreIdList = state => state.getIn(['stores', 'storeIdList']);
export const getStores = state => state.getIn(['stores', 'stores']);
export const getSelectedStoreId = state => state.getIn(['stores', 'selectedStoreId']);

export const getSelectedStore = createSelector(
  [getStores, getSelectedStoreId],
  (stores, selectedStoreId) => stores.getIn([selectedStoreId])
)

export const getSelectedStoreCoordinate = createSelector(
  [getStores, getSelectedStoreId],
  (stores, selectedStoreId) => stores.getIn([selectedStoreId, 'coordinate'])
)

