import React from 'react';


export const Proclaim = (props) => {
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-6 col-sm-12 List">
            <div className="NewsVideoBox">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/Ie7Pm4ottaM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p className="text-center">道路養護工程施工交通、安衛作為短片</p>
            <p className="text-center date"></p>
          </div>
          <div className="col-md-6 col-sm-12 List">
            <div className="NewsVideoBox">
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/cxnpVmoiY50" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p className="text-center">一日高速公路局員工《一日系列第116集》</p>
            <p className="text-center date"></p>
          </div>
        </div>
      </div>
    </div>
  )
}