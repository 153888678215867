import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import CouponsComponent from './component';


const mapStateToProps = (state) => ({
  couponIdList: selectors.getCouponIdList(state),
  coupons: selectors.getCoupons(state),
  searchText: selectors.getSearchText(state),
  statusFilter: selectors.getStatusFilter(state),
  availableCouponCount: selectors.getAvailableCouponCount(state),
});

const mapDispatchToProps = dispatch => ({
  onIsLoggedIn: (history) => {
    dispatch(actions.doIsLoggedIn(history));
  },    
  onChangeStatusFilter: (statusFilter) => {
    dispatch(actions.doChangeStatusFilter({ statusFilter }));
  },
  onChangeSearchText: (searchText) => {
    dispatch(actions.doChangeSearchText({ searchText }));
  },  
  onLoadCoupons: () => {
  	dispatch(actions.doLoadCoupons());
  }  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponsComponent);

 
