import { createAction } from 'redux-actions';
import { fromJS } from 'immutable';
import { message } from 'antd';
import Modal from 'antd/lib/modal';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

export function doIsLoggedIn(history) {
  return dispatch => {
    const stormedToken = cookie.getCookie('stormedToken');
    if (stormedToken === '') {
      history.push(`/login`);
    } else {
      api.cCheckToekn().then(result => {
      }).catch((errMsg) => {
        if (errMsg === 'E401') {
          message.info('Invalid Token');
          cookie.setCookie('stormedToken', '');
          history.push('/');
        }    
      })        
    }  
  }
}

export const doUpdateTaiwanCity = createAction(types.UPDATE_TAIWAN_CITY);
export function doLoadTaiwanCity() {
  return dispatch => {
    api.rCities().then(result => {
      dispatch(doUpdateTaiwanCity({ TaiwanCity: fromJS(result) }));
    }).catch((errMsg) => {
      console.log(errMsg)
    })       
  }
}

export const doUpdateName = createAction(types.UPDATE_NAME);
export const doUpdateEmail = createAction(types.UPDATE_EMAIL);
export const doUpdateGender = createAction(types.UPDATE_GENDER);
export const doUpdatePhone = createAction(types.UPDATE_PHONE);
export const doUpdateCity = createAction(types.UPDATE_CITY);
export const doUpdateAddress = createAction(types.UPDATE_ADDRESS);
export function doLoadProfile() {
  return dispatch => {
    api.rProfile().then(result => {
			dispatch(doUpdateName({ name: result.memberName }));
			dispatch(doUpdateEmail({ email: result.memberEmail }));
			dispatch(doUpdateGender({ gender: result.gender }));
			dispatch(doUpdatePhone({ phone: result.phone }));
      dispatch(doUpdateCity({ city: result.city }));
			dispatch(doUpdateAddress({ address: result.address }));
    }).catch((errMsg) => {
      console.log(errMsg)
    })
  }
}

export function doSubmitProfile() {
  return (dispatch, getState) => {
  	const state = getState();
  	const name = state.getIn(['profile', 'name']);
  	const gender = state.getIn(['profile', 'gender']);
  	const phone = state.getIn(['profile', 'phone']);
    const city = state.getIn(['registration', 'city']);
  	const address = state.getIn(['profile', 'address']);
    return api.uProfile(name, gender, phone, city, address).then(result => {
      if (result && result.hasOwnProperty('errorCode')) {
        console.log(result);
        return;      
      }
      Modal.success({
        title: `會員資料更新成功`,
        okText: `確認`,
        onOk: () => {
        },
      }); 
    })
  }
}
