import Immutable from 'immutable';


export const State = Immutable.fromJS({
	isLoggedIn: false,
	isCompleted: false,
	currentStep: 0,
	
	// step 1
	buyProducts: {},
	products: {},
	canShippingSelf: true,
	canShippingDelivery: true,
	shippingSelfDateList: [],
	shippingDeliveryDateList: [],
	// step 2
	storeIdNames: {},
	deliveryMethod: '1',
	deliveryDate: '',
	deliveryTime: '0',
	storeId: '1',

	purchaserName: '',
	purchaserGender: 'male',
	purchaserEmail: '',
	purchaserPhone: '',
	purchaserAddress: '',
	purchaserCity: '台北市',
	// purchaserName: 'Janie',
	// purchaserGender: 'female',
	// purchaserEmail: 'jchen@stormed.com',
	// purchaserPhone: '0912345678',
	// purchaserAddress: '台中市太平區',

	asPurchaserData: false,
	deliveryName: '', 
	deliveryGender: 'male', 
	deliveryPhone: '', 
	deliveryAddress: '',
	deliveryCity: '台北市',
	// deliveryName: 'RICH', 
	// deliveryGender: 'male', 
	// deliveryPhone: '0987654321', 
	// deliveryAddress: '台北市北投區',

	paymentMethod: '1',

	couponCode: '', 
	couponId: 0,
	couponContent: '', 
	minAmt: 0,
	discount: 0,
	deduction: 100,
	freeShipping: false,
	gift: {},
	giftId: 0,

	invoiceType: '1',
	invoiceUbn: '',
	invoiceTitle: '',

	greetings: '', 
	note: '',

	errorMessage: '',
	isAgreeTerms: false,
	shippingCost: 0,
	balanceDue: 0,
	orderId: '',

	TaiwanCity: {},
});