import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import headerBar from '../modules/headerBar';
import { Helmet } from 'react-helmet';
import footer from '../modules/footer';
import forgotPassword from '../modules/forgotPassword';
// import * as api from '../utils/api';


class ForgotPasswordPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: ''
    };
  }
  componentDidMount() {
    // api.rImage('forgotPw').then(json => this.setState({ imgSrc: json.url }));
  }  
  render() {
  	return (
	    <div id="page-forgotPassword">
      <Helmet>
        <title>鄧師傅功夫菜</title>
        <meta name="description" content="飄香高雄35載，滷豬腳年銷售超越50座臺北101大樓高度! 馳名海內外，獲得媒體一致推薦。Chef Teng founded in 1984, Taiwan, is famous for signature stewed pork knuckles and has won awards and recognition from news media around the world." />
      </Helmet>
        <headerBar.container/>



        <div className="container-fluid">

          <div className="row">

            <div className="LoginBox col-lg-7">
              <div className="LoginBoxGoldBanner">
                <img className="LoginBoxGoldBannerImg imgBox " alt="" />
              </div>
            </div>

            <div className="LoginBox col-lg-5">
              <div className="LoginBoxGold col-12">

                <forgotPassword.container />

                <Link to={`/login`}>
                  <button className="ReturnBtn" type="button">返回登入</button>
                </Link>

              </div>
            </div>

          </div>

        </div>

        <footer.container/>
	    </div>
	  )
  }
}

export default ForgotPasswordPageComponent;
