import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import ShoppingCompletedComponent from './component';


const mapStateToProps = (state) => ({
  isLoggedIn: selectors.getIsLoggedIn(state),
  isCompleted: selectors.getIsCompleted(state),
  purchaserEmail: selectors.getPurchaserEmail(state),
  showOrderId: selectors.getShowOrderId(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadShoppingCompleted: (history) => {
    dispatch(actions.doLoadShoppingCompleted(history));
  },   
  onClearOrder: (history) => {
    dispatch(actions.doClearOrder(history));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingCompletedComponent);