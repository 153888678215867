import React, { Component } from 'react';
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MyMapComponent = compose(
  withProps({
    googleMapURL:
       "https://maps.googleapis.com/maps/api/js?key=AIzaSyDxPDj2Qq3fuxzWX8SF9p3_Qmiia4nmNDc&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `250px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={17} defaultCenter={{ lat: props.latitude , lng: props.longitude }}>
    <Marker position={{ lat: props.latitude , lng: props.longitude }} />
  </GoogleMap>
));

class GoogleMapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 0, // for re-rendering
      longitude: 0,
      latitude: 0
    }
  }
  componentDidMount() {
    this.setState({ longitude: this.props.longitude, latitude: this.props.latitude })
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ longitude: nextProps.longitude, latitude: nextProps.latitude, key: this.state.key + 1 })
  }  
  render() {
    return (
      <div>
        <MyMapComponent 
          key={this.state.key}
          longitude={this.state.longitude}
          latitude={this.state.latitude}                  
        />
      </div>
    )
  }
}

export default GoogleMapComponent;    

