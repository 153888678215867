import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';


class LoginLineComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      onClickLoginLine
    } = this.props;

    return (
      <p className="LoginBtnLine d-none" onClick={() => onClickLoginLine(this.props.history)}>
        Line帳號登入
      </p>
    )
  }
}

export default withRouter(LoginLineComponent);
