// import { createStore, applyMiddleware } from 'redux';
// import reduxThunk from 'redux-thunk';
// import Immutable from 'immutable';
// import rootReducer from '../rootReducer';


// const initialState = Immutable.fromJS();
// export default createStore(
//   rootReducer,
//   initialState,
//   applyMiddleware(reduxThunk)
// );


import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, autoRehydrate } from 'redux-persist-immutable'
import reduxThunk from 'redux-thunk'
import Immutable from 'immutable';
import rootReducer from '../rootReducer'

const initialState = Immutable.fromJS();
const store = createStore(
  rootReducer,
  initialState,
  compose(
	  autoRehydrate(),
	  applyMiddleware(reduxThunk)
	)
);
persistStore(store)
export default store
