import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import OrdersComponent from './component';


const mapStateToProps = (state) => ({
  orderPhone: selectors.getOrderPhone(state),
  orderId: selectors.getOrderId(state),
  order: selectors.getOrder(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadOrdersSearchPage: () => {
    dispatch(actions.doLoadOrdersSearchPage());
  },  
  onChangeOrderPhone: (orderPhone) => {
    dispatch(actions.doChangeOrderPhone({ orderPhone }));
  },
  onChangeOrderId: (orderId) => {
    dispatch(actions.doChangeOrderId({ orderId }));
  },
  onClickSearch: () => {
    dispatch(actions.doClickSearch());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersComponent);