import { createSelector } from 'reselect';

export const getIsLoggedIn = state => state.getIn(['shoppingCompleted', 'isLoggedIn']);
export const getIsCompleted = state => state.getIn(['shoppingCompleted', 'isCompleted']);
export const getOrderId = state => state.getIn(['shoppingCompleted', 'orderId']);
export const getPurchaserEmail = state => state.getIn(['shoppingProgress', 'purchaserEmail']);

export const getShowOrderId = createSelector(
  [getOrderId],
  (orderId) => {
  	orderId = orderId.substring(2);
  	return orderId;
  }
)