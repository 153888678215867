import { createAction } from 'redux-actions';
import { message } from 'antd';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';
import * as config from '../../config'


const windowHeight = window.innerHeight || document.body.clientHeight;
message.config({
  top: windowHeight / 2,
});

export function doIsLoggedIn(history) {
  return dispatch => {
    const stormedToken = cookie.getCookie('stormedToken');
    if (stormedToken === '') {
      history.push(`/login`);
    } else {
      api.cCheckToekn().then(result => {
        dispatch(doEditLogin({value: '', type: "account"}));
        dispatch(doEditLogin({value: '', type: "password"}));
        history.push(`/orders`);
      }).catch((errMsg) => {
        if (errMsg === 'E401') {
          message.info('Invalid Token');
          cookie.setCookie('stormedToken', '');
          history.push('/');
        }
      })
    }  
  }
}

export const doUpdateErrorMessage = createAction(types.UPDATE_ERROR_MESSAGE)
export const doEditLogin = createAction(types.EDIT_LOGIN)
export const doUpdateShowLoading = createAction(types.UPDATE_SHOW_LOADING)
export function doSubmitLogin(history) {
  return (dispatch, getState) => {
    dispatch(doUpdateShowLoading({ showLoading: true }));
  	const state = getState();
  	const account = state.getIn(['login', 'account']);
  	const password = state.getIn(['login', 'password']);
    dispatch(doUpdateErrorMessage({ message: ''}))
    api.cLogin(account, password).then(result => {
      dispatch(doEditLogin({value: '', type: "account"}));
      dispatch(doEditLogin({value: '', type: "password"}));
      cookie.setCookie('loginMethod', 'ACCOUNT');
      cookie.setCookie('stormedToken', result.stormedToken);
      dispatch(doUpdateShowLoading({ showLoading: false }));
      history.push(`/orders`);
    }).catch((errMsg) => {
      if (errMsg === 'E401') {
        dispatch(doUpdateErrorMessage({ message: '帳號或密碼錯誤！'}));
      } else if (errMsg === 'E005') {
        dispatch(doUpdateErrorMessage({ message: '請使用 FB 登入'}));
      } else if (errMsg === 'E006') {
        dispatch(doUpdateErrorMessage({ message: '請使用 Line 登入'}));
      }
      cookie.setCookie('stormedToken', '');
      dispatch(doEditLogin({value: '', type: "password"}));
      dispatch(doUpdateShowLoading({ showLoading: false }));
    })    
  }
}
