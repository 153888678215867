import React from 'react';
import D_01 from '../../../images/portfolio/portfolio_D001.jpg';
import D_02 from '../../../images/portfolio/portfolio_D002.jpg';

export const ProductsD = (props) => {
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={D_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={D_02}/>
          </div>
        </div>
      </div>
    </div>
  )
}