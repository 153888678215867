import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { URL } from 'url';
import btn_prev from '../../images/btn_prev.svg'
import btn_close from '../../images/slider-close-icon.svg'


class NewsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.onLoadActivities();
    this.props.onLoadNews();
  }   
  render() {
    const { 
      activities, news, category, medias, currentMedia, currentNews,
      onClickCategory, onChangeMedia, onChangeNews
    } = this.props;
    
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 SideBox d-none d-lg-block">
            {/* {
            <p className={`SideBoxList ${category === "好康報報"?"active":""}`} onClick={(event) => onClickCategory("好康報報")}>
              ▶ 好康報報
            </p>     
            } */}
            <p className={`SideBoxList ${category === "媒體報導"?"active":""}`} onClick={(event) => onClickCategory("媒體報導")}>
              ▶ 媒體報導
            </p>
            {
              category === "媒體報導"
              &&
                  medias.map(media => (
                    <p className="MediaSubP" key={media} value={media} onClick={(event) => onChangeMedia(media)}>▶ {media}</p>
                  ))
            }
          </div>
         {/*手機版會變select*/}
        <div className="row d-lg-none categorySelect">
          {/*
          <div className="col-sm-12">
            <select id="categorySelect" onChange={(event) => onClickCategory(event.target.value)}>
              <option value="好康報報">好康報報</option>
              <option value="媒體報導">媒體報導</option>
            </select>
          </div>
          */}
          {
            category === "媒體報導"
            &&
            <div className="col-sm-12">
              <select id="categorySelect"  onChange={(event) => onChangeMedia(event.target.value)}>
              {
                medias.map(media => (
                  <option className="MediaSubP" key={media} value={media}>▶ {media}</option>
                ))
              }
              </select>
            </div>
          }
        </div>

          <div className="col-md-8 col-sm-12 ListContent">
            {
            <div className="row">
              {
                (category === "好康報報" && activities.keySeq().size > 0)
                &&
                activities.map(activitie => ( 
                <div className="col-md-6 col-sm-12 col-lg-4 List" key={activitie.getIn(["newsId"])}>            
                  <Link data-toggle="modal" data-target="#NewsModal" to={`/activitie/${activitie.getIn(["newsId"])}`}>
                      
                      {
                        activitie.getIn(["image"]) !== '/newsData/image/None'
                        &&
                        <div className="BulletinBoardImgBox" onClick={(event) => onChangeNews(activitie.toJS())}>
                          <img src={activitie.getIn(["image"])} className="img-fluid" alt={activitie.getIn(["title"])}/>
                        </div>
                      }                    
                      {
                        activitie.getIn(["video"]) !== '/newsData/video/None'
                        &&
                        <div className="NewsVideoBox" onClick={(event) => onChangeNews(activitie.toJS())}>
                          <video width="100%" controls>
                            <source src={activitie.getIn(["video"])} type="video/mp4"/>
                            Your browser does not support HTML5 video.
                          </video>
                        </div>
                      }                      
                      <p className="text-center">{activitie.getIn(["title"])}</p>
                      <p className="text-center date">{activitie.getIn(["date"])}</p>
                  </Link>
                </div>
                ))     
              }
            </div>
            }
            <div className="row">
              {
                (category === "媒體報導" && news.keySeq().size > 0)
                &&
                news.getIn([currentMedia]).map(n => ( 
                  <div className="col-md-6 List" key={n.getIn(["newsId"])}>              
                    {/* <Link to={'news/' +　n.getIn(["newsId"])}>   */}
                        {
                          n.getIn(["image"]) !== '/newsData/image/None'
                          &&
                          <div className="NewsImgBox" data-toggle="modal" data-target="#NewsModal"  target="_blank" onClick={(event) => onChangeNews(n.toJS())}>
                            <img src={n.getIn(["image"])} className="img-fluid Img" alt={n.getIn(["title"])}/>                          
                          </div>
                        }
                        {
                          n.getIn(["video"]) !== '/newsData/video/None'
                          &&
                          <div className="NewsVideoBox" onClick={(event) => onChangeNews(n.toJS())}>
                            <video width="100%" controls>
                              <source src={n.getIn(["video"])} type="video/mp4"/>
                              Your browser does not support HTML5 video.
                            </video>
                          </div>
                        }                         
                        <p className="text-center">{n.getIn(["title"])}</p>
                        <p className="text-center date">{n.getIn(["date"])}</p>
                    {/* </Link> */}
                  </div>
                ))                
              }
            </div>                  
          </div>
        </div>
          <div className="modal fade" id="NewsModal" tabIndex="-1" role="dialog" aria-labelledby="NewsModalLabel" aria-hidden="true">
            <div className="modal-dialog NewsPaper-modal-dialog" role="document">
              <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="NewsModalLongTitle">{currentNews["title"]}</h5>
              </div>
                <img className="close SliderBoxCloseBtn" src={btn_close} data-dismiss="modal" aria-label="Close"/>
                <div className="modal-body">
                  { currentNews["image"] !== '/newsData/image/None'
                    &&
                    <img src={currentNews["image"]} className="img-fluid"/>
                    /*||
                    currentNews["video"] !== '/newsData/video/None'
                    &&
                    <video width="100%" controls>
                      <source src={currentNews["video"]} type="video/mp4"/>
                      Your browser does not support HTML5 video.
                    </video>*/
                  }
                </div>
              </div>
            </div>     
          </div>          
      </div>
    )
  }
}

export default NewsComponent;