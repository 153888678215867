import { createSelector } from 'reselect';


export const getIsLoggedIn = state => state.getIn(['shoppingProgress', 'isLoggedIn']);
export const getIsCompleted = state => state.getIn(['shoppingProgress', 'isCompleted']);
export const getCurrentStep = state => state.getIn(['shoppingProgress', 'currentStep']);

export const getBuyProducts = state => state.getIn(['shoppingProgress', 'buyProducts']);
export const getProducts = state => state.getIn(['products', 'products']);
export const getCanShippingSelf = state => state.getIn(['shoppingProgress', 'canShippingSelf']);
export const getCanShippingDelivery = state => state.getIn(['shoppingProgress', 'canShippingDelivery']);
export const getShippingSelfDateList = state => state.getIn(['shoppingProgress', 'shippingSelfDateList']);
export const getShippingDeliveryDateList = state => state.getIn(['shoppingProgress', 'shippingDeliveryDateList']);

export const getDeliveryMethod = state => state.getIn(['shoppingProgress', 'deliveryMethod']);
export const getDeliveryDate = state => state.getIn(['shoppingProgress', 'deliveryDate']);
export const getDeliveryTime = state => state.getIn(['shoppingProgress', 'deliveryTime']);
export const getStoreId = state => state.getIn(['shoppingProgress', 'storeId']);

export const getAllStoreIdNames = state => state.getIn(['shoppingProgress', 'storeIdNames']);
export const getStoreIdNames = createSelector(
  [getAllStoreIdNames, getDeliveryDate],
  (allStoreIdNames, deliveryDate) => {
  	if (deliveryDate === '2018/02/16') {
      return allStoreIdNames.filter(store => [3,6,8].indexOf(store.getIn(['storeId'])) > -1); // 夢時代店 機場店 巨蛋店
  	} else {
  		return allStoreIdNames;
  	}
  }
)

export const getStoreName = createSelector(
  [getAllStoreIdNames, getStoreId],
  (allStoreIdNames, storeId) => {
    let storeName = '';
    allStoreIdNames.forEach(store => {
      if (`${store.getIn(['storeId'])}` === `${storeId}`) {
        storeName = store.getIn(['storeName']);
        return;
      }
    });
    return storeName;
  }
)

export const getPurchaserName = state => state.getIn(['shoppingProgress', 'purchaserName']);
export const getPurchaserGender = state => state.getIn(['shoppingProgress', 'purchaserGender']);
export const getPurchaserEmail = state => state.getIn(['shoppingProgress', 'purchaserEmail']);
export const getPurchaserPhone = state => state.getIn(['shoppingProgress', 'purchaserPhone']);
export const getPurchaserAddress = state => state.getIn(['shoppingProgress', 'purchaserAddress']);
export const getPurchaserCity = state => state.getIn(['shoppingProgress', 'purchaserCity']);

export const getAsPurchaserData = state => state.getIn(['shoppingProgress', 'asPurchaserData']);
export const getDeliveryName = state => state.getIn(['shoppingProgress', 'deliveryName']);
export const getDeliveryGender = state => state.getIn(['shoppingProgress', 'deliveryGender']);
export const getDeliveryPhone = state => state.getIn(['shoppingProgress', 'deliveryPhone']);
export const getDeliveryAddress = state => state.getIn(['shoppingProgress', 'deliveryAddress']);
export const getDeliveryCity = state => state.getIn(['shoppingProgress', 'deliveryCity']);

export const getPaymentMethod = state => state.getIn(['shoppingProgress', 'paymentMethod']);

export const getCouponCode = state => state.getIn(['shoppingProgress', 'couponCode']);
export const getCouponId = state => state.getIn(['shoppingProgress', 'couponId']);
export const getCouponContent = state => state.getIn(['shoppingProgress', 'couponContent']);
export const getGift = state => state.getIn(['shoppingProgress', 'gift']);
export const getGiftId = state => state.getIn(['shoppingProgress', 'giftId']);

export const getInvoiceType = state => state.getIn(['shoppingProgress', 'invoiceType']);
export const getInvoiceUbn = state => state.getIn(['shoppingProgress', 'invoiceUbn']);
export const getInvoiceTitle = state => state.getIn(['shoppingProgress', 'invoiceTitle']);

export const getGreetings = state => state.getIn(['shoppingProgress', 'greetings']);
export const getNote = state => state.getIn(['shoppingProgress', 'note']);

export const getIsAgreeTerms = state => state.getIn(['shoppingProgress', 'isAgreeTerms']);

export const getTotalAmount = createSelector(
  [getBuyProducts, getProducts],
  (buyProducts, products) => {
  	let totalAmount = 0;
  	buyProducts.keySeq().forEach(productId => {
  		totalAmount += +buyProducts.getIn([productId]) * +products.getIn([productId, 'price'])
  	})
  	return totalAmount;
  }
)
export const getShippingCost = state => state.getIn(['shoppingProgress', 'shippingCost']);
export const getErrorMessage = state => state.getIn(['shoppingProgress', 'errorMessage']);
export const getDiscount = state => state.getIn(['shoppingProgress', 'discount']);
export const getDeduction = state => state.getIn(['shoppingProgress', 'deduction']);
export const getBalanceDue = state => state.getIn(['shoppingProgress', 'balanceDue']);


export const getOrderId = state => state.getIn(['shoppingProgress', 'orderId']);

export const getTaiwanCity = state => state.getIn(['shoppingProgress', 'TaiwanCity']);
export const getIslands = state => state.getIn(['shoppingProgress', 'islands']);