import { createAction } from 'redux-actions';
import Modal from 'antd/lib/modal';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';

export const doUpdateOrderId = createAction(types.UPDATE_ORDER_ID)
export const doUpdateIsCompleted = createAction(types.UPDATE_IS_COMPLETED)
export const doUpdateIsLoggedIn = createAction(types.UPDATE_IS_LOGGED_IN)
export function doLoadShoppingCompleted(history) {
  return (dispatch, getState) => {
    const state = getState();
    const orderId = state.getIn(['shoppingCompleted', 'orderId']);
    if (orderId === '') {
      history.push(`/products`);
    } 

    if (cookie.getCookie('stormedToken') === '') {
      dispatch(doUpdateIsLoggedIn({ isLoggedIn: false }));     
    } else {
      dispatch(doUpdateIsLoggedIn({ isLoggedIn: true }));
    }    

    const purchaserEmail = state.getIn(['shoppingProgress', 'purchaserEmail']);
    api.cOrderCompleted(purchaserEmail, orderId).then(result => {  
      dispatch(doUpdateIsCompleted({ isCompleted: result.isCompleted })); 
    }).catch((errMsg) => {
      console.log(errMsg);
    })
  }
}

export function doClearOrder() {
  return dispatch => {
    return dispatch(doUpdateOrderId({ orderId: '' }));
  }
}