import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import truckImg from '../../images/truck.svg';

class OrderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.onIsLoggedIn(this.props.history);
    this.props.onLoadOrderDetail(this.props.orderId);
  }    
  render() {
    const { 
      id, paymentDate, paymentMethod, paymentStatus, status, totalAmount, shippingCost, balanceDue, pakcageList
    } = this.props;
  	return (
	    <div className="OrderContent">
        <div className="d-md-flex align-items-center OrderMethod">
          <p>訂單編號：{id}</p>
          <p>結帳時間：{paymentDate}</p>
          <p>付款方式：{paymentMethod}({paymentStatus})</p>
          <div className="Status ml-auto d-inline-flex"><img src={truckImg}/>&nbsp;&nbsp;訂單{status}</div>
        </div>

        <table className="table table-responsive-md align-middle TableList">
          <thead className="OrdersListHead">
            <tr>
              <th scope="col">商品名稱</th>
              <th scope="col">單價</th>
              <th scope="col">數量</th>
              <th scope="col">商品總額</th>
            </tr>
          </thead>
          <tbody>
            {
              pakcageList.size > 0
              &&
              pakcageList.map(package_ => (
                <tr key={package_.getIn(['id'])}>
                  <td className="OrderTH">{package_.getIn(['name'])}</td>
                  <td className="OrderTH">{package_.getIn(['unitPricing'])}</td>
                  <td className="OrderTH">{package_.getIn(['orderQty'])}</td>
                  <td className="OrderTH">{package_.getIn(['totalPricing'])}</td>
                </tr>
              ))
            } 
          </tbody>                  
        </table>
        <div className="Amount">
          <p>商品總金額：{totalAmount}</p>
          <p>運費：{shippingCost}</p>
          {/*<p>購物金折抵：{memberPoint}</p>*/}
          <p className="BalanceDue">訂單總金額：<span>{balanceDue}</span></p>
        </div>
        {/*
        <div>
          <p>獲得會員點數{botainMemberPoint}點</p>
        </div>
        */}
        <div className="row justify-content-center">
          <div className="col-md-3">
            <Link to={`/orders`}>
              <button className="GLBtn" type="button">返回</button>
            </Link>
          </div>
        </div>
	    </div>
	  )
  }
}

export default withRouter(OrderComponent);