import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';


class ResetPasswordWithOldPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    this.props.onIsLoggedIn(this.props.history);
  }
  render() {
    const { 
      isReset, oldPassword, newPassword, confirmPassword, errMessage, 
      onUpdateOldPassword, onUpdateNewPassword, onUpdateConfirmPassword, onSubmitResetPassword
    } = this.props;
  	return (
	    <div>
        {
          isReset
          ?
            <div> 
              <p>密碼已重設，請使用新密碼登入！</p>
              <Link to={`/login`}>
                <button type="button">返回</button>
              </Link>
            </div>
          :
            <form onSubmit={(event) => {event.preventDefault();onSubmitResetPassword(this.props.history)}}>

              <table class="table">
                <tbody>
                  <tr>
                    <td className="TD1"><p className="RegistrationText">舊密碼 (Old Password)</p></td>
                    <td className="TD2"><div className="TableGoldLine"></div></td>
                    <td className="TD3">
                      <input className="RegistrationInput" type="password" placeholder="舊密碼"
                        value={oldPassword}
                        onChange={(event) => onUpdateOldPassword(event.target.value)}
                        required
                      />
                    </td>
                  </tr>

                  <tr>
                    <td><p className="RegistrationText">新密碼 (New Password)</p><p>密碼需4位數以上</p></td>
                    <td><div className="TableGoldLine"></div></td>
                    <td>
                      <input className="RegistrationInput" type="password" placeholder="新密碼"
                        value={newPassword}
                        onChange={(event) => onUpdateNewPassword(event.target.value)}
                        required
                      />
                    </td>
                  </tr>

                  <tr>
                    <td><p className="RegistrationText">再次輸入新密碼 (Confrim Password)</p></td>
                    <td><div className="TableGoldLine"></div></td>
                    <td>
                      <input className="RegistrationInput" type="password" placeholder="再次輸入新密碼"
                        value={confirmPassword}
                        onChange={(event) => onUpdateConfirmPassword(event.target.value)}
                        required
                      />  
                    </td>
                  </tr>


                </tbody>
              </table>
            

              <p className="ErrMessage">{errMessage}</p>

              <div className="container">
                <div className="row">
                  <div className="RegistrationBtnBox col-md-3"></div>
                  <div className="RegistrationBtnBox col-md-3">
                    <Link to={`/orders`}>
                      <button className="ReturnBtn_small" type="button">返回</button>
                    </Link>
                  </div>
                  <div className="RegistrationBtnBox col-md-3">
                    <button className="SendBtn_small" type="submit">儲存</button>
                  </div>
                  <div className="RegistrationBtnBox col-md-3"></div>
                </div>
              </div>

          </form>
        }
	    </div>
	  )
  }
}

export default withRouter(ResetPasswordWithOldPasswordComponent);