import React from 'react';
import B_01 from '../../../images/products/B_01.jpg';
import B_02 from '../../../images/products/B_02.jpg';
import B_03 from '../../../images/products/B_03.jpg';
import B_04 from '../../../images/products/B_04.jpg';
import B_05 from '../../../images/products/B_05.jpg';
import B_06 from '../../../images/products/B_06.jpg';
import B_07 from '../../../images/products/B_07.jpg';


export const ProductsB = (props) => {
  return (
    <div>
      <h2 className="productsTitle">滾筒式護欄</h2>

      <h4 className="mt-3 mb-3"><span className="point-font">「台灣首座滾筒式護欄完工，全省合作夥伴徵求中」</span></h4>
      <h4>滾筒式護欄 <span className="point-font">彎曲山路護欄的新選擇</span></h4>
      <p>
        通過美國MASH碰撞測試/韓國國家道路安全檢測。
      </p>
      <h4 className="mt-5">滾筒式的能量吸收筒設計</h4>
      <p>
        <span className="point-font">有效的緩衝其撞擊力道，避免車輛穿越護欄</span>，大量降低事故造成之傷害。
      </p>
      <h4 className="mt-5">安裝簡易、高效能、低維護成本</h4>
      <p>
        設備施工安裝簡易，其設計可<span className="point-font">自動導正偏離之車輛，零件單獨維修，降低維修成本。</span>
      </p>
      <h4 className="mt-5">特殊材質、高強度反光</h4>
      <p>
        特殊EVA乙烯酯共聚物材質設計，有效吸收撞擊力道，顯亮顏色搭配高強度反光，增加日夜間之能見度。
      </p>

      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_02}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_03}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_04}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_05}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_06}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={B_07}/>
          </div>
        </div>
      </div>
    </div>
  )
}