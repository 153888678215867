import React, { Component } from 'react';
import locationIcon from '../../images/map_pin.svg';

class StoresComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.onLoadStores();
  }    
  render() {
    const { 
      storeIdList, stores,
      onClickStore
    } = this.props;
  	return (
	    <div className="StoresContent">
        <div>
          <select id="StorsSelect_2" onChange={(event) => onClickStore(event.target.value)}>
          {
            storeIdList
            &&
            storeIdList.map(storeId => (
              <option key={storeId} value={storeId}>{stores.getIn([storeId, 'storeName'])}</option>
            ))
          }
          </select>
        </div>
        <table className="StorsSelectTable table table-responsive-md table-hover align-middle TableList">
          <thead className="OrdersListHead">
            <tr>
              <th>分店位置</th>
              <th>電話</th>
              <th>營業時間</th>
              <th>地址</th>
            </tr>
          </thead>
          <tbody>
            {
              storeIdList
              &&
              storeIdList.map(storeId => (
                <tr key={storeId} onClick={(event) => onClickStore(storeId)}>
                  <td className="StoresTH">{stores.getIn([storeId, 'storeName'])}</td>
                  <td className="StoresTH"><a href={`tel:${stores.getIn([storeId, 'storePhone'])}`}>{stores.getIn([storeId, 'storePhone'])}</a></td>
                  <td className="StoresTH">{stores.getIn([storeId, 'storeHour'])}</td>
                  <td className="StoresTH">{stores.getIn([storeId, 'storeAddress'])}{stores.getIn([storeId, 'description'])}&nbsp;&nbsp;
                  <a href="#"><img src={locationIcon} alt="" /></a>
                  </td>
                </tr>
              ))
            }
          </tbody>                  
        </table>
	    </div>
	  )
  }
}

export default StoresComponent;