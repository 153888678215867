import { createAction } from 'redux-actions';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from '../../utils/cookiesTool';
import * as config from '../../config'


export const doShowUserDorpDown = createAction(types.SHOW_USER_DROP_DOWN)
export function doClickUser(history) {
	return dispatch => {
		const stormedToken = cookie.getCookie('stormedToken');
		if (stormedToken) {
	    return dispatch(doShowUserDorpDown({ show: true }));
		} else {
			history.push(`/login`)
			return dispatch(doShowUserDorpDown({ show: false }));
		}
	}
}

export const doUpdateMemberData = createAction(types.UPDATE_MEMBER_DATA)	// login module
export function doLogout() {
	return dispatch => {
    cookie.deleteAllCookies();
    window.location.href = `${config.logoutPage}`;
  }
}


