import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'coupons/UPDATE_STATUS_FILTER': (state, { payload }) => {
    return state.setIn(['statusFilter'], payload.statusFilter);
  },	
  'coupons/UPDATE_SEARCH_TEXT': (state, { payload }) => {
    return state.setIn(['searchText'], payload.searchText);
  },
  'coupons/UPDATE_COUPONS': (state, { payload }) => {
    return state.setIn(['coupons'], fromJS(payload.coupons));
  },
  'coupons/UPDATE_COUPON_ID_LIST': (state, { payload }) => {
    return state.setIn(['couponIdList'], fromJS(payload.couponIdList));
  },
}, State);

export default Reducer;