import React, { Component } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { DELIVERY_METHOD, PAYMENT_METHOD, INVOICE_TYPE, DELIVERY_TIME } from '../../utils/constants/order';
// images
import discountIcon from '../../images/discount.png'
import minusIcon from '../../images/minus.svg'; // -
import plusIcon from '../../images/plus.svg'; // +
import shoppingCartOkIcon from '../../images/icon_ShoppingCart_ok.png';
import enterDataIcon from '../../images/icon_EnterData.png';
import enterDataOkIcon from '../../images/icon_EnterData_ok.png';
import confirmIcon from '../../images/icon_Confirm.png';
import confirmOkIcon from '../../images/icon_Confirm_ok.png';
import CouponsIconS from '../../images/coupons_icon_S.png'
import LineIcon from '../../images/line-icon.svg';
import FBIcon from '../../images/fb-icon.svg';

const dateFormat = 'YYYY/MM/DD';


class ShoppingProgressComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // currentStep: 0,
      showShoppingTrems: false
    };
    this.onClickShoppingTerms = this.onClickShoppingTerms.bind(this);
    this.disabledDate = this.disabledDate.bind(this);
  }
  componentDidMount() {
  if (this.props.buyProducts.size > 0) {
      this.props.onInitOrderData();
    }
    if (this.props.currentStep > 1) {
      this.props.onUpdateShoppingData(0, 'currentStep');
    }
  }
  disabledDate(current) {
    if (!current) return true;

    if (this.props.deliveryMethod === '1') {  // 貨運宅配
      // 不允許宅配，全都 disable
      if (this.props.canShippingDelivery === false) {
        return true;
      }
      const d = moment(current.valueOf());
      // 週日不宅配
      if (d.format("d") == 0) {
        return true;
      }
      // 不論是否限期，宅配最晚都要在四天前下單
      let allowedBeginDate = new Date();
      allowedBeginDate.setDate(allowedBeginDate.getDate() + 3);
      if (current.valueOf() < allowedBeginDate) {
        return true;
      };
      // 限期商品
      if (this.props.shippingDeliveryDateList.length !== 0 && this.props.shippingDeliveryDateList.indexOf(d.format('YYYY/MM/DD')) === -1) {
        return true;
      }
    } else if (this.props.deliveryMethod === '2') { // 門市取貨
      // 不允許自取，全都 disable
      if (this.props.canShippingSelf === false) {
        return true;
      }
      const d = moment(current.valueOf());
      // 不論是否限期，最晚都要在三天前下單
      let allowedBeginDate = new Date();
      allowedBeginDate.setDate(allowedBeginDate.getDate() + 2);
      if (current.valueOf() < allowedBeginDate) {
        return true;
      };
      // 限期商品
      if (this.props.shippingSelfDateList.length !== 0 && this.props.shippingSelfDateList.indexOf(d.format('YYYY/MM/DD')) === -1) {
        return true;
      }
    } else {
      return true;
    }
  }
  onClickShoppingTerms(showShoppingTrems) {
    this.setState({ showShoppingTrems })
  }
  next() {
    window.scrollTo(0, 0);
    // const currentStep = this.state.currentStep + 1;
    // this.setState({ currentStep });
    this.props.onUpdateShoppingData(this.props.currentStep + 1, 'currentStep');
  }
  prev() {
    // const currentStep = this.state.currentStep - 1;
    // this.setState({ currentStep });
    this.props.onUpdateShoppingData(this.props.currentStep - 1, 'currentStep');
  }
  onSubmitShoppingDate() {
    this.props.onUpdateShoppingData('', 'errorMessage');
    if (this.props.isAgreeTerms) {
      if (this.props.couponCode) {
        this.props.onClickCheckCoupon();
      }
      if (this.props.buyProducts.keySeq().size < 1) {
        this.props.onUpdateShoppingData('購物車內無商品', 'errorMessage');
      } else {
        this.props.onOrderPreprocessing();
        this.next();
      }
    } else {
      this.props.onUpdateShoppingData('您尚未同意鄧師傅線上購物約定條款', 'errorMessage');
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.buyProducts.size === 0 && this.props.buyProducts.size > 0) {
      this.props.onInitOrderData();
    }
    if (prevProps.currentStep === 0 && this.props.currentStep > 1) {
      this.props.onUpdateShoppingData(0, 'currentStep');
    }
  }
  renderShoppingCart() {
    const {
      buyProducts, products, totalAmount,
      onClickRemoveBuyProduct, onUpdateBuyProductQuantity, onUpdateShoppingData
    } = this.props;
    return (
      <div>
        <div className="tableBox table-responsive" key={`shoppingCart`}>
          <table className="rwd-table">
            <thead className="tableHeade">
              <tr className="tableHeadeText">
                {/*<th className="ThPhoto" scope="col"></th>*/}
                <th>商品名稱</th>
                <th>單價</th>
                <th className="ThModify">數量</th>
                <th>庫存</th>
                <th>商品總額</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                (buyProducts, products)
                &&
                buyProducts.keySeq().map(productId =>
                  <tr key={`shopping_cart_${productId}`}>
                    {/*<td data-th="">
                      <div className="ShoppingProgressPhoto">
                        <img className="rounded mx-auto d-block" src={`/packages/ss/${products.getIn([productId, "image"])}`} alt={products.getIn([productId, "name"])} />
                      </div>
                </td>*/}
                    <td data-th="商品名稱"><span className="ShoppingProgressText">{products.getIn([productId, "name"])}</span></td>
                    <td data-th="單價"><span className="ShoppingProgressText">{products.getIn([productId, "price"])}</span></td>
                    <td className="td-count">
                      <div className="ShoppingProgressModify col-auto mr-auto d-flex align-items-center">
                        <button className="Minus" type="button" onClick={(event) => onUpdateBuyProductQuantity(productId, -1)} disabled={buyProducts.getIn([productId]) == 1}><img src={minusIcon} alt="" /></button>
                        <input className="QuantityNumber" type="number" name="quantity" value={buyProducts.getIn([productId])} onChange={(event) => onUpdateBuyProductQuantity(productId, event.target.value)} disabled />
                        <button className="Plus" type="button" onClick={(event) => onUpdateBuyProductQuantity(productId, 1)} disabled={products.getIn([productId, "quantity"]) <= buyProducts.getIn([productId])}><img src={plusIcon} alt="" /></button>
                      </div>
                    </td>
                    <td data-th="庫存" className="rwd-table-hide"><span className="ShoppingProgressText">{products.getIn([productId, "quantity"])}</span></td>                    
                    <td data-th="商品總額" className="rwd-table-hide"><span className="ShoppingProgressText">{+products.getIn([productId, "price"]) * +buyProducts.getIn([productId])}</span></td>
                    <td>
                      <div className="ShoppingProgressBtnBox_small">
                        <button className="ShoppingProgressBtnSendBtn_small" type="button" onClick={() => onClickRemoveBuyProduct(productId)}>刪除</button>
                      </div>
                    </td>
                  </tr>
                )
              }
            </tbody>

          </table>
          <div className="ShoppingProgressTotalPrice">
            <span className="ShoppingProgressTotalPriceTextGray">總金額：</span>
            <span className="ShoppingProgressTotalPriceTextRed">{totalAmount}</span>
          </div>
        </div>


        <div className="container ShoppingProgressBtnBox">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-3">
              <Link to={`/products`}>
                <button className="ShoppingProgressReturnBtn_small" type="button" >繼續購物</button>
              </Link>
            </div>
            <div className="col-md-3">
              {
                this.props.buyProducts.size > 0
                &&
                <button className="ShoppingProgressBtnSendBtn_small" type="button" onClick={() => { onUpdateShoppingData(`${totalAmount}`, "totalAmount"); this.next(); }} disabled={buyProducts.keySeq().size === 0}>結帳</button>
              }
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    )
  }
  renderShoppingData() {
    const {
      storeIdNames, deliveryMethod, deliveryDate, deliveryTime, storeId, TaiwanCity, canShippingSelf, canShippingDelivery,
      purchaserName, purchaserGender, purchaserEmail, purchaserPhone, purchaserAddress, purchaserCity,
      asPurchaserData, deliveryName, deliveryGender, deliveryPhone, deliveryAddress, deliveryCity, onClickAsPurchaserData,
      paymentMethod,
      couponCode, couponContent, gift, giftId, onClickCheckCoupon,
      invoiceType, invoiceUbn, invoiceTitle,
      greetings, note,
      errorMessage, isAgreeTerms, onUpdateShoppingData
    } = this.props;

    return (
      <div key={`shoppingData`}>
        <form onSubmit={(event) => { event.preventDefault(); this.onSubmitShoppingDate() }}>

          <div className="ShoppingProgressTable">
            <div className="ShoppingProgressTitleTextBox">
              <span className="ShoppingProgressTitleText">取貨方式 / Delivery Info</span>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="TD1"><p className="RegistrationText ShoppingRWDText">取貨方式 (Delivery Info)</p></td>
                  <td className="TD2"><div className="TableGoldLine"></div></td>
                  <td className="TD3">
                    <div className="RadioText">
                      {
                        canShippingDelivery
                        &&
                        <label className="normalFont ShoppingRWDText"><input type="radio" name="deliveryMethod" value="1"
                          checked={deliveryMethod === "1"}
                          onChange={(event) => onUpdateShoppingData(event.target.value, "deliveryMethod")}
                        />&nbsp;&nbsp;貨運宅配&nbsp;&nbsp;&nbsp;&nbsp;</label>
                      }
                      {
                        canShippingSelf
                        &&
                        <label className="normalFont ShoppingRWDText"><input type="radio" name="deliveryMethod" value="2"
                          checked={deliveryMethod === "2"}
                          onChange={(event) => { onUpdateShoppingData(event.target.value, "deliveryMethod"); onUpdateShoppingData("1", "paymentMethod"); }}
                        />&nbsp;&nbsp;到店取貨</label>
                      }
                    </div>
                  </td>
                </tr>

                <tr>
                  <td><p className="RegistrationText ShoppingRWDText">到貨日期 (Arrival Date)</p></td>
                  <td><div className="TableGoldLine"></div></td>
                  <td>
                    <div className="RadioText">
                      <div className="row">
                        {
                          // lastShippingDate
                          // &&
                          // <div className="w-100 px-4 align-self-center ShoppingRWDText text-danger">
                          //   您選擇的商品將於 2020 年 1 月 6 日後統一出貨。如需分開配送，請洽客服專員。
                          // </div>
                          // 由於所訂購的商品有指定出貨日，因此只能選擇{lastShippingDate}之後的日期。
                        }
                        <div className="col-md-3">
                          <DatePicker
                            placeholder="請選擇日期"
                            inputReadOnly={true}
                            showToday={false}
                            format={dateFormat}
                            disabledDate={this.disabledDate}
                            value={deliveryDate ? moment(deliveryDate, dateFormat) : null}
                            onChange={(value, dateString) => onUpdateShoppingData(dateString, "deliveryDate")}
                          />
                          <input className="HiddenDatepicker" type="text" value={deliveryDate} required/>
                        </div>
                      </div>
                      {/* <div className="RadioText row">
                        <div className="col-md-4">
                          <label className="normalFont ShoppingRWDText"><input className="RegistrationInput ShoppingRWDText" type="radio" name="deliveryTime" value="0"
                            checked={deliveryTime === "0"}
                            onChange={(event) => onUpdateShoppingData(event.target.value, "deliveryTime")}
                          />&nbsp;&nbsp;不指定</label>
                        </div>
                        <div className="col-md-4">
                          <label className="normalFont ShoppingRWDText"><input className="RegistrationInput" type="radio" name="deliveryTime" value="1"
                            checked={deliveryTime === "1"}
                            onChange={(event) => onUpdateShoppingData(event.target.value, "deliveryTime")}
                          />&nbsp;&nbsp;早上</label>
                        </div>
                        <div className="col-md-4">
                          <label className="normalFont ShoppingRWDText"><input className="RegistrationInput" type="radio" name="deliveryTime" value="2"
                            checked={deliveryTime === "2"}
                            onChange={(event) => onUpdateShoppingData(event.target.value, "deliveryTime")}
                          />&nbsp;&nbsp;下午</label>
                        </div>
                      </div> */}
                      <p className="RegistrationTextRemarks ShoppingRWDText">
                        註1:黑貓宅急便週日停止集貨/配送包裹服務。 <br/>
                        註2:實際到貨日期，可能因黑貓宅急便配送有誤差情形。
                      </p>
                    </div>
                  </td>
                </tr>
                { /* 選 到店取貨 才顯示此區塊 */
                  deliveryMethod === "2"
                  &&
                  <tr>
                    <td><p className="RegistrationText ShoppingRWDText">分店名稱(Store Name)</p></td>
                    <td><div className="TableGoldLine"></div></td>
                    <td>
                      <div className="categorySelectShopping">
                        <select id="categorySelectShopping" key={`pickModeStores`} value={storeId} onChange={(event) => onUpdateShoppingData(event.target.value, "storeId")}>
                          {
                            storeIdNames.map(store => (
                              <option key={store.getIn(["storeId"])} value={store.getIn(["storeId"])}>{store.getIn(["storeName"])}</option>
                            ))
                          }
                        </select>
                      </div>
                      <p className="RegistrationTextRemarks ShoppingRWDText">註：門市取貨需完成線上刷卡，訂單方可成立。</p>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>

          <div className="ShoppingProgressTable">
            <div className="ShoppingProgressTitleTextBox">
              <span className="ShoppingProgressTitleText">購買人資料 /  Basic Information</span>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="TD1"><p className="RegistrationText ShoppingRWDText">姓名/稱謂 (Name/Title)</p></td>
                  <td className="TD2"><div className="TableGoldLine"></div></td>
                  <td className="TD3">
                    <div className="RadioText">

                      <input className="RegistrationInput" type="text"
                        value={purchaserName}
                        onChange={(event) => onUpdateShoppingData(event.target.value, "purchaserName")}
                        required
                      />
                      <label className="normalFont ShoppingRWDText"><input className="RegistrationInput" type="radio" name="purchaserGender" value="male"
                        checked={purchaserGender === "male"}
                        onChange={(event) => onUpdateShoppingData(event.target.value, "purchaserGender")}
                      />&nbsp;&nbsp;先生&nbsp;&nbsp;&nbsp;&nbsp;</label>
                      <label className="normalFont ShoppingRWDText"><input className="RegistrationInput" type="radio" name="purchaserGender" value="female"
                        checked={purchaserGender === "female"}
                        onChange={(event) => onUpdateShoppingData(event.target.value, "purchaserGender")}
                      />&nbsp;&nbsp;小姐</label>
                    </div>
                    <span className="RegistrationTextRemarks ShoppingRWDText">請正確填寫您的全名，以利於配送流程順暢。</span>
                  </td>
                </tr>

                <tr>
                  <td><p className="RegistrationText ShoppingRWDText">電子郵件 (E-mail)</p></td>
                  <td><div className="TableGoldLine"></div></td>
                  <td>
                    <div className="RadioText">
                      <input className="RegistrationInput" type="Email"
                        value={purchaserEmail}
                        onChange={(event) => onUpdateShoppingData(event.target.value, "purchaserEmail")}
                        required
                      />
                      <span className="RegistrationTextRemarks ShoppingRWDText">請正確填寫電子信箱，以利於收到訂單確認信函。</span>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td><p className="RegistrationText ShoppingRWDText">行動電話 (Mobile)</p></td>
                  <td><div className="TableGoldLine"></div></td>
                  <td>
                    <div className="RadioText">
                      <input className="RegistrationInput" type="Tel" pattern="[0-9]{10}"
                        value={purchaserPhone}
                        onChange={(event) => onUpdateShoppingData(event.target.value, "purchaserPhone")}
                        required
                      />
                    </div>
                  </td>
                </tr>

                {
                  deliveryMethod === "1"
                  &&
                  <tr>
                    <td><p className="RegistrationText ShoppingRWDText">到貨地址 (Address)</p></td>
                    <td><div className="TableGoldLine"></div></td>
                    <td>
                      <select id="categorySelectShopping" value={purchaserCity} onChange={(event) => onUpdateShoppingData(event.target.value, "purchaserCity")}>
                        {
                          TaiwanCity.map(t => (
                            <option key={t.getIn(["id"])} value={t.getIn(["city"])}>{t.getIn(["city"])}</option>
                          ))
                        }
                      </select>
                      <div className="RadioText">
                        <input className="RegistrationInput" type="text"
                          value={purchaserAddress}
                          onChange={(event) => onUpdateShoppingData(event.target.value, "purchaserAddress")}
                          required
                        />
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>


          { /* 選 貨運宅配 才顯示這區塊 */
            deliveryMethod === "1"
            &&
            <div className="ShoppingProgressTable">
              <div className="ShoppingProgressTitleTextBox row">
                <div className="col-md-6"><span className="ShoppingProgressTitleText">收件人資料 / Recevier Information</span></div>
                <div className="col-md-6 ShoppingProgressCheckbox">
                  <p className="ShoppingProgressCheckbox">
                    <label className="normalFont ShoppingRWDText"><input type="checkbox" name="vehicle" value="Bike" checked={asPurchaserData} onClick={(event) => onClickAsPurchaserData(event.target.checked)} />&nbsp;&nbsp;同購買人資料</label>
                  </p>
                </div>
              </div>


              <table className="table">
                <tbody>
                  <tr>
                    <td className="TD1"><p className="RegistrationText ShoppingRWDText">姓名/稱謂 (Name/Title)</p></td>
                    <td className="TD2"><div className="TableGoldLine"></div></td>
                    <td className="TD3">
                      <div className="RadioText">
                        <input className="RegistrationInput" type="text"
                          value={deliveryName}
                          onChange={(event) => onUpdateShoppingData(event.target.value, "deliveryName")}
                          required
                        />
                        <label className="normalFont ShoppingRWDText"><input className="RegistrationInput" type="radio" name="deliveryGender" value="male"
                          checked={deliveryGender === "male"}
                          onChange={(event) => onUpdateShoppingData(event.target.value, "deliveryGender")}
                        />&nbsp;&nbsp;先生&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <label className="normalFont ShoppingRWDText"><input className="RegistrationInput" type="radio" name="deliveryGender" value="female"
                          checked={deliveryGender === "female"}
                          onChange={(event) => onUpdateShoppingData(event.target.value, "deliveryGender")}
                        />&nbsp;&nbsp;小姐</label>
                    </div>
                    </td>
                  </tr>

                  <tr>
                    <td><p className="RegistrationText ShoppingRWDText">行動電話 (Mobile)</p></td>
                    <td><div className="TableGoldLine"></div></td>
                    <td>
                      <div className="RadioText">
                        <input className="RegistrationInput" type="Tel" pattern="[0-9]{10}"
                          value={deliveryPhone}
                          onChange={(event) => onUpdateShoppingData(event.target.value, "deliveryPhone")}
                          required
                        />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td><p className="RegistrationText ShoppingRWDText">收貨地址 (Address)</p></td>
                    <td><div className="TableGoldLine"></div></td>
                    <td>
                      <div className="RadioText">
                        <select id="categorySelectShopping" value={deliveryCity} onChange={(event) => onUpdateShoppingData(event.target.value, "deliveryCity")}>
                          {
                            TaiwanCity.map(t => (
                              <option key={t.getIn(["id"])} value={t.getIn(["city"])}>{t.getIn(["city"])}</option>
                            ))
                          }
                        </select>
                        <input className="RegistrationInput" type="text"
                          value={deliveryAddress}
                          onChange={(event) => onUpdateShoppingData(event.target.value, "deliveryAddress")}
                          required
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          }



          <div className="ShoppingProgressTable">
            <div className="ShoppingProgressTitleTextBox">
              <span className="ShoppingProgressTitleText">輸入優惠碼 / Discount</span>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="TD1"><p className="RegistrationText ShoppingRWDText">折扣代碼(Discount Code)</p></td>
                  <td className="TD2"><div className="TableGoldLine"></div></td>
                  <td className="TD3">
                    <div className="container">
                      <div className="row">
                        {
                          /*<img className="RegistrationImgGifts" src={CouponsIconS} alt="" />*/
                        }
                        <div className="col-md-8">
                          <input className="RegistrationInputGifts" 
                                 type="text" 
                                 value={couponCode}
                                 onChange={(event) => onUpdateShoppingData(event.target.value, "couponCode")}
                          />
                        </div>
                        <div className="col-md-4"><button className="ShoppingProgressReturnBtnGifts_small" type="button" onClick={() => onClickCheckCoupon()}>送出</button></div>
                      </div>
                      <div class="RegistrationTextRemarks ShoppingRWDText">
                        <p className="tipsPoint">期間限定：輸入《ME1500》單筆滿1500元享免運。</p>
                        單筆訂單可使用一項優惠碼。恕不與其他優惠同時使用！
                      </div>
                      {
                        couponContent
                        &&
                        <div className="RegistrationTextGiftsDescription">
                          <p class="RegistrationTextRemarks ShoppingRWDText">{couponContent}</p>
                          <p class="RegistrationTextRemarks ShoppingRWDText">{errorMessage}</p>
                        </div>
                      }
                      {/*
                        (gifts && gifts.getIn([giftId, 'giftId']))
                        &&
                        <div className="RegistrationTextTitleBox">
                          <p className="RegistrationTextTitle ShoppingRWDText">選擇商品(Choice Of Gifts)</p>
                          {
                            gifts.entrySeq().map(([id, gift]) => (
                              <div className="RegistrationTextBox">
                                <label key={id}>
                                  <div className="">
                                    <input className="RegistrationTextInput" type="radio" name="gifts" value={id}
                                      checked={parseInt(giftId) === id}
                                      onChange={(event) => onUpdateShoppingData(event.target.value, "giftId")}
                                      required
                                    />
                                  </div>
                                  <div className="ShoppingProgressPhotoGifts"><img className="rounded mx-auto d-block" src={gift.getIn(['image'])} alt={gift.getIn(['giftName'])} /></div>
                                  <div className="RegistrationTextGifts"><p className="RegistrationText">{`${gift.getIn(['giftName'])}x${gift.getIn(['number'])}`}</p></div>
                                </label>

                              </div>

                            ))
                          }
                        </div>
                      */}
                      {/*
                      <p>會員點數折抵(Shopping gold) 尚有 點</p>
                    */}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>


          <div className="ShoppingProgressTable">
            <div className="ShoppingProgressTitleTextBox">
              <span className="ShoppingProgressTitleText">發票資訊 / Invoice Information</span>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="TD1"><p className="RegistrationText ShoppingRWDText">發票種類(Invoice Type)</p></td>
                  <td className="TD2"><div className="TableGoldLine"></div></td>
                  <td className="TD3">
                    <div className="RadioText row">
                      <div className="col-md-4">
                        <label className="normalFont ShoppingRWDText"><input type="radio" name="invoiceType" value="1"
                          checked={invoiceType === "1"}
                          onChange={(event) => onUpdateShoppingData(event.target.value, "invoiceType")}
                          required
                        />&nbsp;&nbsp;捐贈發票</label>
                        <p className="tips ShoppingRWDText">發票捐贈單位: 財團法人伊甸社會福利基金會</p>
                      </div>
                      <div className="col-md-4">
                        <label className="normalFont ShoppingRWDText"><input type="radio" name="invoiceType" value="2"
                          checked={invoiceType === "2"}
                          onChange={(event) => onUpdateShoppingData(event.target.value, "invoiceType")}
                          required
                        />&nbsp;&nbsp;電子發票</label>
                      </div>
                      <div className="col-md-4">
                        <label className="normalFont ShoppingRWDText"><input type="radio" name="invoiceType" value="3"
                          checked={invoiceType === "3"}
                          onChange={(event) => onUpdateShoppingData(event.target.value, "invoiceType")}
                          required
                        />&nbsp;&nbsp;公司發票</label>
                      </div>
                      線上刷卡成功一律自動產生電子發票(貨到付款則會開立紙本發票隨貨寄出):<br/>
                        (1) 電子發票具有與紙本發票相同效力，且可隨時上網查詢。<br/>
                        (2) 如需統編者與公司抬頭，請於下方輸入。<br/>
                        (3) 如仍需索取紙本發票，請於備註欄註明。<br/>
                    </div>
                  </td>
                </tr>
                {
                  invoiceType === "2"
                  &&  
                  <tr>
                    <td><p className="RegistrationText ShoppingRWDText">統一編號(Company's UBN)</p></td>
                    <td><div className="TableGoldLine"></div></td>
                    <td>
                      <input className="RegistrationInput" type="text" pattern="[0-9]{8}" value={invoiceUbn} onChange={(event) => onUpdateShoppingData(event.target.value, "invoiceUbn")} />
                    </td>
                  </tr>
                }
                {
                  invoiceType === "3"
                  &&
                  <tr>
                    <td><p className="RegistrationText ShoppingRWDText">統一編號(Company's UBN)</p></td>
                    <td><div className="TableGoldLine"></div></td>
                    <td>
                      <input className="RegistrationInput" type="text" required="required" pattern="[0-9]{8}" value={invoiceUbn} onChange={(event) => onUpdateShoppingData(event.target.value, "invoiceUbn")} />
                    </td>
                  </tr>
                }                
                {
                  invoiceType === "3"
                  &&
                  <tr>
                    <td><p className="RegistrationText ShoppingRWDText">發票抬頭(Company's Name)</p></td>
                    <td><div className="TableGoldLine"></div></td>
                    <td>
                      <input className="RegistrationInput" type="text" required="required"
                        value={invoiceTitle}
                        onChange={(event) => onUpdateShoppingData(event.target.value, "invoiceTitle")}
                        requried
                      />
                    </td>
                  </tr>
                }

              </tbody>
            </table>
          </div>

          <div className="ShoppingProgressTable">
            <div className="ShoppingProgressTitleTextBox">
              <span className="ShoppingProgressTitleText">其它 / Other</span>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="TD1"><p className="RegistrationText ShoppingRWDText">賀詞(Greetings)</p></td>
                  <td className="TD2"><div className="TableGoldLine"></div></td>
                  <td className="TD3">
                    <textarea className="ShoppingProgressTextarea" cols="50" rows="5" placeholder="如果您有賀卡需求，請將賀詞寫給我們。我們會為您製作貼心賀卡隨商品附上。舉例：TO 美美 祝生日快樂 FROM 明明"
                      value={greetings} onChange={(event) => onUpdateShoppingData(event.target.value, "greetings")} />
                  </td>
                </tr>

                <tr>
                  <td className="TD1"><p className="RegistrationText ShoppingRWDText">備註(Note)</p></td>
                  <td className="TD2"><div className="TableGoldLine"></div></td>
                  <td className="TD3">
                    <textarea className="ShoppingProgressTextarea ShoppingRWDText" cols="50" rows="5" placeholder="如果您有送禮需求，請將所需要的禮盒與紙袋數量寫在備註給我們。我們會隨商品一同附上。 舉例: 禮盒×1 紙袋×2"
                      value={note} onChange={(event) => onUpdateShoppingData(event.target.value, "note")} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="ShoppingProgressTable">
            <div className="ShoppingProgressTitleTextBox">
              <span className="ShoppingProgressTitleText">選擇付款方式 / Payment Method</span>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td className="TD1"><p className="RegistrationText ShoppingRWDText">付款方式(Payment Method)</p></td>
                  <td className="TD2"><div className="TableGoldLine"></div></td>
                  <td className="TD3">
                    <div className="RadioText">
                      <label className="normalFont ShoppingRWDText"><input type="radio" name="paymentMethod" value="1"
                        checked={paymentMethod === "1"}
                        onChange={(event) => onUpdateShoppingData(event.target.value, "paymentMethod")}
                      />&nbsp;&nbsp;線上刷卡&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <div className="Logo_Card"></div>
                      </label>

                      <label className="normalFont ShoppingRWDText"><input type="radio" name="paymentMethod" value="2"
                        checked={paymentMethod === "2"} disabled={deliveryMethod === "2"}
                        onChange={(event) => onUpdateShoppingData(event.target.value, "paymentMethod")}
                      />&nbsp;&nbsp;貨到付款
                      <div className="Logo_BlackCat"></div>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* <div className="ShoppingProgressDescription">
             
            </div> */}
          </div>
          <div className="ShoppingProgressTermsBox">
            <div className="ShoppingProgressTerms">
              <label className="normalFont">
                <input type="checkbox"
                  checked={isAgreeTerms}
                  onChange={(event) => onUpdateShoppingData(event.target.checked, "isAgreeTerms")}
                />&nbsp;&nbsp;<span className="ShoppingRWDText">我已詳閱並同意</span>
              </label>
              <span className="termsLink ShoppingRWDText" onClick={() => this.onClickShoppingTerms(true)}>&nbsp;鄧師傅線上購物約定條款</span>
              { /* 麻煩 Sandy 調整此處版面 */
                this.state.showShoppingTrems
                &&
                <div onClick={() => this.onClickShoppingTerms(false)} className="termsContent ShoppingProgressDescription">
                  <span className="ShoppingProgressDescriptionTitle">付款方式說明</span>
                  <ol>
                    <li>1. 此網站產品憑証以發票。</li>
                    <li>2. 本網站目前接受《線上刷卡或貨到付款》等方式付款。</li>
                    <li>3. 商品價格不含運費。運費會依據您訂購的商品數量及寄送位置不同而有所調整。若選擇至鄧師傅門市取貨，則無須付宅配費用。若選擇貨到付款，本網站目前配合的物流廠商均為最專業的宅配公司－黑貓宅急便，您可放心的將商品費用與運費一併交給宅配人員。</li>
                    <li>4. 宅配送貨說明—由於黑貓宅急便春節假期業務量大，商品到貨時間不易準時，煩請儘早訂購，謝謝。</li>
                    <li>5. 本公司因業務上所掌握之客戶基本資料均屬機密，受個人資料保護法保護，除客戶依法行使其權利（查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用、請求刪除）外，本公司不對第三人揭露。 前項資料客戶同意本公司刊登，並同意本公司就申請書上內容得編印或建置客戶目錄，於合約目的範圍內為合理之處理與利用，妥善保存至服務結束為止，服務結束後由本公司銷毀及刪除。</li>
                  </ol>
                  <br />
                  貴用戶所提供予鄧師傅滷味股份有限公司之個人資料，將依個人資料保護法之規定來使用、保管與維護。<br />
                  <ol>
                    <li><b>1. 客戶資料之使用目的</b><br />
                      為商業與技術資訊、客戶管理、會員管理、行銷、資訊與資料庫管理、統計調查與分析、其他合於營業登記項目或章程所定業務之需要等目的，蒐集並於前述目的範圍內利用貴用戶所提供之個人資料。
<br /></li>
                    <li><b>2. 客戶資料蒐集方式、利用範圍</b><br />
                      本網站基於服務之提供或商品之銷售，將會請貴用戶填寫服務所需之相關資料(例如:帳號申請、商品宅配資料、退款、兌換贈品等其他行銷活動或基於服務需要)，而取得貴用戶的基本資料。鄧師傅滷味股份有限公司僅將貴用戶的該些資料使用在貴用戶所需的個別服務上。
<br /></li>
                    <li><b>3. 客戶資料使用期間、使用區域</b><br />
                      鄧師傅滷味股份有限公司將持續使用貴用戶提供的個人資料直至貴用戶提出停止使用或鄧師傅滷味股份有限公司停止提供服務之日為止。貴用戶的個人資料使用區域僅限於台灣地區內(台澎金馬等離島)使用，並為提供貴用戶更好之服務品質，鄧師傅滷味股份有限公司將會在法令許可或貴用戶同意下，將貴用戶的資料提供給合作之商品配送業者、商品供應商或關係企業，以提供貴用戶所需的個別服務、行銷活動及相關優惠訊息給貴用戶。
<br /></li>
                    <li><b>4. 客戶資料分類及項目</b><br />
                      依所提供之服務需要，可能需請貴用戶提供下列的個人資料種類：<br />
                      (1) 基本資料：包括姓名、出生年月日、身分證統一編號、電話、地址及電子郵件信箱等資料。<br />
                      (2) 帳務資料：包括帳戶號碼或類似功能號碼、信用卡帳號、存款帳號、交易帳戶號碼、存借款及其他往來交易資料及財務情況等資料。<br />
                      <br /></li>
                    <li><b>5. 客戶資料儲存及保管方式</b><br />
                      貴用戶的個人資料均被嚴密地保存在本公司的資料庫系統中。同時，任何人均需在本公司訂定之資料授權管理規範下，進行資料之取得與使用，而不在授權範圍內之任何人，均不得亦無法通過授權控管系統而取得資料。
<br /></li>
                    <li><b>6. 資料安全及保護方法</b><br />
                      鄧師傅滷味股份有限公司以Secure Sockets Layer（SSL）機制進行資料傳輸的加密，並已加裝防火牆防止不法入侵，避免貴用戶的個人資料遭到非法存取。鄧師傅滷味股份有限公司並應用亂碼化方式儲存密碼，以確保貴用戶的密碼不會遭到非法竊取。
<br /></li>
                    <li><b>7. 客戶資料揭露對象</b><br />
                      鄧師傅滷味股份有限公司絕對不會任意出售、交換、出租或以其他變相之方式，將貴用戶的個人資料揭露與其他團體或個人。惟有下列三種情形，鄧師傅滷味股份有限公司會與第三者共用貴用戶的個人資料。<br />
                      (1) 經過貴用戶的事前同意或授權允許時。<br />
                      (2) 司法單位或其他主管機關經合法正式的程序要求時。<br />
                      (3) 為了提供貴用戶其他服務或優惠權益，需要與提供該服務或優惠之第三者共用貴用戶的資料時，鄧師傅滷味股份有限公司會在活動時提供充分說明並告知，貴用戶可以自由選擇是否接受這項服務或優惠。
<br /></li>
                    <li><b>8. 客戶資料變更修改方式</b><br />
                      (1) 貴用戶得就貴用戶留於本網站之個人資料依法向本網站以書面或電子文件請求行使:查詢或請求閱覽、製給複製本、補充或更正、請求停止蒐集、處理或利用、請求刪除等權利。<br />
                      (2) 如果貴用戶就貴用戶的會員註冊資料向本網站請求答覆查詢、提供閱覽或製給複製本時，本網站將酌收必要成本費用。而前述之申請，應填具申請文件 ，本網站得向貴用戶請求提出可資確認之身分證明文件;若委託他人代為申請者，並應出具委任書，且提供本人及代理人之身分證明文件。<br />
                      (3) 如果貴用戶的個人資料有變更，貴用戶可自行於鄧師傅滷味股份有限公司網頁進行更正或致電本網站客服中心修正貴用戶的個人資料。<br />
                      <br /></li>
                    <li><b>9. 選擇退出方式</b><br />
                      鄧師傅滷味股份有限公司將依據法令規定、貴用戶與本網站往來之相關契約或電子文件上勾選同意提供之資料範疇，在鄧師傅滷味股份有限公司及各關係企業間 進行共同行銷時交互運用與揭露。如果貴用戶不願意將貴用戶的資料提供予鄧師傅滷味股份有限公司或鄧師傅滷味股份有限公司之廠商或關係企業繼續使用，貴用戶也可以致電本網站客服中心，鄧師傅滷味股份有限公司將於停止就貴用戶的資料繼續使用。貴用戶得自由選擇提供個人資料之程度，惟若提供之資料不足或有誤時，本網站則可能將無法提供完整之服務。<br />
                      <br /></li>
                  </ol>
                  因本網站協助貴用戶執行本項需求時可能因此刪除貴用戶的銷售紀錄及紅利點數，本網站於取得貴用戶同意後即進行資料之移除或停止使用。
                </div>
              }
              <p className="RegistrationText ShoppingRWDText">{errorMessage}</p>
            </div>
          </div>

          <div className="container ShoppingProgressBtnBox">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-3">
                <button className="ShoppingProgressReturnBtn_small" type="button" onClick={() => { onUpdateShoppingData("", "errorMessage"); this.prev(); }}>返回</button>
              </div>
              <div className="col-md-3">
                <button className="ShoppingProgressBtnSendBtn_small" type="submit">下一步</button>
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-3">
                <a href="https://line.me/R/ti/p/" target="blank"><button className="LoginBtnLine" type="button"><span></span>Line 立即詢問</button></a>
              </div>
              <div className="col-md-3">
                <a href="https://www.facebook.com/tianlong3126952/" target="blank"><button className="LoginBtnFB" type="button"><span></span>FB 立即詢問</button></a>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </form>
      </div>
    )
  }
  renderShoppingResult() {
    const {
      buyProducts, products, totalAmount, discount, deduction, shippingCost, giftId, gift, balanceDue,
      deliveryMethod, deliveryDate, deliveryTime, deliveryCity, deliveryAddress, storeId, storeName, storeIdNames, paymentMethod, 
      purchaserName, purchaserPhone, purchaserEmail, deliveryName, deliveryPhone, invoiceType, invoiceUbn, invoiceTitle,
      greetings, note, TaiwanCity, errorMessage,
      onClickSubmitOrder
    } = this.props;
    return (
      <div key={`shoppingResult`}>
        <div className="OrderContent">
          <div className="d-md-flex align-items-center OrderMethod">
            <p className="ShoppingRWDText">親愛的顧客您好，請確認下列購物資訊是否正確</p>
          </div>


          <table className="table table-responsive-md align-middle TableList">
            <thead className="OrdersListHead">
              <tr>
                <th scope="col">商品名稱</th>
                <th scope="col">單價</th>
                <th scope="col">數量</th>
                <th scope="col">商品總額</th>
              </tr>
            </thead>
            <tbody>
              {
                (buyProducts, products)
                &&
                buyProducts.keySeq().map(productId =>
                  <tr key={`shopping_result_${productId}`}>
                    <td className="OrderTH shoppingComplete ShoppingRWDText">{products.getIn([productId, "name"])}</td>
                    <td className="OrderTH shoppingComplete ShoppingRWDText">{products.getIn([productId, "price"])}</td>
                    <td className="OrderTH shoppingComplete ShoppingRWDText">{buyProducts.getIn([productId])}</td>
                    <td className="OrderTH shoppingComplete ShoppingRWDText">{+products.getIn([productId, "price"]) * +buyProducts.getIn([productId])}</td>
                  </tr>
                )
              }
            </tbody>
          </table>

          <div className="Amount">
            <p>商品總金額：{totalAmount}</p>
            <p>運費：{shippingCost}</p>
            {
              discount !== 0
              &&
              <p>折扣金額：{discount}</p>
            }
            {/*<p>購物金折抵：{memberPoint}</p>*/}
            <p className="BalanceDue">訂單總金額：<span>{balanceDue}</span></p>

            {
              (gift && gift.size > 0 && giftId && giftId == gift.getIn(['id']))
              &&
              <di>
                <p className="BalanceDue">獲得贈品</p>
                <div className="GiftsBox">
                  <div className="ShoppingProgressPhotoGifts"><img className="rounded mx-auto d-block" src={`/packages/sxs/${gift.getIn(['image'])}`} alt={gift.getIn(['name'])} /></div>
                  <div className="RegistrationTextGifts"><p className="RegistrationText">{`${gift.getIn(["name"])} x 1`}</p></div>
                </div>
              </di>

            }

          </div>

        </div>




        <div className="ShoppingProgressTable">
          <div className="ShoppingProgressTitleTextBox">
            <span className="ShoppingProgressTitleText">付款方式與寄送資訊</span>
          </div>
          <table className="table">
            <tbody>
              <tr>
                <td className="TD1"><p className="RegistrationText ShoppingRWDText">配送方式</p></td>
                <td className="TD2"><div className="TableGoldLine"></div></td>
                <td className="TD3">
                  <p className="RegistrationText ShoppingRWDText">{DELIVERY_METHOD[deliveryMethod]}</p>
                </td>
              </tr>

              <tr>
                <td><p className="RegistrationText ShoppingRWDText">到貨日期</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td><p className="RegistrationText ShoppingRWDText">{`${deliveryDate} (${DELIVERY_TIME[deliveryTime]})`}</p></td>
              </tr>


              {/* <tr>
                <td><p className="RegistrationText">到貨地址</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td>
                  {
                    deliveryMethod === "1"
                    &&
                    <p className="RegistrationText">{deliveryAddress}</p>
                  }
                  {
                    deliveryMethod === "2"
                    &&
                    <p className="RegistrationText">{storeIdNames.getIn([storeId, "storeName"])}</p>
                  }
                </td>
              </tr> */}

              {
                deliveryMethod == '1'
                ?
                <tr>
                  <td><p className="RegistrationText ShoppingRWDText">到貨地址</p></td>
                  <td><div className="TableGoldLine"></div></td>
                  <td><p className="RegistrationText ShoppingRWDText">{ deliveryCity }{ deliveryAddress }</p></td>
                </tr>
                :
                <tr>
                  <td><p className="RegistrationText ShoppingRWDText">取貨門市</p></td>
                  <td><div className="TableGoldLine"></div></td>
                  <td><p className="RegistrationText ShoppingRWDText">{ storeName }</p></td>
                </tr>                
              }

              <tr>
                <td><p className="RegistrationText ShoppingRWDText">收件者</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td>
                  <div className="RegistrationText ShoppingRWDText">
                    {
                      deliveryMethod === "1"
                      &&
                      <p className="RegistrationText ShoppingRWDText">{deliveryName}</p>
                    }
                    {
                      deliveryMethod === "2"
                      &&
                      <p className="RegistrationText ShoppingRWDText">{purchaserName}</p>
                    }
                  </div>
                </td>
              </tr>

              <tr>
                <td><p className="RegistrationText ShoppingRWDText">聯絡電話</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td>
                  <div className="RegistrationText ShoppingRWDText">
                    {
                      deliveryMethod === "1"
                      &&
                      <p className="RegistrationText ShoppingRWDText">{deliveryPhone}</p>
                    }
                    {
                      deliveryMethod === "2"
                      &&
                      <p className="RegistrationText ShoppingRWDText">{purchaserPhone}</p>
                    }
                  </div>
                </td>
              </tr>

              <tr>
                <td><p className="RegistrationText ShoppingRWDText">E-mail</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td><p className="RegistrationText ShoppingRWDText">{purchaserEmail}</p></td>
              </tr>

              <tr>
                <td><p className="RegistrationText ShoppingRWDText">付款方式</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td><p className="RegistrationText ShoppingRWDText">{PAYMENT_METHOD[paymentMethod]}</p></td>
              </tr>

              <tr>
                <td><p className="RegistrationText ShoppingRWDText">發票</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td><p className="RegistrationText ShoppingRWDText">{INVOICE_TYPE[invoiceType]}</p></td>
              </tr>
              {
                invoiceType !== "1"
                &&
                <tr>
                  <td><p className="RegistrationText ShoppingRWDText">統一編號</p></td>
                  <td><div className="TableGoldLine"></div></td>
                  <td><p className="RegistrationText ShoppingRWDText">{invoiceUbn}</p></td>
                </tr>                
              }
              {
                invoiceType === "3"
                &&
                <tr>
                  <td><p className="RegistrationText ShoppingRWDText">發票抬頭</p></td>
                  <td><div className="TableGoldLine"></div></td>
                  <td><p className="RegistrationText ShoppingRWDText">{invoiceTitle}</p></td>
                </tr>                
              }
              <tr>
                <td><p className="RegistrationText ShoppingRWDText">賀詞</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td><p className="RegistrationText ShoppingRWDText">{greetings}</p></td>
              </tr>

              <tr>
                <td><p className="RegistrationText ShoppingRWDText">備註</p></td>
                <td><div className="TableGoldLine"></div></td>
                <td><p className="RegistrationText ShoppingRWDText">{note}</p></td>
              </tr>

            </tbody>
          </table>
        </div>


        <div className="container ShoppingProgressBtnBox">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-3">
              <button className="ShoppingProgressReturnBtn_small" type="button" onClick={() => this.prev()}>返回</button>
            </div>
            <div className="col-md-3">
              <button id="ShoppingProgressBtnSendBtn" className="ShoppingProgressBtnSendBtn_small" type="button" onClick={() => { document.getElementById('ShoppingProgressBtnSendBtn').disabled=true; onClickSubmitOrder(this.props.history);}}>送出訂單</button>
              {
                errorMessage
                &&
                <p className="RegistrationText">{errorMessage}</p>
              }
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-3">
              <a href="https://line.me/R/ti/p/" target="blank"><button className="LoginBtnLine" type="button"><span></span>Line 立即詢問</button></a>
            </div>
            <div className="col-md-3">
              <a href="https://www.facebook.com/tianlong3126952/" target="blank"><button className="LoginBtnFB" type="button"><span></span>FB 立即詢問</button></a>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>

      </div>
    )
  }
  componentWillMount() {
    this.props.onLoadStoreIdNames();
    this.props.onLoadTaiwanCity();
  }
  render() {
    const steps = [
      {
        title: '購物車',
        content: this.renderShoppingCart()
      }, {
        title: '填寫資料',
        content: this.renderShoppingData()
      }, {
        title: '確認訂單',
        content: this.renderShoppingResult()
      }
    ];
    const {
      currentStep
    } = this.props;
    return (
      <div>
        <div className="ShoppingProgressStepBox">
          {/* 第一步 */}
          <div className="ShoppingProgressStepText_1" style={{ color: "#DBC28C " }}>購物車</div>
          <img className="ShoppingProgressStepImg_1" src={shoppingCartOkIcon} alt="" onClick={e => this.props.onUpdateShoppingData(0, 'currentStep')} />
          {/* 一 -> 二 */}
          {
            currentStep > 0
              ?
              <div className="ShoppingProgressStepBar_1-1"></div>
              :
              <div className="ShoppingProgressStepBar_1-2"></div>
          }

          {/* 第二步 */}
          {
            currentStep > 0
              ?
              <div className="ShoppingProgressStepText_2" style={{ color: "#DBC28C " }}>填寫資料</div>
              :
              <div className="ShoppingProgressStepText_2" style={{ color: "#ABABAB " }}>填寫資料</div>
          }
          {
            currentStep > 0
              ?
              <img className="ShoppingProgressStepImg_2" src={enterDataOkIcon} alt="" />
              :
              <img className="ShoppingProgressStepImg_2" src={enterDataIcon} alt="" onClick={() => this.next()} />
          }

          {/* 二 -> 三 */}
          {
            currentStep > 1
              ?
              <div className="ShoppingProgressStepBar_2-1"></div>
              :
              <div className="ShoppingProgressStepBar_2-2"></div>
          }

          {/* 第三步 */}
          {
            currentStep > 1
              ?
              <div className="ShoppingProgressStepText_3" style={{ color: "#DBC28C " }}>確認訂單</div>
              :
              <div className="ShoppingProgressStepText_3" style={{ color: "#ABABAB " }}>確認訂單</div>
          }
          {
            currentStep > 1
              ?
              <img className="ShoppingProgressStepImg_3" src={confirmOkIcon} alt="" />
              :
              <img className="ShoppingProgressStepImg_3" src={confirmIcon} alt="" />
          }
        </div>
        {steps[this.props.currentStep].content}
      </div>
    )
  }
}

export default withRouter(ShoppingProgressComponent);