import { createAction } from 'redux-actions';
import { message, Modal } from 'antd';
import moment from 'moment';
import * as types from './actionTypes';
import * as api from '../../utils/api';
import * as cookie from "../../utils/cookiesTool";


export const doUpdateIsLoggedIn = createAction(types.UPDATE_IS_LOGGED_IN);
export function doIsLoggedIn(history) {
    return dispatch => {
        if (cookie.getCookie('stormedToken') === '') {
            dispatch(doUpdateIsLoggedIn({ isLoggedIn: false }));
            Modal.confirm({
                title: '您尚未登入會員，是否要先登入？',
                content: '(成為會員即可享有購物優惠等福利)',
                okText: '登入',
                cancelText: '繼續',
                onOk() {
                    history.push(`/login`);
                },
            });
        }
    }
}

export const doUpdateProducts = createAction(types.UPDATE_PRODUCTS);
export function doLoadProducts() {  
  return (dispatch, getState) => {
    const state = getState();
    const buyProducts = state.getIn(['shoppingProgress', 'buyProducts']);
    const buyProductIdList = [];
    buyProducts.keySeq().forEach(productId => buyProductIdList.push(productId));
    api.rPartialPackages(buyProductIdList).then(result => {  
      const products = {};
      result.forEach(product => {
        products[product.id] = product;
      })
      dispatch(doUpdateProducts({ products }));
    }).catch((errMsg) => {
      console.log(errMsg);
    })    
  }
}

export const doUpdateBuyProducts = createAction(types.UPDATE_BUY_PRODUCTS);
export const doUpdateShoppingCount = createAction(types.UPDATE_SHOPPING_COUNT);
export function doUpdateBuyProductQuantity(productId, buyQuantity) {
  return (dispatch, getState) => {
    dispatch(doUpdateBuyProducts({ productId, buyQuantity }));
    dispatch(doUpdateShoppingCount({ count: buyQuantity }));
  }
}

export function doClickRemoveBuyProduct(productId, buyQuantity) {
  return (dispatch, getState) => {
    const state = getState();
    const qty = state.getIn(['shoppingProgress','buyProducts',productId]);
    dispatch(doUpdateBuyProducts({ productId, buyQuantity }));
    dispatch(doUpdateShoppingCount({ count: -qty }));
  }
}


export const doUpdateShoppingData = createAction(types.UPDATE_SHOPPING_DATA);
export function doClickProgress(payload) {
  return (dispatch, getState) => {
    dispatch(doUpdateShoppingData({value: payload.value, type:"currentStep"}));
    payload.history.push(`/shopping/progress`);
  }
}