import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import headerBar from '../modules/headerBar';
import footer from '../modules/footer';
import products from '../modules/products';


class ProductsPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
  	return (
	    <div id="page-products">
      <Helmet>
        <title>隆太國際有限公司-產品介紹</title>
        <meta name="description" content="「隆太國際有限公司」。提供各項專業的交通安全服務，專營各項交通安全設施產品，如碰撞緩衝設施、鋼索型碰撞防護體、滾筒式護欄、TMA車體碰撞防護器、LED標誌指示燈及交通安全等。" />
      </Helmet>
        <headerBar.container/>

        {/* <div className="ProductBannerTitle">
          <p className="center">產品介紹</p>
        </div>  */}
        <div className="row TitleBox">
          <p className="col-auto mr-auto Title">產品介紹</p>
          <p className="col-auto DirectionTitle">首頁 / 產品介紹</p>
        </div>

        <products.container
          categoryId={this.props.match.params.categoryId}
        />

        <footer.container/>
	    </div>
	  )
  }
}

export default ProductsPageComponent;
