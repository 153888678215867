export const UPDATE_IS_LOGGED_IN = 'shoppingProgress/UPDATE_IS_LOGGED_IN'
export const UPDATE_IS_COMPLETED = 'shoppingProgress/UPDATE_IS_COMPLETED'

export const UPDATE_BUY_PRODUCTS = 'shoppingProgress/UPDATE_BUY_PRODUCTS'
export const UPDATE_PRODUCTS = 'shoppingProgress/UPDATE_PRODUCTS'

export const UPDATE_STORE_ID_NAMES = 'shoppingProgress/UPDATE_STORE_ID_NAMES'
export const UPDATE_SHOPPING_DATA = 'shoppingProgress/UPDATE_SHOPPING_DATA'

export const UPDATE_ORDER_ID = 'shoppingProgress/UPDATE_ORDER_ID'


export const UPDATE_SHOPPING_COUNT = 'headerBar/UPDATE_SHOPPING_COUNT'
