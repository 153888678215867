import React from 'react';
import E_01 from '../../../images/portfolio/portfolio_E001.JPG';
import E_02 from '../../../images/portfolio/portfolio_E002.jpg';

export const ProductsE = (props) => {
  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={E_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={E_02}/>
          </div>
        </div>
      </div>
    </div>
  )
}