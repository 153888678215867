import { connect } from 'react-redux';
import * as actions from './actions';
import * as selectors from './selectors';
import NewsComponent from './component';


const mapStateToProps = (state) => ({
  activities: selectors.getActivities(state),
  news: selectors.getNews(state),
  category: selectors.getCategory(state),
  medias: selectors.getMedias(state),
  currentMedia: selectors.getCurrentMedia(state),
  currentNews: selectors.getCurrentNews(state),
});

const mapDispatchToProps = dispatch => ({
  onLoadNews: () => {
  	dispatch(actions.doLoadNews());
  },
  onClickCategory: (category) => {
    dispatch(actions.doClickCategory(category));
  },  
  onLoadActivities: () => {
    dispatch(actions.doLoadActivities());
  },
  onChangeMedia: (currentMedia) => {
    dispatch(actions.doUpdateCurrentMedia({ currentMedia }));
  },
  onChangeNews: (currentNews) => {
    dispatch(actions.doUpdateCurrentNews({ currentNews }));
  }    
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsComponent);