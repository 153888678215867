import { handleActions } from 'redux-actions';
import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'productHistory/UPDATE_SELECTED_PRODUCT_HISTORY_CATEGORY_ID': (state, { payload }) => {
    return state.setIn(['selectedProductHistoryCategoryId'], payload.productHistoryCategoryId);
  },	
  'productHistory/UPDATE_SELECTED_PRODUCT_HISTORY_ID': (state, { payload }) => {
    console.log(state);
    return state.setIn(['selectedProductHistoryId'], payload.productHistoryId);
  },
  'productHistory/UPDATE_PRODUCT_HISTORY': (state, { payload }) => {
    return state.setIn(['productHistory'], fromJS(payload.productHistory));
  },
  'productHistory/UPDATE_PRODUCT_HISTORY_ID_LIST': (state, { payload }) => {
    return state.setIn(['productHistoryIdList'], fromJS(payload.productHistoryIdList));
  },
  'productHistory/UPDATE_PRODUCT_HISTORY_CATEGORIES': (state, { payload }) => {
    return state.setIn(['productHistoryCategories'], fromJS(payload.productHistoryCategories));
  },
  'productHistory/UPDATE_PRODUCT_HISTORY_CATEGORY_ID_LIST': (state, { payload }) => {
    return state.setIn(['productHistoryCategoryIdList'], fromJS(payload.productHistoryCategoryIdList));
  } 
}, State);

export default Reducer;