import { handleActions } from 'redux-actions';
// import { fromJS } from 'immutable';
import { State } from './model';


const Reducer = handleActions({
  'aboutUs/UPDATE_ABOUT_US': (state, { payload }) => {
    return state.setIn(['about'], payload.about);
  }
}, State);

export default Reducer;