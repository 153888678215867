import Immutable from 'immutable';


var today = new Date();
// today.setDate(today.getDate() + 1)
var dd = today.getDate();
var mm = today.getMonth() + 1; 
var yyyy = today.getFullYear();
if(dd<10) {
    dd = '0'+dd
} 
if(mm<10) {
    mm = '0'+mm
} 
today = yyyy + '/' + mm + '/' + dd;


export const State = Immutable.fromJS({
	isLoggedIn: false,
	isCompleted: false,
	currentStep: 0,

	// step 1
	buyProducts: {},
	products: {},
	// step 2
	storeIdNames: {},
	deliveryMethod: 'delivery',
	deliveryDate: today,
	deliveryTime: 'morning',
	storeId: '1',

	purchaserName: '',
	purchaserGender: 'male',
	purchaserEmail: '',
	purchaserPhone: '',
	purchaserAddress: '',

	asPurchaserData: false,
	deliveryName: '', 
	deliveryGender: 'male', 
	deliveryPhone: '', 
	deliveryAddress: '',

	paymentMethod: 'creditCard',

	couponCode: '', 
	couponId: 0,
	couponContent: '', 
	minAmt: 0,
	discount: 0,
	deduction: 100,
	freeShipping: false,
	gifts: [], 
	giftId: 0,

	invoiceType: 'doubleDonationInvoice',
	invoiceUbn: '',
	invoiceTitle: '',

	errorMessage: '',
	isAgreeTerms: false,
	shippingCost: 220,
	balanceDue: 0,
	orderId: ''
});