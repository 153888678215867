import React from 'react';
import D_01 from '../../../images/products/D_01.jpg';
import D_02 from '../../../images/products/D_02.jpg';
import D_03 from '../../../images/products/D_03.jpg';
import D_04 from '../../../images/products/D_04.jpg';
import D_05 from '../../../images/products/D_05.jpg';

export const ProductsD = (props) => {
  return (
    <div>
      <h2 className="productsTitle">鋼索型碰撞防護體</h2>
      <br />
      <p>產品特性：</p>
      <ul>
        <li>◎ Sentry鋼索緩撞防護體是通過美國最新MASH TL-2和TL-3測試報告，可承受時速70KM～100KM之車輛撞擊。</li>
        <li>◎ Sentry護欄淨重160磅(73公斤)，加入約850公升的水後重量為2,000磅(907公斤)。</li>
        <li className="point-font">◎ 防護體內含有四條完整的防腐蝕鋼索，除緩衝撞擊力道外，亦避免車輛撞擊後穿越。</li>
        <li>◎ 藉由完整的軸襯可排列出橫排或直立，且適當長度的Sentry護欄。</li>
        <li>◎ 可利用堆高機快速移動Sentry護欄並重新擺放，過程快速便利，簡單施工作業時間，降低施工風險。</li>
        <li>◎ Sentry護欄15度角的鉸鏈連扣設計，可適用於部分彎曲的工區道路。</li>
        <li>◎ 每一組Sentry護欄末端的連結處皆有鉸鏈連扣設計和鍍鋅T型釘，加強其連接力。</li>
      </ul>
      
      <p>產品規格：</p>
      <ul>
        <li>◎ 長183公分</li>
        <li>◎ 高109公分</li>
        <li>◎ 寬57公分</li>
        <li>◎ 空重73公斤</li>
        <li>◎ 總重907公斤(含850公升水量)</li>
        <li>◎ 內部四條防鏽鐵條</li>
      </ul>
      <div>
        <div className="row">
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={D_01}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={D_02}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={D_03}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={D_04}/>
          </div>
          <div className="col-md-6 col-12 mb-3">
            <img className="ProductsImg" src={D_05}/>
          </div>
        </div>
      </div>
    </div>
  )
}